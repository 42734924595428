import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/model/user-data.service';
import { UsersModel, MysteryShopperUserModel, MysteryShopperAssignModel, MysteryTemplateModel, MysteryTemplateQuestionModel, excelDataModel, MysteryCampaignModel, WhitelistUsersDateRangeModel } from 'src/app/model/contract/user';
import { UserRoleService } from 'src/app/model/user-role.service';
import { UserRoleModel } from 'src/app/model/contract/userroles';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { MysteryShopperAssignedModel } from 'src/app/model/contract/mystery-shopper';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class MysteryShopperProvider {

    userDataList: UsersModel[];
    userroles: UserRoleModel[];
    mysteryAssignedList: MysteryShopperAssignedModel[];
    templateDataList: MysteryTemplateModel[];
    questionDataList: MysteryTemplateQuestionModel[];
    mysteryCampaign: MysteryCampaignModel[];
    MysteryWhiteListCount: any;
    crownCount: any[];

    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        private users: UserDataService,
        public alertService: AlertService,
        private userRoleService: UserRoleService,
        public toasterService: ToastrService) {

    }

    getUserList() {
        let program_id = 1;
        this.users.getAllMysteryShopper(program_id)
            .subscribe(
                (response: any) => {
                    this.userDataList = response.data.shoppers;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMysterCampaignList() {
        this.users.getAllMysteryShopperCamapign()
            .subscribe(
                (response: any) => {
                    this.mysteryCampaign = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    public getUserRoles() {
        this.userRoleService.getUserRoleList()
            .subscribe(
                (result: any) => {
                    this.userroles = result.data;
                    console.log(this.userroles);
                },
                error => {
                    this.alertService.error(error);
                }
            );
    }

    addMysteryShopper(user: MysteryShopperUserModel) {
        this.users.addMysteryShopperUser(user)
            .subscribe(
                (result: any) => {
                    this.toasterService.success('Mystery Shopper Added Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getUserList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    updateMysteryShopper(user: MysteryShopperUserModel) {
        this.users.updateMysteryShopperUser(user)
            .subscribe(
                (result: any) => {
                    this.toasterService.success('Mystery Shopper Updated Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getUserList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    updateMysteryAssignment(user: MysteryShopperAssignModel) {
        this.users.updateMysteryAssignment(user)
            .subscribe(
                (result: any) => {
                    this.toasterService.success('Mystery Shopper Outlet Assignment Updated Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getMysteryShopperAssignment();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    showMysteryLoader: boolean;

    getMysteryShopperAssignment() {
        this.showMysteryLoader = false;
        this.users.getMysteryShopperAssinedList()
            .subscribe(
                (result: any) => {
                    this.mysteryAssignedList = result.data.assignment;
                    if (this.mysteryAssignedList.length > 0) {
                        this.showMysteryLoader = true;
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMysteryTemplates() {
        this.users.getMysteryTemplates()
            .subscribe(
                (result: any) => {
                    this.templateDataList = result.data.templates;
                    console.log(this.templateDataList);
                    if (this.templateDataList.length > 0) {
                        this.templateDataList.forEach(
                            u => {
                                u.geographical_id = u.region_id;
                                u.auditStatus = false;
                                u.updateBtn = true;
                                //Save btn won't show if audit has been started.
                                if (new Date().setHours(0, 0, 0, 0) > new Date(u.start_date).setHours(0, 0, 0, 0)) {
                                    u.updateBtn = false;
                                }
                                if (u.outlets.length > 0) {
                                    u.outlets.forEach(
                                        c => {
                                            c.id = c.outlet_id;
                                            c.itemName = c.outlet_name + ' - ' + c.outlet_code;
                                            c.checked = true;
                                            c.geographical_id = c.region_id;
                                            c.assignedStatus = false;
                                            if (c.audit_status == 2) {
                                                u.auditStatus = true;
                                            }
                                        }
                                    )
                                }

                                //Check if the audit status is 2;

                                u.template_groups.forEach(
                                    t => {
                                        t.selectedQuestions.forEach(
                                            t1 => {
                                                t1.id = t1.question_id;

                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addMysteryTemplate(user: any) {
        this.users.addMysteryTemplate(user)
            .subscribe(
                (result: any) => {
                    this.alertService.clear();
                    this.toasterService.success('Mystery Shopper Template Added Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getMysteryTemplates();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMysteryTemplateQuestions() {
        this.users.getMysteryTemplateData()
            .subscribe(
                (result: any) => {
                    this.questionDataList = result.data.questionsAnswers;
                    this.questionDataList.forEach(
                        c => {
                            c.checked = false;
                        }
                    );
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    updateMysteryTemplate(user: any) {
        this.users.updateMysteryTemplate(user)
            .subscribe(
                (result: any) => {
                    this.toasterService.success('Mystery Shopper Template Updated Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getMysteryTemplates();
                    this.getMysteryTemplateQuestions();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMysteryShopperList() {
        let program_id = 1;
        this.users.getAllMysteryShopper(program_id)
            .subscribe(
                (response: any) => {
                    this.userDataList = response.data.shoppers;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addMysteryQuestionaries(user: any) {
        this.users.updateMysteryTemplate(user)
            .subscribe(
                (result: any) => {
                    this.toasterService.success('Mystery Shopper Template Updated Successfully', "Success", {
                        timeOut: 5000,
                    });
                    this.getMysteryTemplates();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addMysteryUser(user: excelDataModel) {
        this.users.addExcelData(user)
            .subscribe(
                (result: any) => {
                    if (result.data.message_code == 1) {
                        this.toasterService.success(result.data.message, "Success", {
                            timeOut: 5000,
                        });
                    } else if (result.data.message_code == 2) {
                        this.toasterService.error(result.data.message, "Error", {
                            timeOut: 5000,
                        });
                    }
                    this.userDataList = result.data.shoppers;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addMysteryWhitelistCampaign(loyaltyTarget: MysteryCampaignModel) {
        this.users.MysterShopperCampaign(loyaltyTarget)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Mystery Whitelist Campaign Added Successfully',
                        "Success", {
                        timeOut: 1000,
                    });
                    this.getMysterCampaignList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    qcpaneldata() {
        this.users.qcpaneldata()
            .subscribe(
                (response: any) => {
                    this.crownCount = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    getTargetCount(id: number) {
        this.users.getwhitelistdata(id)
            .subscribe(
                (response: any) => {
                    this.crownCount = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }
    cartonLoader: boolean;
    updateCartonInsert(scheme: WhitelistUsersDateRangeModel) {
        this.cartonLoader = false;
        this.users.updateMysterCampaign(scheme)
            .subscribe(
                (response: any) => {
                    this.cartonLoader = true;
                    this.toasterService.success('Whitelisted Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.getMysterCampaignList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    assignMysteryShopper(excel: excelDataModel) {
        this.users.addMysteryAssignedExcelData(excel)
            .subscribe(
                (response: any) => {
                    if (response.data.message_code == 1) {
                        this.toasterService.success(response.data.message, 'Success', {
                            timeOut: 5000
                        });
                    } else if (response.data.message_code == 2) {
                        this.toasterService.error(response.data.message, "Error", {
                            timeOut: 5000,
                        });
                    }
                    this.mysteryAssignedList = response.data.assignment;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }
}