import { Component, OnInit, Output, OnChanges, Input, EventEmitter } from '@angular/core';
import { StaffService } from '../../model/staff.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';

@Component({
  selector: 'app-simple',
  templateUrl: './simple-layout.component.html'
})
export class SimpleLayoutComponent implements OnInit {
  currentRouter: string;
  isHubAdmin: boolean = true;
  calendar_month: number;
  constructor(public staffService: StaffService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public routerDataProvider: RouteDataProvider) {
    this.calendar_month = parseInt(localStorage.getItem("calendar_year"));
    this.routerDataProvider.subArea.subscribe(
      m => {
        this.currentRouter = m;
        console.log(this.currentRouter)
      }
    )
  }

  ngOnInit(): void {
  }

  clearProgramId(){
    localStorage.removeItem('program_id');
    localStorage.removeItem('programupdated');
  }
}
