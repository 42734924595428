import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grey-tile',
  templateUrl: './grey-tile.component.html',
  styleUrls: ['./grey-tile.component.scss']
})
export class GreyTileComponent implements OnInit {
  @Input() color : any;
  @Input() data : any;
  @Input() input_string : any;
  constructor() { }

  ngOnInit() {
  }

}
