import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeoHierarchyModel } from 'src/app/model/contract/program';
import { GeoHierachyDataService } from 'src/app/model/geo-hierachy-data.service';
import { RouteDataProvider } from '../RouterProvider/RouteDataProvider.provider';
import { StaffDataService } from 'src/app/model/staff-data.service';
import { AlertService } from '../RouterProvider/AlertProvider';
import { CampaignDataService } from 'src/app/model/campaign-data.service';
import { CampaignModel, CampaignQuizModel, RewardModel, options, TasksModel } from 'src/app/model/contract/campaign';
import { ToastrService } from 'ngx-toastr';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { PosmExcel } from 'src/app/model/contract/posm';


@Injectable({
  providedIn: 'root',
})
export class CampaignProvider {
  campaigns: CampaignModel[];
  tasks : CampaignModel[];
  campaignReward: RewardModel[];
  campignQuiz: CampaignQuizModel[];
  constructor(private routeDataProvider: RouteDataProvider,
    private staffDataService: StaffDataService,
    private router: Router,
    public campaignService: CampaignDataService,
    public alertService: AlertService,
    public toasterService: ToastrService,
    private settingDataService: SettingDataService,) {
  }


  public getCampaigns() {
    this.campaignService.getCampaignList()
      .subscribe(
        (result: any) => {
          this.campaigns = result.data;
          if (this.campaigns.length > 0) {
            this.campaigns.forEach(
              c => {
                c.campaignActiveState = true;
                c.campaignStatus = 'Active';
                c.quizChecked = false;
                c.imageChecked = false;
                c.gameType = false;
                c.successMsgBoolean = false;
                c.setCampaignHold = false;
                c.successNotificationBoolean = false;
                c.successSmsBoolean = false;
                c.failureMsgBoolean = false;
                c.failureNotificationBoolean = false;
                c.failureSmsBoolean = false;
                c.notDisabled = true;
                if (c.status == 0) {
                  c.campaignActiveState = false;
                  c.campaignStatus = 'Inactive';
                  c.notDisabled = false;
                }
              }
            )
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  addCampaignData(campaign: CampaignModel) {
    this.campaignService.addCampaign(campaign)
      .subscribe(
        (result: any) => {
          this.alertService.clear();
          this.toasterService.success('Data Inserted Successfully', "Success", {
            timeOut: 1000,
          });
          this.campaigns = result.data;
          if (this.campaigns.length > 0) {
            this.campaigns.forEach(
              c => {
                c.campaignActiveState = true;
                c.campaignStatus = 'Active';
                c.quizChecked = false;
                c.imageChecked = false;
                c.gameType = false;
                c.successMsgBoolean = false;
                c.setCampaignHold = false;
                c.successNotificationBoolean = false;
                c.successSmsBoolean = false;
                c.failureMsgBoolean = false;
                c.failureNotificationBoolean = false;
                c.failureSmsBoolean = false;
                c.notDisabled = true;
                if (c.status == 0) {
                  c.campaignActiveState = false;
                  c.campaignStatus = 'Inactive';
                  c.notDisabled = false;
                }
              }
            )
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  campaignUpdated: boolean;
  updateCampaignRelatedData(camapign: CampaignModel) {
    this.campaignUpdated = false;
    this.campaignService.UpdateCampaign(camapign)
      .subscribe(
        (result: any) => {
          this.campaignUpdated = true;
          this.toasterService.success('Data Updated Successfully', "Success", {
            timeOut: 1000,
          });
          this.getCampignQuizList(camapign.id);
          this.getCampaignReward(camapign.id);
          this.getCampaignTasks();
          this.campaigns = result.data;
          if (this.campaigns.length > 0) {
            this.campaigns.forEach(
              c => {
                c.campaignActiveState = true;
                c.campaignStatus = 'Active';
                c.quizChecked = false;
                c.imageChecked = false;
                c.gameType = false;
                c.successMsgBoolean = false;
                c.setCampaignHold = false;
                c.successNotificationBoolean = false;
                c.successSmsBoolean = false;
                c.failureMsgBoolean = false;
                c.failureNotificationBoolean = false;
                c.failureSmsBoolean = false;
                c.notDisabled = true;
                if (c.status == 0) {
                  c.campaignActiveState = false;
                  c.campaignStatus = 'Inactive';
                  c.notDisabled = false;
                }
              }
            )
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  getCampaignReward(id) {
    this.campaignService.getCampaignRewardList(id)
      .subscribe(
        (result: any) => {
          this.campaignReward = result;
          this.campaignReward = this.campaignReward.filter(c => c.campaign_id == id);
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  quizTask : TasksModel;
  imageTask : TasksModel;

  getCampaignTasks() {
    this.campaignService.getCampaignTask()
      .subscribe(
        (result: any) => {
          this.tasks = result;
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  getCampignQuizList(id) {
    this.campaignService.getCampaignQuizDataList(id)
      .subscribe(
        (result: any) => {
          this.campignQuiz = result;
          this.campignQuiz = this.campignQuiz.filter(c => c.campaign_id == id);
          if(this.campignQuiz.length > 0) {
            this.campignQuiz.forEach((c, i)=> {
              c.options = [];

              if(c.option1 != null) {

                  let Answers : options = new options;
                  Answers.optionText = c.option1;
                  Answers.answer = false;
                  if(c.answer == c.option1){
                    Answers.answer = true;
                  }
                  c.options.push(Answers);
              }
              if(c.option2 != null) {
                let Answers : options = new options;
                Answers.optionText = c.option2;
                Answers.answer = false;
                if(c.answer == c.option1){
                  Answers.answer = true;
                }

                c.options.push(Answers);
            }
            if(c.option3 != null) {
              let Answers : options = new options;
              Answers.optionText = c.option3;
              Answers.answer = false;
              if(c.answer == c.option3){
                Answers.answer = true;
              }

              c.options.push(Answers);
            }
            if(c.option4 != null) {
                let Answers : options = new options;
                Answers.optionText = c.option1;
                Answers.answer = false;
                  if(c.answer == c.option4){
                    Answers.answer = true;
                  }
                c.options.push(Answers);
            }
              c.activity_title = '';
            })
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  deleteReward(item) {
    this.campaignReward.splice(item, 1);
  }

  addReward(con) {
    this.campaignReward.push(con);
  }

  changeCampaignStatus(camapign) {
    this.campaignUpdated = false;
    this.campaignService.UpdateCampaignStatus(camapign)
      .subscribe(
        (result: any) => {
          this.campaignUpdated = true;
          this.toasterService.success('Data Updated Successfully', "Success", {
            timeOut: 1000,
          });
          this.campaigns = result.data;
          if (this.campaigns.length > 0) {
            this.campaigns.forEach(
              c => {
                c.campaignActiveState = true;
                c.campaignStatus = 'Active';
                c.quizChecked = false;
                c.imageChecked = false;
                c.gameType = false;
                c.successMsgBoolean = false;
                c.setCampaignHold = false;
                c.successNotificationBoolean = false;
                c.successSmsBoolean = false;
                c.failureMsgBoolean = false;
                c.failureNotificationBoolean = false;
                c.failureSmsBoolean = false;
                c.notDisabled = true;
                if (c.status == 0) {
                  c.campaignActiveState = false;
                  c.campaignStatus = 'Inactive';
                  c.notDisabled = false;
                }
              }
            )
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }
  
  excelLoader : boolean;
    whiteListCampaign(posmExcel : PosmExcel){
        this.excelLoader = true;
        this.settingDataService.excelCamapignWhiteList(posmExcel)
            .subscribe(
                (response: any) => {
                    this.excelLoader = false;
                    this.toasterService.success('Outlet whitelisted for Campaign Successfully', "Success", {
                        timeOut: 10000,
                      });
                    // this.getPosmConfigDataList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }
}