import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uplift-report',
  templateUrl: './uplift-report.component.html',
  styleUrls: ['./uplift-report.component.scss']
})
export class UpliftReportComponent implements OnInit {
table_data;
  constructor() { }

  ngOnInit() {
    this.table_data = [
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/uplift_category_wise_report.xlsx",
        "State": "Consolidated Report",
        "MYEAR": ""
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Jun.xlsx",
        "State": "Karnataka",
        "MYEAR": "June 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Jul.xlsx",
        "State": "Karnataka",
        "MYEAR": "July 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Aug.xlsx",
        "State": "Karnataka",
        "MYEAR": "August 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Sep.xlsx",
        "State": "Karnataka",
        "MYEAR": "September 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Oct.xlsx",
        "State": "Karnataka",
        "MYEAR": "October 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Nov.xlsx",
        "State": "Karnataka",
        "MYEAR": "November 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Karnataka-Dec20.xlsx",
        "State": "Karnataka",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-TE-Sep.xlsx",
        "State": "Telegana",
        "MYEAR": "September 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-TE-Oct.xlsx",
        "State": "Telegana",
        "MYEAR": "October 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-TE-Nov.xlsx",
        "State": "Telegana",
        "MYEAR": "November 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-TE-Dec.xlsx",
        "State": "Telegana",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-MH-Sep.xlsx",
        "State": "Maharashtra",
        "MYEAR": "September 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-MH-Oct.xlsx",
        "State": "Maharashtra",
        "MYEAR": "October 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-MH-Nov.xlsx",
        "State": "Maharashtra",
        "MYEAR": "November 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-MH-Dec.xlsx",
        "State": "Maharashtra",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-PY-Nov.xlsx",
        "State": "Pondicherry",
        "MYEAR": "November 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-PY-Dec.xlsx",
        "State": "Pondicherry",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Goa-Nov.xlsx",
        "State": "Goa",
        "MYEAR": "November 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-Goa-Dec.xlsx",
        "State": "Goa",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-WB-Dec.xlsx",
        "State": "West Bengal",
        "MYEAR": "December 2020"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-KA-Jan21.xlsx",
        "State": "Karnataka",
        "MYEAR": "January 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-PY-Jan21.xlsx",
        "State": "Pondicherry",
        "MYEAR": "January 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-GOA-Jan21.xlsx",
        "State": "Goa",
        "MYEAR": "January 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-MH-Jan21.xlsx",
        "State": "Maharashtra",
        "MYEAR": "January 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/uplift/Uplift-Outlet-Wise-TE-Jan21.xlsx",
        "State": "Telegana",
        "MYEAR": "January 2021"
      }
     ]
  }

}
