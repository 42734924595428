import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-color-tile',
  templateUrl: './color-tile.component.html',
  styleUrls: ['./color-tile.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class ColorTileComponent implements OnInit {

  @Input() color : any;
  @Input() data : any;
  @Input() input_string : any;

  flip: string = 'inactive';

  toggleFlip() {
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }

  constructor() { }

  ngOnInit() {
    console.log(this.color, this.data, this.input_string);
  }

}
