import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseBody } from './contract/response-body';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { GeoHierarchyModel } from './contract/program';

@Injectable()
export class GeoHierachyDataService {

  constructor(
    private globalService:GlobalService, 
    private staffService:StaffService,
    private http:HttpClient
  ) { }


  private getHeaders(){
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.staffService.getToken()
    })
  }

  private getURLList(){
    return this.globalService.apiHost  + '/admin';
  }

  private getHubAdminURLList() {
    return this.globalService.apiHost + '/hub-admin';
  }


  addGeoHierarchyData(geoHierarchy:GeoHierarchyModel):Observable<any>{
    let headers = this.getHeaders();
    let body    = JSON.stringify(geoHierarchy);
    let apiUrl     = this.getURLList() + '/addgeohierarchy';
    return this.http.post<ResponseBody>(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <ResponseBody>response;
      }
    )
  }

  getGeoHierarchyList():Observable<GeoHierarchyModel>{
    let headers = this.getHeaders();
    let apiUrl     = this.getHubAdminURLList() + '/geohierarchy';
    return this.http.get<GeoHierarchyModel>(apiUrl, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <GeoHierarchyModel>response;
      }
    )
  }

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }
}
