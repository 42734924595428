export const VisiblityCooler: any = 
{
    "region_targets": [
        {
            "name": 'East',
            "value": '1000'
        },
        {
            "name": 'West',
            "value": '200'
        },
        {
            "name": 'North',
            "value": '150'
        },
        {
            "name": 'South',
            "value": '400'
        },
        {
            "name": "NESA",
            "value": "60"
        }
    ],
    "channelwise_partcipation": [
        {
            "name": "Fine Dine",
            "percentage": "55"
        },
        {
            "name": "PCL",
            "percentage": "55"
        },
        {
            "name": "B&R A",
            "percentage": "55"
        },
        {
            "name": "B&R B",
            "percentage": "55"
        },
        {
            "name": "B&R C",
            "percentage": "55"
        },
        {
            "name": "Mainstream A",
            "percentage": "55"
        },
        {
            "name": "Mainstream B",
            "percentage": "55"
        },
        {
            "name": "Mainstream C",
            "percentage": "55"
        },
        {
            "name": "PWI",
            "percentage": "55"
        }
    ],
    "total_points": [
        {
            "name": 'Total points of available',
            "value": '1000'
        },
        {
            "name": 'Total points of Earned',
            "value": '200'
        },
    ],
    "audit_data": [
        {
            "name": "Number of outlets eligible for Branding Audit",
            "data": 100
        },
        {
            "name": "No of outlets who have attempted Branding Audit",
            "data": 100
        },
        {
            "name": "Total branding self-audits - Available",
            "data": 100
        },
        {
            "name": "Total branding self-audits - attempted",
            "data": 100
        },
        {
            "name": "Percentage of approved entries",
            "data": 100
        },
        {
            "name": "Percentage of rejected entries",
            "data": 100
        },
        {
            "name": "Number of entries pending",
            "data": 100
        },
    ],
    "channel_type_audit": [
        {
            "name": 'Fine Dine',
            "points": '6'
        },
        {
            "name": 'PCL',
            "points": '8'
        },
        {
            "name": 'B&R A',
            "points": '1'
        },
        {
            "name": 'B&R B',
            "points": '4'
        },
        {
            "name": 'B&R C',
            "points": '3'
        },
        {
            "name": 'Mainstream A',
            "points": '7'
        },
        {
            "name": 'Mainstream B',
            "points": '6'
        },
        {
            "name": 'Mainstream C',
            "points": '8'
        },
        {
            "name": 'PWI',
            "points": '3'
        }
    ],
    "top_m1_list": [
        {
            "name": "Name 1",
            "number": "9875654333",
            "success_rate": "100%"
        },
        {
            "name": "Name 2",
            "number": "9875654333",
            "success_rate": "90%"
        },
        {
            "name": "Name 3",
            "number": "9875654333",
            "success_rate": "80%"
        },
        {
            "name": "Name 4",
            "number": "9875654333",
            "success_rate": "70%"
        },
        {
            "name": "Name 5",
            "number": "9875654333",
            "success_rate": "60%"
        },
        {
            "name": "Name 6",
            "number": "9875654333",
            "success_rate": "50%"
        },
        {
            "name": "Name 7",
            "number": "9875654333",
            "success_rate": "40%"
        },
        {
            "name": "Name 8",
            "number": "9875654333",
            "success_rate": "30%"
        },
        {
            "name": "Name 9",
            "number": "9875654333",
            "success_rate": "20%"
        },
        {
            "name": "Name 10",
            "number": "9875654333",
            "success_rate": "10%"
        }
    ],
    "top_m2_list": [
        {
            "name": "Name 1",
            "number": "9875654333",
            "success_rate": "100%"
        },
        {
            "name": "Name 2",
            "number": "9875654333",
            "success_rate": "90%"
        },
        {
            "name": "Name 3",
            "number": "9875654333",
            "success_rate": "80%"
        },
        {
            "name": "Name 4",
            "number": "9875654333",
            "success_rate": "70%"
        },
        {
            "name": "Name 5",
            "number": "9875654333",
            "success_rate": "60%"
        },
        {
            "name": "Name 6",
            "number": "9875654333",
            "success_rate": "50%"
        },
        {
            "name": "Name 7",
            "number": "9875654333",
            "success_rate": "40%"
        },
        {
            "name": "Name 8",
            "number": "9875654333",
            "success_rate": "30%"
        },
        {
            "name": "Name 9",
            "number": "9875654333",
            "success_rate": "20%"
        },
        {
            "name": "Name 10",
            "number": "9875654333",
            "success_rate": "10%"
        }
    ],
    "top_m3_list": [
        {
            "name": "Name 1",
            "number": "9875654333",
            "success_rate": "100%"
        },
        {
            "name": "Name 2",
            "number": "9875654333",
            "success_rate": "90%"
        },
        {
            "name": "Name 3",
            "number": "9875654333",
            "success_rate": "80%"
        },
        {
            "name": "Name 4",
            "number": "9875654333",
            "success_rate": "70%"
        },
        {
            "name": "Name 5",
            "number": "9875654333",
            "success_rate": "60%"
        },
        {
            "name": "Name 6",
            "number": "9875654333",
            "success_rate": "50%"
        },
        {
            "name": "Name 7",
            "number": "9875654333",
            "success_rate": "40%"
        },
        {
            "name": "Name 8",
            "number": "9875654333",
            "success_rate": "30%"
        },
        {
            "name": "Name 9",
            "number": "9875654333",
            "success_rate": "20%"
        },
        {
            "name": "Name 10",
            "number": "9875654333",
            "success_rate": "10%"
        }
    ],
    "top_tmm_list": [
        {
            "name": "Name 1",
            "number": "9875654333",
            "success_rate": "100%"
        },
        {
            "name": "Name 2",
            "number": "9875654333",
            "success_rate": "90%"
        },
        {
            "name": "Name 3",
            "number": "9875654333",
            "success_rate": "80%"
        },
        {
            "name": "Name 4",
            "number": "9875654333",
            "success_rate": "70%"
        },
        {
            "name": "Name 5",
            "number": "9875654333",
            "success_rate": "60%"
        },
        {
            "name": "Name 6",
            "number": "9875654333",
            "success_rate": "50%"
        },
        {
            "name": "Name 7",
            "number": "9875654333",
            "success_rate": "40%"
        },
        {
            "name": "Name 8",
            "number": "9875654333",
            "success_rate": "30%"
        },
        {
            "name": "Name 9",
            "number": "9875654333",
            "success_rate": "20%"
        },
        {
            "name": "Name 10",
            "number": "9875654333",
            "success_rate": "10%"
        }
    ],
    "top_csm_list": [
        {
            "name": "Name 1",
            "number": "9875654333",
            "success_rate": "100%"
        },
        {
            "name": "Name 2",
            "number": "9875654333",
            "success_rate": "90%"
        },
        {
            "name": "Name 3",
            "number": "9875654333",
            "success_rate": "80%"
        },
        {
            "name": "Name 4",
            "number": "9875654333",
            "success_rate": "70%"
        },
        {
            "name": "Name 5",
            "number": "9875654333",
            "success_rate": "60%"
        },
        {
            "name": "Name 6",
            "number": "9875654333",
            "success_rate": "50%"
        },
        {
            "name": "Name 7",
            "number": "9875654333",
            "success_rate": "40%"
        },
        {
            "name": "Name 8",
            "number": "9875654333",
            "success_rate": "30%"
        },
        {
            "name": "Name 9",
            "number": "9875654333",
            "success_rate": "20%"
        },
        {
            "name": "Name 10",
            "number": "9875654333",
            "success_rate": "10%"
        }
    ]
}