export const GENERAL_REPORT: any = {
    "tse_data": [
        {
            "name": 'Plan',
            "value": '1000'
        },
        {
            "name": 'Signed Up',
            "value": '200'
        },
        {
            "name": 'Logged in Last 7 Days',
            "value": '150'
        }
    ],
    "csm_data": [
        {
            
            "name": 'Plan',
            "value": '1000'
        },
        {
            
            "name": 'Signed Up',
            "value": '200'
        },
        {
            
            "name": 'Logged in Last 7 Days',
            "value": '150'
        }
    ],
    "waiter_data": [
        {
            
            "name": 'Plan',
            "value": '1000'
        },
        {
            
            "name": 'Signed Up',
            "value": '200'
        },
        {
            
            "name": 'Logged in Last 7 Days',
            "value": '150'
        }
    ],
    "kingservice": [
        {
            "name": "Volume Achievment",
            "data": "100"
        },
        {
            "name": "% POC achieving target",
            "data": "100",
        },
        {
            "name": "Uplift",
            "data": "100"
        },
        {
            "name": "HE MIX",
            "data": "100"
        },
        {
            "name": "ROI",
            "data": "100"
        }
    ],
    "weekly_challenges": [
        {
            
            "name": 'Available',
            "value": '150'
        },
        {
            
            "name": 'Attempted',
            "value": '150'
        },
        {
            
            "name": 'Successful',
            "value": '150'
        },
    ],
    "content_learning": [
        {
            
            "name": 'Available',
            "value": '150'
        },
        {
            
            "name": 'Attempted',
            "value": '150'
        },
        {
            
            "name": 'Successful',
            "value": '150'
        },
    ],
    "mysteryshopper": [
        {
            "name": "No. Outlets whitelisted",
            "data": 100
        },
        {
            "name": "Audited",
            "data": 100
        },
        {
            "name": "Average total performance",
            "data": 100
        },
        {
            "name": "Total Payout Available (INR)",
            "data": 100
        },
        {
            "name": "Total Actual Payout (INR)",
            "data": 100
        },
        {
            "name": "Total Execution Payout Available (INR)",
            "data": 100
        },
        {
            "name": "Total Recommendation Entries",
            "data": 100
        },
        {
            "name": "Total Recommendation Entries Marked Successful",
            "data": 100
        },
        {
            "name": "Total Recommendation Payout Available (INR)",
            "data": 100
        },
        {
            "name": "Total Actual Recommendation Payout (INR)",
            "data": 100
        },
        {
            "name": "Total Volume Target",
            "data": 100
        },
        {
            "name": "Total Volume Achievement",
            "data": 100
        },
        {
            "name": "Total Volume Payout Available (INR)",
            "data": 100
        }
    ],
    "cumulative_reward_tat": [
        {
            "name": "Brand 1",
            "data": 35
        },
        {
            "name": "Brand 2",
            "data": 55
        },
        {
            "name": "Brand 3",
            "data": 70
        },
        {
            "name": "Brand 4",
            "data": 100
        },
        {
            "name": "Brand 5",
            "data": 85
        },
        {
            "name": "Brand 6",
            "data": 100
        }
    ],
    "crown_collection": [
        {
            "name": "Volume Achievement",
            "value": 1000
        },
        {
            "name": "POC Target",
            "value": 1000
        }
    ],
    "menu_program": [
        {
            "name": "No of Outlets",
            "value": 1000
        },
        {
            "name": "Not Initated",
            "value": 500
        },
        {
            "name": "Pending With TSE",
            "value": 300
        },
        {
            "name": "Approved",
            "value": 500
        }
    ],
    "coolers": [
        {
            "name": 'Available',
            "value": 60,
            "color": "#725CA4"
        },
        {
            "name": 'Attempted',
            "value": 60,
            "color": "#04AEE6"
        },
        {
            "name": 'Not 100% ABI Pure',
            "value": 60,
            "color": "#EBB763"
        },
        {
            "name": '100% ABI Pure but not as per plangoram',
            "value": 10,
            "color": "#725CA4"
        },
        {
            "name": "100% ABI Pure as per plangoram",
            "value": 10,
            "color": "#04AEE6"
        }
    ],
    "branding": [
        {
            "name": "Available",
            "value": 1000,
            "color": "#725CA4"
        },
        {
            "name": "Attempted",
            "value": 5000,
            "color": "#04AEE6"
        },
        {
            "name": "Successfull",
            "value": 3000,
            "color": "#EBB763"
        }
    ],
    "average_time_spent": [
        {
            "name": "Bud4Trade app",
            "hours": 12,
        },
        {
            "name": "Kingservice",
            "hours": 12,
        },
        {
            "name": "Content & Learning",
            "hours": 12,
        },
        {
            "name": "Weekly Challenges",
            "hours": 12,
        },
        {
            "name": "Coolers & Branding",
            "hours": 12,
        },
        {
            "name": "Reward Store",
            "hours": 12,
        }
    ],
    "average_open_rate": [
        {
            "name": "Bud4Trade app",
            "open_rate": 3.4,
        },
        {
            "name": "Kingservice",
            "open_rate": 3.4,
        },
        {
            "name": "Content & Learning",
            "open_rate": 3.4,
        },
        {
            "name": "Weekly Challenges",
            "open_rate": 3.4,
        },
        {
            "name": "Coolers & Branding",
            "open_rate": 3.4,
        },
        {
            "name": "Reward Store",
            "open_rate": 3.4,
        }
    ],
    "reward_tat": [
        {
            "name": "Bud4Trade app",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        },
        {
            "name": "Kingservice",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        },
        {
            "name": "Content & Learning",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        },
        {
            "name": "Weekly Challenges",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        },
        {
            "name": "Coolers & Branding",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        },
        {
            "name": "Reward Store",
            "total_value_budgeted": 100,
            "total_value_earned": 500,
        }
    ],
    "consumer_promo": [
        {
            "name": "Scheme1",
            "open_rate": 500
        },
        {
            "name": "Scheme2",
            "open_rate": 500
        },
        {
            "name": "Scheme3",
            "open_rate": 500
        },
        {
            "name": "Scheme4",
            "open_rate": 500
        },
        {
            "name": "Scheme5",
            "open_rate": 500
        },
        {
            "name": "Scheme6",
            "open_rate": 500
        }
    ],
    "avg_reward_tat": [
        {
            "name": "Bud4Trade app",
            "avg_time": "15"
        },
        {
            "name": "King Service",
            "avg_time": "15"
        },
        {
            "name": "King Service",
            "avg_time": "15"
        },
        {
            "name": "King Service",
            "avg_time": "15"
        },
        {
            "name": "King Service",
            "avg_time": "15"
        }
    ]
}