import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/model/user-data.service';
import { UsersModel } from 'src/app/model/contract/user';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { SchemeModel } from 'src/app/model/contract/scheme';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { LoyaltyTargetModel, TargetModel, CrownTargetModel, CartonInsertModel, ConfigDataModel, MenuProgramModel } from 'src/app/model/contract/LoyaltyTarget';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class LoyaltyTargetProvider {

    loyaltyTarget: LoyaltyTargetModel[];
    crownTarget: CrownTargetModel[];
    menuProgram: MenuProgramModel[];
    cartonInsert: CartonInsertModel[];
    crownCount: number;
    cartonCount: number;
    ConfigData: ConfigDataModel[];

    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        private users: UserDataService,
        public alertService: AlertService,
        private settingDataService: SettingDataService,
        public toasterService: ToastrService) {

    }


    addTarget(loyaltyTarget: LoyaltyTargetModel) {
        this.settingDataService.addTarget(loyaltyTarget)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Target Added Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.loyaltyTarget = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addCrownTarget(loyaltyTarget: CrownTargetModel) {
        this.settingDataService.addCrownTarget(loyaltyTarget)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Target Added Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.crownTarget = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    MenuWhitelist(loyaltyTarget: MenuProgramModel) {
        this.settingDataService.addMenuTarget(loyaltyTarget)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Added Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.menuProgram = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addCartonInsert(loyaltyTarget: CartonInsertModel) {
        this.settingDataService.addCartonInsert(loyaltyTarget)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Carton Insert Added Successfully',
                        "Success", {
                        timeOut: 1000,
                    });
                    this.cartonInsert = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getTarget() {
        let program_id = 1;
        this.settingDataService.getAllTarget(program_id)
            .subscribe(
                (response: any) => {
                    this.loyaltyTarget = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    getCrownTarget() {
        let program_id = 1;
        this.settingDataService.getAllCrownTarget(program_id)
            .subscribe(
                (response: any) => {
                    this.crownTarget = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMenuProgram() {
        let program_id = 1;
        this.settingDataService.getAllMenuProgram(program_id)
            .subscribe(
                (response: any) => {
                    this.menuProgram = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getCartonInsert() {
        let program_id = 1;
        this.settingDataService.getAllCartonInsert(program_id)
            .subscribe(
                (response: any) => {
                    this.cartonInsert = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    updateTarget(scheme: LoyaltyTargetModel) {
        this.crownLoader = false;
        this.settingDataService.updateTarget(scheme)
            .subscribe(
                (response: any) => {
                    this.getTarget();
                    this.crownLoader = true;
                    this.toasterService.success('Target Updated Successfully', "Success", {
                        timeOut: 1000,
                    });
                },
                error => {
                    this.getTarget();
                    this.alertService.error(error);
                }
            );
        this.alertService.clear();
    }


    crownLoader: boolean;
    updateCrownTarget(scheme: CrownTargetModel) {
        this.crownLoader = false;
        this.settingDataService.updateCrownTarget(scheme)
            .subscribe(
                (response: any) => {
                    this.crownLoader = true;
                    this.toasterService.success('Crown Target Updated Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.getCrownTarget();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }
    cartonLoader: boolean;
    updateCartonInsert(scheme: CartonInsertModel) {
        this.cartonLoader = false;
        this.settingDataService.updateCartonInsert(scheme)
            .subscribe(
                (response: any) => {
                    this.cartonLoader = true;
                    this.toasterService.success('Carton Inserted Whitelisted Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.getCartonInsert();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    menuLoader: boolean;
    updateMenuProgram(scheme: MenuProgramModel) {
        this.menuLoader = false;
        this.settingDataService.updateMenuProgram(scheme)
            .subscribe(
                (response: any) => {
                    this.menuLoader = true;
                    this.toasterService.success('Whitelisted Successfully', "Success", {
                        timeOut: 1000,
                    });
                    this.getMenuProgram();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getTargetCount(id: number) {
        this.settingDataService.countCrownTarget(id)
            .subscribe(
                (response: any) => {
                    this.crownCount = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    cartonexcelupload(data) {
        this.settingDataService.cartonexcelupload(data)
            .subscribe(
                (response: any) => {
                    this.alertService.clear();
                    this.toasterService.success('Configured Successfully', "Success", {
                        timeOut: 1000,
                    });
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    getCartonCount(id: number) {
        this.settingDataService.countCartonWhitelist(id)
            .subscribe(
                (response: any) => {
                    this.crownCount = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getcartonconfig(id: number) {
        this.settingDataService.getcartonconfig(id)
            .subscribe(
                (response: any) => {
                    this.ConfigData = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

}