import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-dropdown',
  templateUrl: './common-dropdown.component.html',
  styleUrls: ['./common-dropdown.component.scss']
})
export class CommonDropdownComponent implements OnInit {
  @Input() selecDetails: any;
  @Input() multiple: any;
  @Input() dropdownName: any;
  @Input() reset: boolean;
  @Input() selection: boolean;
  @Input() show: boolean;
  @Input()selectedValue1:any;
  @Input()selectedValue2:any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  selectedValue: any;
  @Input() selectedItems : any;
  constructor() {
    if(typeof this.show == "undefined") {
      this.show = true;
    }
  }
  dropdownSettings = {};
  ngOnInit() {
    this.selectedValue = this.selectedItems;
    // this.selectedValue = 0;
    console.log(this.selecDetails)
    this.dropdownSettings = {
      singleSelection: this.selection,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      enableCheckAll:false,
      limitSelection:500,
    };
  }

  valueChanged(value): void {

  }

  onItemDeSelect() {
    this.notify.emit(this.selectedValue);
  }

  ngOnChanges() {
    // console.log(this.selectedValue1)
    if(this.reset == true) {
      this.selectedValue = [];
    }

  }


onItemSelect(item: any) {
    console.log(this.selectedValue);
  if (typeof this.selectedValue.find(i => i.id).id != "undefined") {
    this.notify.emit(this.selectedValue);
  }
}
}
