import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { LeaderboardModel } from 'src/app/model/contract/setting';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class LeaderboardProvider {

    leaderboardDataList: LeaderboardModel[];

    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        public alertService: AlertService,
        private settingDataService: SettingDataService,
        public toasterService: ToastrService) {}

    getLeaderboards() {
        this.settingDataService.getAllLeaderboards()
        .subscribe(
            (response: any) => {
                this.leaderboardDataList = response.data.leaderboards;
            },
            error => {
                this.alertService.error(error);
            }
        )
    }

    addLeaderboard(leaderboard: LeaderboardModel) {
        this.settingDataService.addLeaderboard(leaderboard)
        .subscribe(
            (response: any) => {
                this.toasterService.success('Leaderboard Added Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getLeaderboards();
            },
            error => {
                console.log(error);
                this.alertService.error(error);
            }
        )
    }

    updateLeaderboard(leaderboard: LeaderboardModel) {
        this.settingDataService.updateLeaderboard(leaderboard)
        .subscribe(
            (response: any) => {
                this.toasterService.success('Leaderboard updated Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getLeaderboards();
            },
            error => {
                console.log(error);
                this.alertService.error(error);
            }
        )
    }
}