import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  
  private loginState  = localStorage.getItem('loginState');
  title = 'app';
  constructor() {

  }
}
