import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Input,
} from "@angular/core";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { DatePipe } from "@angular/common";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { StateModel } from "src/app/model/contract/menu-program";
import {
  ChannelModel,
  FrequencyModel,
  RegionModel,
  ChartModel,
} from "src/app/model/contract/channel";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { GeneralService } from "../Services/general.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import {
  DROPDOWN_DETAILS,
  M1_CHALLENGE,
  M2_CHALLENGE,
  M3_CHALLENGE,
  CSM_CHALLENGE,
  COLOR_TITLE,
} from "../Models/general-headings.model";
import { CommonHttpServiceService } from "../Services/common-http-service.service";
import { TileModel, WhitleTile } from "../Models/tile_data.model";
import { ToastrService } from "ngx-toastr";
import { FilterModel } from "src/app/nesa-admin/Models/filter-data";
declare var jspdf;
declare var $: any;

@Component({
  selector: "app-engagement",
  templateUrl: "./engagement.component.html",
  styleUrls: ["./engagement.component.scss"],
})
export class EngagementComponent implements OnInit {
  dateRange: any = [];
  data: any;
  //Data Variables;
  dropdown: any[] = DROPDOWN_DETAILS;
  //Table Headers
  m1Challenge = M1_CHALLENGE;
  m2Challenge = M2_CHALLENGE;
  m3Challenge = M3_CHALLENGE;
  CSMChallenge = CSM_CHALLENGE;
  //Table Header @Input
  projectSettings = this.m1Challenge;
  M2Settings = this.m2Challenge;
  M3Settings = this.m3Challenge;
  CSMSettings = this.CSMChallenge;
  min: number;
  max: number;
  smonth: any = "0";
  sdate: any = "0";
  options: Array<any> = [];
  colors: any[] = COLOR_TITLE;
  POINTS_SUMMARY: TileModel[] = [];
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  selectOption = [
    { id: 1, name: "State" },
    { id: 2, name: "Region" },
    { id: 3, name: "M1" },
    { id: 4, name: "M2" },
    { id: 5, name: "M3" },
  ];

  // Date Variable;

  start_date: any;
  end_date: any;

  // Bar Chart

  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];

  // Dropdown Names

  regionDropdown = "Select Region";
  stateDropdown = "Select State";
  channelDropdown = "Select Channel";
  m1Dropdown = "Select M1";
  m2Dropdown = "Select M2";
  m3Dropdown = "Select M3";
  startDate: any;
  endDate: any;

  // Dropdown Selected Values;

  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedM1: number[] = [];
  selectedM2: number[] = [];
  selectedM3: number[] = [];
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  event = [];
  resetDropdown: boolean;
  constructor(
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService
  ) {}


  @Input() filterData: FilterModel;
  spinner: boolean = true;
  ngOnChanges() {
    console.log(this.filterData);
    this.removeVariables();
    this.data = [];
    if(typeof this.filterData != "undefined") {
      this.getWeeklyReport(
        this.filterData.startDate,
        this.filterData.endDate,
        this.filterData.region,
        this.filterData.state,
        this.filterData.channel,
        this.filterData.m1,
        this.filterData.m2,
        this.filterData.m3,
        this.filterData.smonth,
        this.filterData.sdate
      );
    }

  }

  ngOnInit() {
    this.getWeeklyReport(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    (this.min = 2019), (this.max = new Date().getFullYear());
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    this.options.reverse();
  }
  Cumulative_pointsData;
  getWeeklyReport(
    startdate,
    enddate,
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id,
    month,
    year
  ) {
    let calendar_year = parseInt(localStorage.getItem("calendar_year"));
    this.removeVariables();
    this.ngxLoader.start();
    this.httpClient
      .engagements(
        startdate,
        enddate,
        region,
        state,
        channel,
        tse_user_id,
        m2_user_id,
        m3_user_id,
        month,
        year,
        calendar_year
      )
      .subscribe(
        (res: any) => {
          this.removeVariables();
          this.data = res.data;
          let i = 0;
          let j = 0;
          let k = 0;
          // this.data.points_summary.forEach(element => {
          //   let data: TileModel = new TileModel;
          //   data.color = this.colors[i];
          //   data.name = element.name;
          //   data.value = element.value;
          //   this.POINTS_SUMMARY.push(data);
          //   i++;
          // });

          if (this.data.engsummary.length != 0) {
            this.Cumulative_pointsData = [];
            this.data.engsummary.forEach((element) => {
              let data: WhitleTile = new WhitleTile();
              data.data = element.value;
              data.name = element.name;
              data.breakupdata = element.breakupdata;
              data.name = element.name.replace("POC", "CSM");
              data.name = element.name.replace("Outlet", "CSM");
              data.name = element.name.replace("User", "CSM");
              data.name = element.name.replace("poc", "CSM");
              data.name = element.name.replace("outlet", "CSM");
              data.name = element.name.replace("user", "CSM");
              if(data.name == 'Total Enagagement Available') {
                data.name = 'Total Enagagement (Available)';
              }
              else if(data.name == 'Total Enagagement Completed') {
                data.name = 'Total Enagagement (Completed)';
              }
              else if(data.name == 'Total Enagagement Success') {
                data.name = 'Total Enagagement (Success)';
              }
              else if(data.name == 'Total Enagagement Points Available') {
                data.name = 'Total Enagagement (Points Available)';
              }
              else if(data.name == 'Total Points Earned') {
                data.name = 'Total Enagagement (Earned)';
              }
              else if(data.name == 'Total Payout Available (Inr)') {
                data.name = 'Total Enagagement (INR)';
              }
              else if(data.name == 'Total Actual Payout (Inr)') {
                data.name = 'Total Actual Payout (INR)';
              }
              data.name.includes("%") ? (data.type = "%") : "";
              data.name.includes("INR") ? (data.data = "₹ " + data.data) : "";
              this.Cumulative_pointsData.push(data);
            });
          }
          this.ngxLoader.stop();
        },
        (error) => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`, "Dashboard", {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
  }

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    // console.log('---states---')
    // console.log(this.states);
    this.dropdown.find((d) => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find((d) => d.id == 3).data = this.channels;
    if (this.event == undefined || this.event.length < 1) {
      this.dropdown.find((d) => d.id == 4).data = this.m1Users;
      this.dropdown.find((d) => d.id == 5).data = this.m2Users;
      this.dropdown.find((d) => d.id == 6).data = this.m3Users;
    }
    // this.dropdown.find(d => d.id == 4).data = this.m1Users;
    // this.dropdown.find(d => d.id == 5).data = this.m2Users;
    // this.dropdown.find(d => d.id == 6).data = this.m3Users;
  }

  // Service Call
  m1FilterByRegion = [];
  m2FilterByRegion = [];
  m3FilterByRegion = [];

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    this.event = event;
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        if (event != 0) {
          event.forEach((element) => {
            let data = this.states.filter((s) => s.region_id == element.id);
            let m1Data = this.m1Users.filter((s) => s.region_id == element.id);
            let m2Data = this.m2Users.filter((s) => s.region_id == element.id);
            let m3Data = this.m3Users.filter((s) => s.region_id == element.id);
            this.m1FilterByRegion.push(...m1Data);
            this.m2FilterByRegion.push(...m2Data);
            this.m3FilterByRegion.push(...m3Data);
            this.StatesfilterByRegion.push(...data);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        break;
      case 2:
        this.state = this.getList(event, "selectedState");

        break;
      case 3:
        this.channel = this.getList(event, "selectedChannel");

        break;
      case 4:
        this.m1 = this.getList(event, "selectedM1");

        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event, "selectedM2");
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 = this.getList(event, "selectedM3");
        break;
    }
    // this.searchByFilter();
  }

  getList(event, mainData) {
    this[mainData] = event;

    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
      console.log(this[mainData]);
    } else {
      g = "";
    }

    return g;
  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }

  errorMsg: any = "";
  errorMsg1: any = "";

  searchByFilter() {
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.resetDropdown = false;
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (
      this.selectedChannel === undefined ||
      this.selectedChannel.length == 0
    ) {
      this.channel = "";
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = "";
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = "";
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = "";
    }
    if (this.smonth == "0" && this.sdate == "0") {
      this.errorMsg = "Please select year";
      this.errorMsg1 = "Please select month";

      // if(parseInt(this.smonth) !=0) {
      //   if(parseInt(this.sdate) !=0) {
      //       this.getGeneralReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.whitelist_date1,this.smonth,this.sdate);
      //   }
      //   else{
      //     this.errorMsg = "Please select year";
      //     this.errorMsg1 = "Please select month";
      //   }
      // }
    } else {
      if (typeof this.smonth != "undefined") {
        if (parseInt(this.smonth) != 0) {
          if (parseInt(this.sdate) != 0) {
            this.getWeeklyReport(
              this.startDate,
              this.endDate,
              this.region,
              this.state,
              this.channel,
              this.m1,
              this.m2,
              this.m3,
              this.smonth,
              this.sdate
            );
          } else {
            this.errorMsg = "Please select year";
          }
        }
      }

      if (typeof this.sdate != "undefined") {
        if (parseInt(this.sdate) != 0) {
          if (parseInt(this.smonth) != 0) {
            this.getWeeklyReport(
              this.startDate,
              this.endDate,
              this.region,
              this.state,
              this.channel,
              this.m1,
              this.m2,
              this.m3,
              this.smonth,
              this.sdate
            );
          } else {
            this.errorMsg1 = "Please select month";
          }
        }
      }
    }

    // if(typeof this.smonth !="undefined") {
    //   if(parseInt(this.smonth) !=0) {
    //     if(parseInt(this.sdate) !=0) {
    //       this.removeVariables();
    //       this.getWeeklyReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg = "Please select year";
    //     }
    //   }
    // }

    // if(typeof this.sdate !="undefined") {
    //   if(parseInt(this.sdate) !=0) {
    //     if(parseInt(this.smonth) !=0) {
    //       this.removeVariables();
    //       this.getWeeklyReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg1 = "Please select month";
    //     }
    //   }
    // }
  }

  removeVariables() {
    this.POINTS_SUMMARY = [];
    this.Cumulative_pointsData = [];
  }

  public convetToPDF() {
    this.ngxLoader.startBackground();
    var data = document.getElementById("Program-Overview");
    $(data).prepend(
      "<p id='gr' style='font-size:40px;margin:40px 0px 60px 0px;'>Weekely Challenges</p> "
    );
    html2canvas(data)
      .then((canvas) => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jsPDF.jsPDF("p", "mm", "a4"); // A4 size page of PDF
        var position = 0;
        heightLeft -= pageHeight;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("content_report.pdf");
        this.ngxLoader.stopBackground();
      })
      .then(() => {
        $("#gr").remove();
      });
  }
}
