export class TileModel{
    name : string;
    color : string;
    value : any;
    value1 : any;
}

export class WhitleTile{
    name : string;
    data : string;
    type : string;
    breakupdata:any;
}

