import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/model/user-data.service';
import { UsersModel } from 'src/app/model/contract/user';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { SchemeModel } from 'src/app/model/contract/scheme';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class LoyaltySchemeProvider {

    schemeDataList: SchemeModel[];

    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        private users: UserDataService,
        public alertService: AlertService,
        private settingDataService: SettingDataService,
        public toasterService: ToastrService) {

    }

    getSchemeList() {
        let program_id = 1;
        this.settingDataService.getAllSchemes(program_id)
        .subscribe(
            (response: any) => {
                this.schemeDataList = response.data.outletSchemes;
            },
            error => {
                this.alertService.error(error);
            }
        )
    }

    addScheme(scheme: SchemeModel) {
        this.settingDataService.addScheme(scheme)
        .subscribe(
            (response: any) => {
                this.alertService.clear();
                this.toasterService.success('Scheme Added Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getSchemeList();
            },
            error => {
                this.alertService.error(error);
            }
        )
    }

    updateScheme(scheme: SchemeModel) {
        this.settingDataService.updateScheme(scheme)
        .subscribe(
            (response: any) => {
                this.toasterService.success('Scheme Updated Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getSchemeList();
            },
            error => {
                this.alertService.error(error);
            }
        )
    }
}