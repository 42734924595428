import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { GlobalService } from './global.service';
import { Setting } from './contract/setting';
import { StaffService } from './staff.service';
import { LoyaltyBannerModel, SchemesOffersModel, WhitelistOutletModel, PointStructureModel, ChannelTypeModel, UserProfileModel } from './contract/user';
import { ChannelModel, FrequencyModel, OutletModel, BrandCategoryModel, UnitModel, ModulesModel, MessageTrigger, CommunicationModel, RegionModel } from './contract/channel';
import { SchemeModel } from './contract/scheme';

import { ResponseBody } from './contract/response-body';

import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry } from 'rxjs/operators';
import { LoyaltyTargetModel, TargetModel, CrownTargetModel, CartonInsertModel, MenuProgramModel } from './contract/LoyaltyTarget';
import { PosmAuditType, PosmConfigModel, PosmExcel } from './contract/posm';
import { ContentLearningModel } from './contract/content-learning';
import { LeaderboardModel } from './contract/setting';

@Injectable()
export class SettingDataService {
  program_id: string;
  constructor(
    private globalService: GlobalService,
    private staffService: StaffService,
    private http: HttpClient
  ) {
    this.program_id = localStorage.getItem('program_id');
  }

  public static getMetaTypes(): Array<any> {
    return [
      {
        label: 'Select',
        value: 'select'
      },
      {
        label: 'Number',
        value: 'number'
      },
      {
        label: 'Text',
        value: 'text'
      }
    ];
  }

  public static getIsPublicTypes(): Array<any> {
    return [
      {
        label: 'Public',
        value: 1
      },
      {
        label: 'Private',
        value: 0
      }
    ];
  }

  // POST /v1/setting
  addSetting(setting: Setting): Observable<any> {
    const headers = this.getHeaders();

    return this.http
      .post<ResponseBody>(
        this.globalService.apiHost + '/setting',
        JSON.stringify(setting),
        {
          headers: headers
        }
      )
      .map(response => {
        return response;
      })
      .catch(this.handleError);
  }

  // DELETE /v1/setting/1
  deleteSettingById(id: number): Observable<any> {
    const headers = this.getHeaders();

    return this.http
      .delete<ResponseBody>(this.globalService.apiHost + '/setting/' + id, {
        headers: headers
      })
      .map(response => {
        return response;
      })
      .catch(this.handleError);
  }

  // PUT /v1/setting/1
  updateSettingById(setting: Setting): Observable<any> {
    const headers = this.getHeaders();

    return this.http
      .put<ResponseBody>(
        this.globalService.apiHost + '/setting/' + setting.id,
        JSON.stringify(setting),
        {
          headers: headers
        }
      )
      .map(response => {
        return response;
      })
      .catch(this.handleError);
  }

  // GET /v1/setting
  getAllSettings(): Observable<Setting[]> {
    const headers = this.getHeaders();

    return this.http
      .get<ResponseBody>(
        this.globalService.apiHost + '/setting?sort=meta_key',
        {
          headers: headers
        }
      )
      .map(response => {
        return <Setting[]>response.data;
      })
      .catch(this.handleError);
  }

  refreshGlobalSettings(): void {
    // get settings
    this.globalService.loadGlobalSettingsFromSessionStorage();

    this.getAllSettingsPublic().subscribe(settings => {
      settings.forEach(setting => {
        switch (setting.meta_type) {
          case 'select':
          case 'text':
            this.globalService.setting[setting.meta_key] = setting.meta_value;
            break;
          case 'number':
            this.globalService.setting[setting.meta_key] = +setting.meta_value;
            break;
        }
        sessionStorage.setItem(
          'backend-setting',
          JSON.stringify(this.globalService.setting)
        );
      });
    });
  }

  // GET /v1/setting/public
  getAllSettingsPublic(): Observable<Array<any>> {
    const headers = this.getHeaders();

    return this.http
      .get<ResponseBody>(this.globalService.apiHost + '/setting/public', {
        // headers: headers
      })
      .map(response => {
        return <Array<any>>response.data;
      })
      .catch(this.handleError);
  }

  getSettingById(id: number): Observable<Setting> {
    const headers = this.getHeaders();

    return this.http
      .get<ResponseBody>(this.globalService.apiHost + '/setting/' + id, {
        headers: headers
      })
      .map(response => {
        return <Setting>response.data;
      })
      .catch(this.handleError);
  }


  // GET /v1/hub-admin
  getAllBanners(): Observable<LoyaltyBannerModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallbanners';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <LoyaltyBannerModel[]>response;
        }
      )
  }

  addExcelData(user: any, uploadUrl: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/' + uploadUrl;
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  // GET /v1/hub-admin
  getAllSchemes(id: number): Observable<SchemeModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getoutletschemes?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <SchemeModel[]>response;
        }
      )
  }

  // GET /v1/hub-admin
  getAllWhitelistedOutlets(id : number): Observable<WhitelistOutletModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getwhitelistoutlets?target_id='+ id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <WhitelistOutletModel[]>response;
        }
      )
  }

  getAllPointStructure(): Observable<PointStructureModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getpointstructure';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <PointStructureModel[]>response;
        }
      )
  }

  getAllChannelType(): Observable<ChannelTypeModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallchannel';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ChannelTypeModel[]>response;
        }
      )
  }

  getUserProfiles(program_id: number): Observable<UserProfileModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getuserprofiles?program_id=' + program_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <UserProfileModel[]>response;
        }
      )
  }

  getAllFrequency(): Observable<FrequencyModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallfrequency';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <FrequencyModel[]>response;
        }
      )
  }

  getAllReports(): Observable<FrequencyModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/loadstaticreports?program_id='+this.program_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <FrequencyModel[]>response;
        }
      )
  }

  getAllChannel(): Observable<ChannelModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallchannel';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ChannelModel[]>response;
        }
      )
  }

  getAllOutlets(channel_id: number, geographical_id: number): Observable<OutletModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getalloutlets?channel_id=' + channel_id + '&geographical_id=' + geographical_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <OutletModel[]>response;
        }
      )
  }

  getAllCategories(): Observable<BrandCategoryModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallbrandcategory';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return <BrandCategoryModel[]>response;
        }
      )
  }

  getAllModules(): Observable<ModulesModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallmodules';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ModulesModel[]>response;
        }
      )
  }

  getAllMessageTrigger(): Observable<MessageTrigger[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallmessagetriggers';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <MessageTrigger[]>response;
        }
      )
  }

  getAllUnits(): Observable<UnitModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallunit';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <UnitModel[]>response;
        }
      )
  }

  addScheme(scheme: SchemeModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addscheme';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateScheme(scheme: SchemeModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatescheme';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateTarget(target: LoyaltyTargetModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatetarget';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateCrownTarget(target: CrownTargetModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatecrown';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateMenuProgram(target: MenuProgramModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatemenu';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateCartonInsert(target: CartonInsertModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatecarton';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  countCrownTarget(id: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getcrowncount?id=' + id;
    return this.http.get<ResponseBody>(apiUrl, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  cartonexcelupload(target: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/cartonexcelupload';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  // getcartonconfig

  getcartonconfig(id: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getcartonconfig?id=' + id;
    return this.http.get<ResponseBody>(apiUrl, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  countCartonWhitelist(id: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getcartoncount?id=' + id;
    return this.http.get<ResponseBody>(apiUrl, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.staffService.getToken()
    });
  }

  private handleError(response: Response | any) {
    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return Observable.throw(errorMessage);
  }

  private getURLList() {
    return this.globalService.apiHost + '/hub-admin';
  }

  private getURLList1() {
    return this.globalService.apiHost + '/adminreport';
  }


  addTarget(scheme: LoyaltyTargetModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addtarget';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addCrownTarget(scheme: CrownTargetModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addcrowntarget';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addMenuTarget(scheme: MenuProgramModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmenuprogram';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addCartonInsert(scheme: CartonInsertModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addcarton';
    let body = JSON.stringify(scheme);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }



  getAllTarget(id: number): Observable<LoyaltyTargetModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getalltargets?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <LoyaltyTargetModel[]>response;
        }
      )
  }

  getAllCrownTarget(id: number): Observable<CrownTargetModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallcrowntargets?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CrownTargetModel[]>response;
        }
      )
  }

  getAllMenuProgram(id: number): Observable<CrownTargetModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallmenuprograms?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CrownTargetModel[]>response;
        }
      )
  }

  getAllCartonInsert(id: number): Observable<CartonInsertModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallcarton?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CartonInsertModel[]>response;
        }
      )
  }

  getAllPOSMAuditType(id: number): Observable<PosmAuditType[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/posmaudittype?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <PosmAuditType[]>response;
        }
      )
  }

  getPOSMConfig(id: number): Observable<PosmAuditType[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getposmconfig?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <PosmAuditType[]>response;
        }
      )
  }

  addPOSMConfig(posmConfig : PosmConfigModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addposmconfig';
    let body = JSON.stringify(posmConfig);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updatePOSMConfig(posmConfig : PosmConfigModel, id : number): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updateposmconfig?id='+id;
    let body = JSON.stringify(posmConfig);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
    }

    excelPOSMWhiteList(posmExcel: PosmExcel): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.getURLList() + '/posmwhitelist';
      let body = JSON.stringify(posmExcel);
      return this.http.post<any[]>(apiUrl, body, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return response;
          }
        )
      }
      excelCamapignWhiteList(posmExcel: PosmExcel): Observable<any[]> {
        let headers = this.getHeaders();
        let apiUrl = this.getURLList() + '/camapignwhitelist';
        let body = JSON.stringify(posmExcel);
        return this.http.post<any[]>(apiUrl, body, { headers: headers })
          .pipe(
            catchError(this.handleError)
          )
          .map(
            response => {
              return response;
            }
          )
        }

  getCourses(id: number): Observable<ContentLearningModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getcourses?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <ContentLearningModel[]> response;
      }
    )
  }

  addCourse(course: ContentLearningModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addcourse';
    let body = JSON.stringify(course);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateCourse(course: ContentLearningModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatecourse';
    let body = JSON.stringify(course);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }


  addCommunication(communication: CommunicationModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addcommunication';
    let body = JSON.stringify(communication);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }


  getCommunication(id: number): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getcommunication?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getAllLeaderboards(): Observable<LeaderboardModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallleaderboard';
    return this.http.get(apiUrl, { headers: headers })
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <LeaderboardModel[]> response;
      }
    )
  }

  addLeaderboard(leaderboard: LeaderboardModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addleaderboard';
    let body = JSON.stringify(leaderboard);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateLeaderboard(leaderboard: LeaderboardModel): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updateleaderboard';
    let body = JSON.stringify(leaderboard);
    return this.http.post<any[]>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  getAllRegion(): Observable<RegionModel[]> {
    let year = parseInt(localStorage.getItem("calendar_year"));
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallregion?calendar_year='+year;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <RegionModel[]>response;
        }
      )
  }

  getAllRegionOutlets(channel_id: number, region_id: number): Observable<OutletModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallregionoutlets?channel_id=' + channel_id + '&region_id=' + region_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <OutletModel[]>response;
        }
      )
  }

  ModuleOutlets(module_id : number): Observable<OutletModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/whitelistoutletsbyid?module_id=' + module_id ;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <OutletModel[]>response;
        }
      )
  }
}
