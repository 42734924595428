import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uplift-report',
  templateUrl: './uplift-report.component.html',
  styleUrls: ['./uplift-report.component.scss']
})
export class UpliftReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
