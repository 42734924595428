import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/model/user-data.service';
import { UsersModel } from 'src/app/model/contract/user';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { SchemeModel } from 'src/app/model/contract/scheme';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { PosmAuditType, PosmConfigModel, PosmExcel } from 'src/app/model/contract/posm';
import { CommunicationModel, communicationData } from 'src/app/model/contract/channel';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class VisibilityProvider {

    posmAuditTypeList: PosmAuditType[];
    posmConfigList : PosmConfigModel[];
    communicationData :communicationData[];
    communication : CommunicationModel[];
    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        private users: UserDataService,
        public alertService: AlertService,
        private settingDataService: SettingDataService,
        public toasterService: ToastrService) {

    }

    getAuditList() {
        let program_id = 1;
        this.settingDataService.getAllPOSMAuditType(program_id)
            .subscribe(
                (response: any) => {
                    this.posmAuditTypeList = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getPosmConfigDataList() {
        let program_id = 1;
        this.settingDataService.getPOSMConfig(program_id)
            .subscribe(
                (response: any) => {
                    this.posmConfigList = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }
    addPosmConfig(PosmConfig) {
        this.settingDataService.addPOSMConfig(PosmConfig)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Data Inserted Successfully', "Success", {
                        timeOut: 1000,
                      });
                    this.posmConfigList = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    updatePosmConfig(PosmConfig : PosmConfigModel){
        console.log(PosmConfig);
        this.settingDataService.updatePOSMConfig(PosmConfig, PosmConfig.id)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Data Inserted Successfully', "Success", {
                        timeOut: 1000,
                      });
                    this.getPosmConfigDataList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getAllCommunication() {
        let program_id = 1;
        this.settingDataService.getCommunication(program_id)
            .subscribe(
                (response: any) => {
                    this.communicationData = response.data;
                    if(this.communicationData.length > 0) {
                        this.communicationData.forEach(
                            c => {
                                c.showImageUrl = false;
                                let formt = c.format.split(',');
                                formt.forEach(
                                    f => {
                                        if(f == '1'){
                                            c.format_name = 'SMS, '
                                        }
                                        else if( f == '2'){
                                            c.format_name += 'Email,'
                                        }
                                        else if(f == '3'){
                                            c.format_name += 'Notification,'
                                        }
                                        else if( f == '4'){
                                            c.format_name += 'IVR,'
                                        }
                                    }
                                );

                                if(c.image_url == null) {
                                    c.showImageUrl = false;
                                }
                                

                                if(c.geograph_name == null) {
                                    c.geoSelected = false;
                                }

                                if(c.channel_desc == null) {
                                    c.channelSeleted = false;
                                }

                            }
                        )
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    addSubmit : boolean;
    
    addCommunication(communication) {
        this.addSubmit = false;
        this.settingDataService.addCommunication(communication)
            .subscribe(
                (response: any) => {
                    this.toasterService.success('Data Added Successfully', "Success", {
                        timeOut: 1000,
                      });
                    this.addSubmit = true;
                    this.communicationData = response.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    excelLoader : boolean;
    whiteListPosm(posmExcel : PosmExcel){
        this.excelLoader = true;
        this.settingDataService.excelPOSMWhiteList(posmExcel)
            .subscribe(
                (response: any) => {
                    this.excelLoader = false;
                    this.toasterService.success('Outlet whitelisted Successfully', "Success", {
                        timeOut: 1000,
                      });
                    // this.getPosmConfigDataList();
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


}