import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteDataProvider } from '../RouterProvider/RouteDataProvider.provider';
import { StaffDataService } from 'src/app/model/staff-data.service';
import { AlertService } from '../RouterProvider/AlertProvider';
import { UserRoleService } from 'src/app/model/user-role.service';
import { UserRoleModel } from 'src/app/model/contract/userroles';

@Injectable({
  providedIn: 'root',
})
export class UserRoleProvider {

    userroles: UserRoleModel[];
    
  constructor( private routeDataProvider:RouteDataProvider, 
    private staffDataService: StaffDataService,
    private router: Router,
    public alertService: AlertService,
    private userRoleService: UserRoleService) {
    //super('LConnectt', routeDataProvider);
   }


   public getUserRoles() {
    this.userRoleService.getUserRoleList()
      .subscribe(
        (result: any) => {
          this.userroles = result.data;
          if(this.userroles.length > 0){
            this.userroles.forEach(
              u => {
                u.checked = false;
                u.itemName = u.role_name;
              }
            )
          }
        },
        error => {
            this.alertService.error(error);
        }
      );
  }



}