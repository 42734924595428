import { Injectable } from '@angular/core';
import { StaffDataService } from "../../model/staff-data.service";
import { Router } from '@angular/router';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { ContentLearningModel } from 'src/app/model/contract/content-learning';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ContentLearningProvider {
    courseDataList: ContentLearningModel[];
    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        public alertService: AlertService,
        private settingDataService: SettingDataService,
        public toasterService: ToastrService) {
    }

    getCourseList() {
        let program_id = 1;
        this.settingDataService.getCourses(program_id)
        .subscribe(
            (response: any) => {
                this.courseDataList = response.data;
            },
            error => {
                this.alertService.error(error);
            }
        )
    }

    addCourse(course: ContentLearningModel) {
        this.settingDataService.addCourse(course)
        .subscribe(
            (response: any) => {
                this.alertService.clear();
                this.toasterService.success('Course Added Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getCourseList();
            },
            error => {
                this.alertService.error(error);
            }
        )
    }

    updateCourse(course: ContentLearningModel) {
        this.settingDataService.updateCourse(course)
        .subscribe(
            (response: any) => {
                this.toasterService.success('Course Updated Successfully', "Success", {
                    timeOut: 1000,
                });
                this.getCourseList();
            },
            error => {
                this.alertService.error(error);
            }
        )
    }
}