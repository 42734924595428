import { Component, Input, OnInit } from '@angular/core';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { StateModel } from 'src/app/model/contract/menu-program';
import { ChannelModel, FrequencyModel, RegionModel } from 'src/app/model/contract/channel';
import { DROPDOWN_DETAILS, CHANNEL_TARGET, DISTRIBUTION_HEADERS, COLOR_TITLE, DISTRIBUTION_HEADERS1 } from '../Models/general-headings.model';
import { CommonHttpServiceService } from '../Services/common-http-service.service';
import { TileModel } from '../Models/tile_data.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
declare var $: any;
import { SettingDataService } from 'src/app/model/setting-data.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { RegionDataService } from 'src/app/model/region-data.service';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { FilterModel } from '../Models/filter-data';
declare var $:any;
@Component({
  selector: 'app-crown-collection',
  templateUrl: './crown-collection.component.html',
  styleUrls: ['./crown-collection.component.scss']
})
export class CrownCollectionComponent implements OnInit {
  details: any;
  colors: any[] = COLOR_TITLE;
  targetVsAchivement: any[] = CHANNEL_TARGET;
  distributionHeaders: any[] = DISTRIBUTION_HEADERS1;
  projectSettings = this.targetVsAchivement;
  distribution = this.distributionHeaders;

  //Data Variables;
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  dropdown: any[] = DROPDOWN_DETAILS;

  // Date Variable;
  start_date: any;
  end_date: any;

  // Dropdown Selected Values;
  selectedRegion: number;
  selectedState: number;
  selectedChannel: number;
  selectedM1: number;
  selectedM2: number;
  selectedM3: number;
  startDate: any;
  endDate: any;

  dateRange: any = [];
  data: any;
  regionTargets: any = [];
  totalPointsData: any = [];
  totalTargetAchievement: any = [];
  resetDropdown: boolean;
  region_id: number;

  constructor(
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    private httpClient: CommonHttpServiceService,
    public ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private  settingDataService: SettingDataService,
    private usersService : RegionDataService,
    public alertService: AlertService,
    public hubAdminDataProvider: HubAdminDataProvider,
  ) { }

  @Input() filterData: FilterModel;

  ngOnChanges() {
    console.log(this.filterData);
    this.data = [];
    this.remove();
    this.getCrownCollection(
      this.filterData.smonth,
      this.filterData.sdate,
      this.filterData.selectedNewRegion,
      this.filterData.state,
      this.filterData.channel,
      this.filterData.m1,
      this.filterData.m2,
      this.filterData.m3,
    );
  }

  ngOnInit() {
    this.region_id = parseInt(localStorage.getItem('region_id'))
    console.log(this.region_id)
    console.log(this.dropdown)
    this.getRegions();
    this.getCrownCollection(0, 0, this.region_id, 0, 0, 0, 0, 0);
  }


  regionsData: RegionModel[];
  statesData : StateModel[];
  selectedStates : StateModel[];
  selectedNewRegion: number = 0;


  getRegions(): void {
    
    this.settingDataService.getAllRegion()
        .subscribe(
            (result: any) => {
                this.regionsData = result.data.regions;
                this.getStateList()
                if(typeof this.regionsData !="undefined"){
                  if(this.regionsData.length > 0) {
                    this.regionsData = this.regionsData.filter(i => i.id == this.region_id);
                    this.selectedNewRegion = this.regionsData.find(i => i.id == this.region_id).id;
                  }
               }
                this.regionsData.forEach(ele => {
                    ele.itemName = ele.region_name;
                    ele.checked = false;
                    ele.name = ele.region_name
                });
            },
            error => {
                this.alertService.error(error);
            }
        )
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe(
        (data) => {

            this.states = data;
            this.dropdown.find(d => d.id == 2).data = this.states.filter(i => i.region_id == this.selectedNewRegion);
            this.states.forEach(
                s => {
                    s.name = s.state_name;
                    s.id = s.state_code

                }
            )
        }
    )
}
  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;

    // this.dropdown.find(d => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find(d => d.id == 3).data = this.channels;
    this.dropdown.find(d => d.id == 4).data = this.m1Users;
    this.dropdown.find(d => d.id == 5).data = this.m2Users;
    this.dropdown.find(d => d.id == 6).data = this.m3Users;
  }

  targetName: any[] = [
    'Target Quantity', 'Achievement Quantity', 'Achievement Percentage'
  ]


  getCrownCollection(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.remove();
    this.httpClient.getCrownCollection(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          this.data = result.data;
          this.data.region_targets.forEach((element, i) => {
            let data: TileModel = new TileModel;
            data.color = this.colors[i];
            data.name = element.name;
            data.value = element.value;
            this.regionTargets.push(data);
          });

          this.data.total_points.forEach((element, i) => {
            let data: TileModel = new TileModel;
            data.color = this.colors[i];
            data.name = element.name;
            data.value = element.value;
            this.totalPointsData.push(data);
          });
          this.data.total_target_achievement.forEach((element, i) => {
            let data: TileModel = new TileModel;
            data.color = this.colors[i];
            data.name = element.name;
            data.value = element.value;
            this.totalTargetAchievement.push(data);
          });

        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
            });
          }
        }
      )
    this.ngxLoader.stop();
  }


  remove() {
    this.totalPointsData = [];
    this.regionTargets = [];
    this.totalTargetAchievement = [];
  }

  selectedValueCheck(event, filter): void {
    switch (filter) {
      case 1:
        this.StatesfilterByRegion = this.states.filter(s => s.region_id == event);
        this.dropdown.find(d => d.id == 2).data = this.StatesfilterByRegion;
        this.selectedRegion = event;
        break;
      case 2:
        this.selectedState = event;
        break;
      case 3:
        this.selectedChannel = event;
        break;
      case 4:
        this.selectedM1 = event;
        break;
      case 5:
        this.selectedM2 = event;
        break;
      case 6:
        this.selectedM3 = event;
        break;

    }
    console.log("general:", event, filter);

  }

  searchByFilter() {
    this.resetDropdown = false;
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    }
    else {
      this.startDate = this.datepipe.transform(this.dateRange[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.dateRange[1], 'yyyy-MM-dd');
    }

    if (this.selectedNewRegion === undefined) {
      this.selectedNewRegion = 0;
    }

    if (this.selectedState === undefined) {
      this.selectedState = 0;
    }

    if (this.selectedChannel === undefined) {
      this.selectedChannel = 0;
    }

    if (this.selectedM1 === undefined) {
      this.selectedM1 = 0;
    }

    if (this.selectedM2 === undefined) {
      this.selectedM2 = 0;
    }

    if (this.selectedM3 === undefined) {
      this.selectedM3 = 0;
    }

    this.getCrownCollection(this.startDate, this.endDate, this.selectedNewRegion, this.selectedState, this.selectedChannel, this.selectedM1, this.selectedM2, this.selectedM3);
  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    this.getCrownCollection(0, 0, this.region_id, 0, 0, 0, 0, 0);
  }

  public convetToPDF() {
    this.ngxLoader.startBackground();
    var data = document.getElementById('Program-Overview');
    $(data).prepend("<p id='gr' style='font-size:40px;margin:40px 0px 60px 0px;'>Crown Collection</p> ");
    html2canvas(data).then(canvas => {
    var imgWidth = 208;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf =  new jsPDF.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    heightLeft -= pageHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }
      pdf.save('content_report.pdf');
      this.ngxLoader.stopBackground();
    }).then(()=>{
      $('#gr').remove();
    });
 }
}
