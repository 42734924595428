import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { element } from 'protractor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MenuTransactionModel } from 'src/app/model/contract/menu-program';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/model/global.service';
import { RegionDataService } from 'src/app/model/region-data.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { AUDIT_DETAILS, COLOR_TITLE } from '../Models/general-headings.model';


@Component({
    selector: 'app-mysteryshopper',
    templateUrl: './entry.component.html',
    styleUrls: ['./mysteryshopper.component.scss']
})

export class EntryComponent implements OnInit {

    requestTransactions: MenuTransactionModel[];
    siteUrl: any;
    colors: any[] = COLOR_TITLE;
    constructor(
        public routerDataProviders: RouteDataProvider,
        private router: Router,
        public datepipe: DatePipe,
        private modalService: BsModalService,
        private _location: Location,
        private activatedRoute: ActivatedRoute,
        public usersService: RegionDataService,
        public globalService: GlobalService,
        public ngxLoader: NgxUiLoaderService,
        private users: UserDataService) {
        this.siteUrl = this.globalService.apiHost;
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(
            params => {
                if (typeof params['id'] != 'undefined') {
                    this.getReport(params['id'])
                }
            }
        );
    }

    audit_info: TransactionModel[];
    percentage_data: any[];
    transaction_data: MysteryTransactionModel[];

    outletDetailsHeading : any[] = [
        "Outlet Name", 
        "Outlet Code",
        "Channel",
        "Total Score"
    ];

    
    auditHeaders: any[] = AUDIT_DETAILS;

    auditDetails = [
        {
            "parameter": "Visibility",
            "questions": "Was the first row of the chiller exclusively Corona",
            "original_response": "No",
            "score_payout": "0",
            "link": "http://cooler.jpg",
            "action": "No",
            "upload": ""
        }
    ];

    auditData = [
        {
            name: "Total Payout Available (INR)",
            data: "10000"
        },
        {
            name: "Total Actual Payout (INR)",
            data: "8500"
        },
        {
            name: "Total Execution Payout Available (INR)",
            data: "10000"
        },
        {
            name: "Total Actual Execution Payout (INR)",
            data: "1500"
        },
        {
            name: "Total Recommendation Entries",
            data: "-"
        },
        {
            name: "Total Recommendation Entries Marked Successful",
            data: "-"
        },
        {
            name: "Total Recommendation Payout Available (INR)",
            data: "-"
        },
        {
            name: "Total Actual Recommendation Payout (INR)",
            data: "1500"
        },
        {
            name: "Total Volume Target",
            data: "-"
        },
        {
            name: "Total Volume Achievement",
            data: "-"
        },
        {
            name: "Total Volume Payout Available (INR)",
            data: "-"
        },
        {
            name: "Total Actual Volume Payout (INR)",
            data: "5500"
        }
    ]

    getReport(outlet_user_id) {
        this.ngxLoader.start();
        this.users.getMysteryReportByOutlet(outlet_user_id)
            .subscribe(
                (result: any) => {
                    this.ngxLoader.stop();
                    this.audit_info = result.data.audit_info;
                    this.percentage_data = result.data.percentage_data;
                    this.transaction_data = result.data.transaction_data;
                }
            )
    }

    backClicked() {
        console.log('here');
        this._location.back();
    }
}

export class TransactionModel {
    assignment_id: number;
    outlet_user_id: number;
    audit_name: string;
    completed_on: number;
    total_points: number;
    earned_points: number;
    record_gap: number;
    tse_mobile: number;
    ro_code: string;
    tse_name: string;
    ro_name: string;
    channel: string;
    m2_mobile: string;
    m2_name: string;
    m3_mobile: number;
    m3_name: string;
}

export class MysteryTransactionModel {
    template_id: number;
    template_name: string;
    group_name: string;
    group_total_reward: string;
    group_earned_reward: string;
    mystery_questions: QuestionAnswerModel[];
    question_name: string;
    points_text: string;
}

export class QuestionAnswerModel {
    mystery_question_id: number;
    question: number;
    question_type_string: string;
    question_type: string;
    sub_question: string;
    no_of_options: string;
    sub_question_id: string;
    mystery_question_options: AnswerModel[];
}

export class AnswerModel {
    id: number;
    question_id: number;
    option_text: string;
    selected_answer: string;
    points_text: string;
    point_color: number;
}


export class GroupQuestionData {
    group_name : string;
    question : string;
    question_score : string;

}