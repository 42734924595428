import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry } from 'rxjs/operators';
import { GlobalService } from 'src/app/model/global.service';
import { StaffService } from 'src/app/model/staff.service';
import { User } from 'src/app/model/contract/user';
import { of } from 'rxjs';
import { GENERAL_REPORT } from '../Models/general.mock-data';
import { WEEKLY_CHALLENGE } from '../Models/weekly-challenge.mock-data';
import { crown_collections } from '../Models/crown-collections.mock-data';
import { CONTENT_LEARNING } from '../Models/content-learning.mock-data';
import { KING_SERVICE } from '../Models/king-service.mock-data';
import { VisiblityCooler } from '../Models/visibility-cooler.mock-data';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpServiceService {
  program_id: string;

  constructor(private globalService: GlobalService,
    private staffService: StaffService,
    private http: HttpClient) {
      this.program_id = localStorage.getItem('program_id');
     }
    //  http header
    private getHeaders(): HttpHeaders {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.staffService.getToken()
      });
    }
    // point to hub-admin path
    private hubAdmin() {
      return this.globalService.apiHost + '/hub-admin';
    }
    // point to admin path
    private admin() {
      return this.globalService.apiHost + '/adminreport';
    }

        // point to admin path
        private qc() {
          return this.globalService.apiHost + '/admin';
        }
    
        private qcauditsubmit() {
          return this.globalService.apiHost + '/admin/qcauditsubmit';
        }
    
    
        private qcfinalaudit_submit() {
          return this.globalService.apiHost + '/admin/qcfinalauditsubmit';
        }
    


    getDetails(formatedUrl): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + formatedUrl;
      return this.http.get(apiUrl, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return <any[]>response;
          }
        )
    }



    getQcData(formatedUrl): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.qc() + formatedUrl;
      return this.http.get(apiUrl, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return <any[]>response;
          }
        )
    }


    qcauditsubmitdata(assignment_id, question_data,submit_status,remarks):Observable<any>{

      let headers = this.getHeaders();
      let apiUrl = this.qcauditsubmit() ;
      let questiondata=JSON.stringify(question_data)
      let body    = JSON.stringify({"assignment_id":assignment_id, "question_data":questiondata,"submit_status":submit_status, "remarks":remarks});
      return this.http.post(apiUrl, body, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return response;
          }
        )
    }
  
    qcfinalauditsubmit(assignment_id, question_data,submit_status,remarks,user_id):Observable<any>{

      let headers = this.getHeaders();
      let apiUrl = this.qcfinalaudit_submit() ;
      let questiondata=JSON.stringify(question_data)
      let body    = JSON.stringify({"assignment_id":assignment_id, "question_data":questiondata,"submit_status":submit_status, "internal_remarks":remarks,"user_id":user_id});
      return this.http.post(apiUrl, body, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return response;
          }
        )
    }
  

    public getMockData(filename: string): Observable<any> {
      if(filename === "crown"){
        return of(crown_collections)
      }
      if(filename === 'cooler'){
        return of(VisiblityCooler)
      }
    }


    getVisibilityCooler(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,month,year): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + '/visibilitycooler?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id+ "&month=" + month+ "&year=" + year;
      return this.http.get(apiUrl, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return <any[]>response;
          }
        )
    }

    getCrownCollection(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + '/crowncollection?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
      return this.http.get(apiUrl, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return <any[]>response;
          }
        )
    }

    getContentAndLearning(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,month,year): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + '/contentandlearning?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id+ "&month=" + month+ "&year=" + year;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
    }

    geKingService(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,brand_id,whitelist_date,month,year): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + '/kingservice?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id + "&brand_id="+brand_id+ "&whitelist_date="+whitelist_date+ "&month=" + month+ "&year=" + year;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
    }

    getWeeklyChallenge(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,month,year): Observable<any[]> {
      let headers = this.getHeaders();
      let apiUrl = this.admin() + '/weeklychallenge?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id+ "&month=" + month+ "&year=" + year;
      return this.http.get(apiUrl, { headers: headers })
        .pipe(
          catchError(this.handleError)
        )
        .map(
          response => {
            return <any[]>response;
          }
        )
    }

    

    
    
  
    // Handling Errors;
    private handleError(response: any) {
      let errorMessage: any = {};
      // Connection error
      if (response.error.status === 0) {
        errorMessage = {
          success: false,
          status: 0,
          data: 'Sorry, there was a connection error occurred. Please try again.'
        };
      } else {
        errorMessage = response.error;
      }
      return throwError(errorMessage);
    }
}
