import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonHttpServiceService } from "src/app/admin/Services/common-http-service.service";
import {
  ChannelModel,
  FrequencyModel,
  RegionModel,
} from "src/app/model/contract/channel";
import { StateModel } from "src/app/model/contract/menu-program";
import { RegionDataService } from "src/app/model/region-data.service";
import { SettingDataService } from "src/app/model/setting-data.service";
import { GeneralService } from "src/app/report-dashboard/Services/general.service";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { DROPDOWN_DETAILS } from "../Models/general-headings.model";

@Component({
  selector: "app-shared-search",
  templateUrl: "./shared-search.component.html",
  styleUrls: ["./shared-search.component.scss"],
})
export class SharedSearchComponent implements OnInit {
  selectedNewRegion: number = 0;
  dateRange: any = [];
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  dropdown: any[] = DROPDOWN_DETAILS;
  regionsData: RegionModel[];
  statesData: StateModel[];
  selectedStates: StateModel[];
  min: number;
  max: number;
  smonth: any = '0';
  sdate: any = '0';
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  region_id: any;
  program_id: string;
  options: Array<any> = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private settingDataService: SettingDataService,
    private usersService: RegionDataService
  ) {}

  ngOnInit() {
    this.program_id = localStorage.getItem('program_id');
    this.region_id = parseInt(localStorage.getItem('region_id'))
    console.log(this.region_id)
    console.log(this.dropdown)
    this.getRegions();
    this.min = 2019,
      this.max = new Date().getFullYear();
    let options = '<option>Select Year</option>'
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + '</option>'
      this.options.push(i);

    }
    this.options.reverse();
  }

  getRegions(): void {
    this.settingDataService.getAllRegion().subscribe(
      (result: any) => {
        this.regionsData = result.data.regions;
        this.getStateList();
        if (typeof this.regionsData != "undefined") {
          if (this.regionsData.length > 0) {
            this.regionsData = this.regionsData.filter(
              (i) => i.id == this.region_id
            );
            this.selectedNewRegion = this.regionsData.find(
              (i) => i.id == this.region_id
            ).id;
          }
        }
        this.regionsData.forEach((ele) => {
          ele.itemName = ele.region_name;
          ele.checked = false;
          ele.name = ele.region_name;
        });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe((data) => {
      this.states = data;
      this.dropdown.find((d) => d.id == 2).data = this.states.filter(
        (i) => i.region_id == this.selectedNewRegion
      );
      this.states.forEach((s) => {
        s.name = s.state_name;
        s.id = s.state_code;
      });
    });
  }

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    this.dropdown.find((d) => d.id == 3).data = this.channels;
    this.dropdown.find((d) => d.id == 4).data = this.m1Users;
    this.dropdown.find((d) => d.id == 5).data = this.m2Users;
    this.dropdown.find((d) => d.id == 6).data = this.m3Users;
  }


}
