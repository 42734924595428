export class FilterModel {
  startDate : any;
  endDate : any;
  selectedNewRegion : any;
  state : any;
  channel : any;
  m1 : any;
  m2 : any;
  m3 : any;
  whitelist_date1 : any;
  smonth : any;
  sdate : any;
  region:any;
}
