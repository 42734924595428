import { Component, Input, OnInit, ɵConsole } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { DatePipe } from "@angular/common";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { StateModel } from "src/app/model/contract/menu-program";
import {
  ChannelModel,
  FrequencyModel,
  RegionModel,
  ChartNewModel,
} from "src/app/model/contract/channel";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { GeneralService } from "../Services/general.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import {
  DROPDOWN_DETAILS,
  GENERAL_AVERAGE,
  GENERAL_REWARD,
  GENERAL_PROMO1,
  COLOR_TITLE,
  REWART_TAT_TOP,
  GENERAL_OPEN_RATE,
  DROPDOWN_DETAILS_MYSTERY,
} from "../Models/general-headings.model";
import { CommonHttpServiceService } from "../Services/common-http-service.service";
import { TileModel, WhitleTile } from "../Models/tile_data.model";
import { IChartistData } from "chartist";
import { type } from "os";
import { ToastrService } from "ngx-toastr";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { UserDataService } from "src/app/model/user-data.service";
import { FilterModel } from "src/app/nesa-admin/Models/filter-data";
declare var $: any;

@Component({
  selector: "app-mysteryshopper-report",
  templateUrl: "./mysteryshopper-report.component.html",
  styleUrls: ["./mysteryshopper-report.component.scss"],
})
export class MysteryshopperReportComponent implements OnInit {
  //Data Variables;
  dateRange: any = [];
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  whitelist_date: any = 0;
  maxDate: Date;
  dropdown: any[] = DROPDOWN_DETAILS_MYSTERY;
  avgTable: any[] = GENERAL_AVERAGE;
  avgOpenRateSettings: any[] = GENERAL_OPEN_RATE;
  avgReward: any[] = GENERAL_REWARD;
  avgPromo: any[] = GENERAL_PROMO1;
  rewardTAT: any[] = REWART_TAT_TOP;
  colors: any[] = COLOR_TITLE;
  options: Array<any> = [];

  projectSettings = this.avgTable;
  generealRewards = this.avgReward;
  generalPromo = this.avgPromo;
  rewardTatTop5 = this.rewardTAT;

  // Date Variable;
  start_date: any;
  end_date: any;

  // Bar Chart
  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];
  public TSEDailybarChartLabels5: any[] = [];
  public TSEDialybarChartData5: any[];

  // Dropdown Selected Values;
  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedM1: number[] = [];
  selectedM2: number[] = [];
  selectedM3: number[] = [];
  selectedRound: any;
  startDate: any;
  endDate: any;
  resetDropdown: boolean;
  whitelist_date1: any;
  min: number;
  max: number;
  smonth: any = "0";
  sdate: any = "0";
  region = "";
  round = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  event = [];
  roundData = [
    { id: 1, name: "Round 1" },
    { id: 2, name: "Round 2" },
    { id: 3, name: "Round 3" },
    { id: 4, name: "Round 4" },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private users: UserDataService
  ) {
    this.maxDate = new Date();
    // this.dropdown.find(d => d.id == 7).data = this.roundData;
    this.selectedRound = 1;
  }

  @Input() filterData: FilterModel;

  ngOnChanges() {
    console.log(this.filterData);
    this.removeData();
    this.data = [];
    if (typeof this.filterData != "undefined") {
      this.getGeneralReport(
        this.filterData.region,
        this.filterData.state,
        this.filterData.channel,
        this.filterData.m1,
        this.filterData.m2,
        this.filterData.m3,
        0
      );
    }
  }

  ngOnInit() {
    console.log(this.maxDate);
    this.getGeneralReport(0, 0, 0, 0, 0, 0, 0);
    (this.min = 2019), (this.max = new Date().getFullYear());
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    this.options.reverse();
  }

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;

    this.dropdown.find((d) => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find((d) => d.id == 3).data = this.channels;
    // this.dropdown.find(d => d.id == 4).data = this.m1Users;
    // this.dropdown.find(d => d.id == 5).data = this.m2Users;
    // this.dropdown.find(d => d.id == 6).data = this.m3Users;
    if (this.event == undefined || this.event.length < 1) {
      this.dropdown.find((d) => d.id == 4).data = this.m1Users;
      this.dropdown.find((d) => d.id == 5).data = this.m2Users;
      this.dropdown.find((d) => d.id == 6).data = this.m3Users;
    }
  }

  // Service Call

  data: any;
  chart: ChartNewModel = new ChartNewModel();
  chart1: ChartNewModel = new ChartNewModel();
  TSE_data: TileModel[] = [];
  CSM_data: TileModel[] = [];
  WAITER_data: TileModel[] = [];
  Weekly_data: TileModel[] = [];
  Content_data: TileModel[] = [];
  MENU_data: TileModel[] = [];
  CROWN_data: TileModel[] = [];
  COLLER_data: TileModel[] = [];
  BRANDING_data: TileModel[] = [];
  MYSTERYSHOPPER_data: WhitleTile[] = [];
  MYSTERYSHOPPER_summary: WhitleTile[] = [];
  MYSTERYSHOPPER_TAREGT_data: WhitleTile[] = [];
  MYSTERYSHOPPER_GAP_analysis: WhitleTile[] = [];
  KINGSERVICE_data: WhitleTile[] = [];
  Cumulative_data: WhitleTile[] = [];
  Cumulative_pointsData: WhitleTile[] = [];
  avgTimeSpentChart: IChartistData;
  avgOpenRateChart: IChartistData;

  avgTimeSpent: any = {
    week: new ChartNewModel(),
    month: new ChartNewModel(),
  };
  avgOpenRate: any = {
    week: new ChartNewModel(),
    month: new ChartNewModel(),
  };

  acheviementHeading: any[] = [
    "Volume Achievement",
    "% of POCs achieving target",
  ];

  WeeklyChallengeHeading: any[] = ["Available", "Attempted", "Successful"];

  contentLearningHeading: any[] = ["Available", "Attempted", "Successful"];

  spinner: boolean = true;

  getGeneralReport(
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id,
    round
  ) {
    this.removeData();
    let calendar_year = parseInt(localStorage.getItem("calendar_year"));
    this.ngxLoader.start();
    this.users
      .getMysteryReport(
        region,
        state,
        channel,
        tse_user_id,
        m2_user_id,
        m3_user_id,
        round,
        this.smonth,
        this.sdate,
        calendar_year
      )
      .subscribe(
        (result: any) => {
          this.data = result.data;
          let [i, j, k, l, m, n, o, p, q, r, s] = [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ];
          //White Tile
          this.data.mystery_tile.forEach((element) => {
            let data: WhitleTile = new WhitleTile();
            data.data = element.value;
            data.name = element.name;
            data.data =
              typeof element.value != "undefined" && element.value != null
                ? element.value
                : "0";
            data.name = element.name.replaceAll("POC", "CSM");
            data.name = element.name.replaceAll("Outlet", "CSM");
            data.name = element.name.replaceAll("User", "CSM");
            data.name = element.name.replaceAll("poc", "CSM");
            data.name = element.name.replaceAll("outlets", "CSM");
            data.name = element.name.replaceAll("outlet", "CSM");
            data.name = element.name.replaceAll("user", "CSM");
            data.name.includes("%") ? (data.type = "%") : "";
            data.name.includes("INR") ? (data.data = "₹ " + data.data) : "";
            this.MYSTERYSHOPPER_data.push(data);
          });

          if (this.data.performance_trakcing.length > 0) {
            this.data.performance_trakcing.forEach((element) => {
              let data: TileModel = new TileModel();
              data.color = this.colors[i];
              data.name = element.range;
              data.name = element.range.replace("outlets", "Audits");
              data.value = element.outletcounts;
              this.TSE_data.push(data);
              i++;
            });
          }

          this.data.mystery_summary_tile.forEach((element) => {
            let data: WhitleTile = new WhitleTile();
            data.data = element.value;
            data.data =
              typeof element.value != "undefined" && element.value != null
                ? element.value
                : "0";
            data.name = element.name;
            data.name = element.name.replaceAll("POC", "CSM");
            data.name = element.name.replaceAll("Outlet", "CSM");
            data.name = element.name.replaceAll("User", "CSM");
            data.name = element.name.replaceAll("poc", "CSM");
            data.name = element.name.replaceAll("outlets", "CSM");
            data.name = element.name.replaceAll("outlet", "CSM");
            data.name = element.name.replaceAll("user", "CSM");
            data.name.includes("%") ? (data.type = "%") : "";
            data.name.includes("INR") ? (data.data = "₹ " + data.data) : "";
            this.MYSTERYSHOPPER_summary.push(data);
          });

          this.data.gap_analysis.forEach((element) => {
            let data: WhitleTile = new WhitleTile();
            data.data = element.value;
            data.data =
              typeof element.value != "undefined" && element.value != null
                ? element.value
                : "0";
            data.name = element.name;
            data.name = element.name.replaceAll("POC", "CSM");
            data.name = element.name.replaceAll("Outlet", "CSM");
            data.name = element.name.replaceAll("User", "CSM");
            data.name = element.name.replaceAll("poc", "CSM");
            data.name = element.name.replaceAll("outlets", "CSM");
            data.name = element.name.replaceAll("outlet", "CSM");
            data.name = element.name.replaceAll("user", "CSM");
            data.name.includes("%") ? (data.type = "%") : "";
            data.name.includes("INR") ? (data.data = "₹ " + data.data) : "";
            this.MYSTERYSHOPPER_GAP_analysis.push(data);
          });

          if (this.data.visit_tracking) {
            let data: TileModel = new TileModel();
            data.color = this.colors[0];
            data.name = `Visit 1`;
            data.value = this.data.visit_tracking.visit1;
            this.CROWN_data.push(data);
            let data1: TileModel = new TileModel();
            data1.color = this.colors[1];
            data1.name = `Visit 2`;
            data1.value = this.data.visit_tracking.visit2;
            this.CROWN_data.push(data1);
            let data2: TileModel = new TileModel();
            data2.color = this.colors[2];
            data2.name = `Visit 3`;
            data2.value = this.data.visit_tracking.visit3;
            this.CROWN_data.push(data2);
            let data3: TileModel = new TileModel();
            data3.color = this.colors[3];
            data3.name = `Visit 4`;
            data3.value = this.data.visit_tracking.visit4;
            this.CROWN_data.push(data3);
            let data4: TileModel = new TileModel();
            data4.color = this.colors[4];
            data4.name = `Visit 5`;
            data4.value = this.data.visit_tracking.visit5;
            this.CROWN_data.push(data4);
          }
          this.spinner = false;
        },
        (error) => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
            this.spinner = false;
            this.toastr.error(`Internal Server Error`, "Dashboard", {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
  }

  // formatting Average Time Spent & Average open Rate charts.
  chartFormatting(data) {
    if (Object.getOwnPropertyNames(this.data.average_time_spent).length !== 0) {
      let Timeseries: any[];
      Timeseries = Object.values(this.data.average_time_spent).map(
        (e) => e["open_rate"]
      );
      console.log(Timeseries);
      this.avgTimeSpentChart = {
        labels: Object.values(this.data.average_time_spent).map(
          (e) => e["name"]
        ),
        series: [Timeseries],
      };
    }
    if (Object.getOwnPropertyNames(this.data.average_open_rate).length !== 0) {
      let spentSeries: any, spentSeries2: any;
      spentSeries = Object.values(this.data.average_open_rate).map(
        (e) => e["open_rate"]
      );
      this.avgOpenRateChart = {
        labels: Object.values(this.data.average_open_rate).map(
          (e) => e["name"]
        ),
        series: [spentSeries],
      };
      console.log(this.avgOpenRateChart);

      // this.avgOpenRate.month.series.push(spentSeries);
      // this.avgOpenRate.week.series.push(spentSeries2);
      // this.avgOpenRateChart = this.avgOpenRate.week;
      //console.log("this.avgOpenRateChart",  this.avgOpenRateChart)
    }
  }
  // Based on user selection pass the chart data to chart component
  chartChange(type, section) {
    section === "time"
      ? type === "Week"
        ? (this.avgTimeSpentChart = this.avgTimeSpent.week)
        : (this.avgTimeSpentChart = this.avgTimeSpent.month)
      : type === "Week"
      ? (this.avgOpenRateChart = this.avgOpenRate.week)
      : (this.avgOpenRateChart = this.avgOpenRate.month);
  }

  // Call Chart data and change button style
  tabButtonGroup($event, section) {
    let clickedElement = $event.target || $event.srcElement;
    if (clickedElement.nodeName === "BUTTON") {
      this.chartChange(clickedElement.textContent, section);
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(
        ".active"
      );
      // if a Button already has Class: .active

      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
        isCertainButtonAlreadyActive.classList.remove("g-activeTab");
      }
      clickedElement.className += " active";
      clickedElement.className += " g-activeTab";
    }
  }

  m1FilterByRegion = [];
  m2FilterByRegion = [];
  m3FilterByRegion = [];

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    this.event = event;
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        if (event != 0) {
          event.forEach((element) => {
            let data = this.states.filter((s) => s.region_id == element.id);
            let m1Data = this.m1Users.filter((s) => s.region_id == element.id);
            let m2Data = this.m2Users.filter((s) => s.region_id == element.id);
            let m3Data = this.m3Users.filter((s) => s.region_id == element.id);
            this.m1FilterByRegion.push(...m1Data);
            this.m2FilterByRegion.push(...m2Data);
            this.m3FilterByRegion.push(...m3Data);
            this.StatesfilterByRegion.push(...data);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        break;
      case 2:
        this.state = this.getList(event, "selectedState");

        break;
      case 3:
        this.channel = this.getList(event, "selectedChannel");

        break;
      case 4:
        this.m1 = this.getList(event, "selectedM1");

        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event, "selectedM2");
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 = this.getList(event, "selectedM3");
        break;
    }
    // this.searchByFilter();
  }

  getList(event, mainData) {
    this[mainData] = event;

    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
    } else {
      g = "";
    }

    return g;
  }

  searchByFilter() {
    // let selectedRegion = this.selectedRegion.slice()
    this.resetDropdown = false;

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (
      this.selectedChannel === undefined ||
      this.selectedChannel.length == 0
    ) {
      this.channel = "";
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = "";
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = "";
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = "";
    }

    this.getGeneralReport(
      this.region,
      this.state,
      this.channel,
      this.m1,
      this.m2,
      this.m3,
      this.selectedRound
    );
  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }

  removeData() {
    this.TSE_data = [];
    this.CSM_data = [];
    this.WAITER_data = [];
    this.Weekly_data = [];
    this.Content_data = [];
    this.MENU_data = [];
    this.CROWN_data = [];
    this.COLLER_data = [];
    this.BRANDING_data = [];
    this.MYSTERYSHOPPER_data = [];
    this.Cumulative_data = [];
    this.KINGSERVICE_data = [];
    this.MYSTERYSHOPPER_summary = [];
    this.MYSTERYSHOPPER_GAP_analysis = [];
  }

  public convetToPDF() {
    this.ngxLoader.startBackground();
    var myWindow = window.open("", "_blank");
    // Opens a new window
    var data = document.getElementById("Program-Overview");
    var data5 = document.getElementById("at");
    var data6 = document.getElementById("au");
    var data1 = $("#avg_timespend");
    var data2 = $("#avg_usecount");
    var data1_parent = $("#avg_timespend").parent();
    var data2_parent = $("#avg_usecount").parent();
    $("#avg_timespend").remove();
    $("#avg_usecount").remove();
    this.ngxLoader.startBackground();
    $(data).prepend(
      "<p id='gr' style='font-size:40px;margin:40px 0px 60px 0px;'>General Report</p> "
    );
    data5.style.margin = "250px 0px 150px 0px";
    data6.style.margin = "10px 0px 146px 0px";
    myWindow.close();
    html2canvas(data)
      .then((canvas) => {
        // Few necessary setting options
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jsPDF.jsPDF("p", "mm", "a4"); // A4 size page of PDF
        var position = 0;
        heightLeft -= pageHeight;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("general_report.pdf");
        this.ngxLoader.stopBackground();
      })
      .then(() => {
        data5.style.margin = "10px 0px 10px 0px";
        data6.style.margin = "10px 0px 0px 0px";

        $("#gr").remove();
        data1_parent.append(data1);
        data2_parent.append(data2);
      });
  }
}
