import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-white-tile',
  templateUrl: './white-tile.component.html',
  styleUrls: ['./white-tile.component.scss']
})
export class WhiteTileComponent implements OnInit {

  @Input() data : any;
  @Input() tabs : any;
  breakupdata : any[] = [];

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ngOnInit() {
    console.log(this.data);
    console.log(this.tabs)
    if(this.tabs == 1) {
      this.breakupdata = [];
      this.data.breakupdata.forEach(element => {
        element.name = element.name.replace("Visibility Cooler", "Cooler");
        element.name = element.name.replace("Visibility Branding", "Branding");
        this.breakupdata.push(element);
      });
    }
  }


  hide(){
    this.modalRef.hide();
  }


}
