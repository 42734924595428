export const crown_collections: any = {
    "region_targets": [
        {
            "name": "East",
            "value": "60"
        },
        {
            "name": "West",
            "value": "50"
        },
        {
            "name": "North",
            "value": "10"
        },
        {
            "name": "South",
            "value": "150"
        },
        {
            "name": "NESA",
            "value": "60"
        }
    ],"total_points": [
        {
            "name": "Total Points Available",
            "value": "1000"
        },
        {
            "name": "Total Points Earned",
            "value": "200"
        }
    ],"audit_data": [
        {
            "name": "Total number of outets whitelisted",
            "data": "1000"
        },
        {
            "name": "Number of outlets enrolled by M1 - Set Target",
            "data": "100%"
        },
        {
            "name": "Total number of Watiers enrolled",
            "data": "100%"
        },
        {
            "name": "No. of outlets - Closure of Achievement reporting",
            "data": "100%"
        },
        {
            "name": "Volume Achievement",
            "data": "100%"
        },
        {
            "name": "% of POCs Achieveing target",
            "data": "100%"
        }
    ],"total_target_achievement": [
        {
            "name": "Total target",
            "value": "60"
        },
        {
            "name": "Total Achivement",
            "value": "50"
        },
        {
            "name": "Total Percentage",
            "value": "100%"
        }
    ],
    "cumulative_channel": [
        {
            "name": "Bud4Trade app",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Kingservice",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Content & Learning",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Weekly Challenges",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Coolers & Branding",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Reward Store",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        }
    ],
    "target_achievement_distribution": [
        {
            "bracket": "Bud4Trade app",
            "users": 200,
        },
        {
            "bracket": "Kingservice",
            "users": 200,
        },
        {
            "bracket": "Content & Learning",
            "users": 200,
        },
        {
            "bracket": "Weekly Challenges",
            "users": 200,
        },
        {
            "bracket": "Coolers & Branding",
            "users": 200,
        },
        {
            "bracket": "Reward Store",
            "users": 200,
        }
    ],
    "categorywise_target_achievement": [
        {
            "name": "Bud4Trade app",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Kingservice",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Content & Learning",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Weekly Challenges",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Coolers & Branding",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        },
        {
            "name": "Reward Store",
            "target": 12,
            "achievement":10,
            "percentage":"100%"
        }
    ],
    
}