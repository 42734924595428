export class ChannelModel {
    id: number;
    channel_code: string;
    channel_desc: string;
    channel_type: number;
    parent_channel_id: number;
    status: number;
    created_date: string;
    updated_date: string;
    hierarchy_level: number;
    channel_classification_id: number;
    name : string;
}

export class FrequencyModel {
    id: number;
    program_id: number;
    campaign_id: number;
    frequency_name: string;
    created_date: string;
    status: number;
}


export class OutletModel {
    id: number;
    outlet_id: number;
    outlet_name: string;
    outlet_code: number;
    channel_id: number;
    geographical_id: number;
    itemName: string;
    checked: boolean;
    region_id : number;
    assigned_status : number;
}

export class BrandCategoryModel {
    id: number;
    brand_category_name: string;
    short_name: string;
    status: number;
    created_date: string;
    updated_date: string;
    name : string;
}

export class UnitModel {
    id: number;
    unit_name: string;
    unit_desc: string;
    status: number;
}

export class TargetCategoryModel {
    category_id: number;
    target: number;
    unit_id: number;
    unit: string;
    category: string;
}

export class CategoryConfigModel {
    category_id: number;
    qty: number;
    unit_id: number;
    operator: string;
    points: number;
    category: string;
    unit: string;
    id: number;
    state: number;
    channel_id: number;
    geography: any;
}


export class ModulesModel {
    id: number;
    program_id: number;
    user_role_id: number;
    module_name: string;
    module_icon: string;
    module_indicate: string;
    created_date: string;
    updated_date: string;
    status: number;
    module_desc: string;
}

export class MessageTrigger {
    id: number;
    trigger_name: string;
    module_id: number;
    module_transaction_id: number;
    status: number;
    created_date: string;
    updated_date: string;
    module_name : string;
    trigger_for : string;
    moduleTransactionName : string;
}

export class CommunicationModel {
    id: number;
    trigger_id: number;
    start_date: string;
    end_date: string;
    frequency: number;
    type: number;
    sms_content: string;
    mail_content: string;
    notification_content: string;
    is_trigger: number;
    user_role_id: number;
    geograph_id: number;
    channel_id: number;
    schedule_notification_config_id: number;
    format: any;
    image_url: any;
    notification_time: string;
    module_id: string;
    content: string;
    file : any;
    file_name : any;

}

export class communicationData {
    id : number;
    trigger_name: string;
    format: string;
    content: string;
    communication_type: string;
    module_name: string;
    channel_desc: string;
    geograph_name: string;
    frequency_name: string;
    start_date: string;
    end_date: string;
    image_url: string;
    format_name : string;
    showImageUrl : boolean;
    geoSelected : boolean;
    channelSeleted : boolean;
}

export class RegionModel {
    id: number;
    region_name: string;
    createdon: string;
    itemName: string;
    checked: boolean;
    name : string;
}

export class ReportModel {
    id: number
    is_batch: number
    is_frequency: number
    name:string
    itemName: string;
    checked: boolean;
}


export class WhiteListModuleOutletModel {
    id  : number;
    user_id : number;
    module_id : number;
    email : string;
    state_code : string;
    state_name : string;
    outlet_code : string;
    outlet_name : string;
    user_name : string;
    outlet_owner_name : string;
    role_name : string;
    role_id : number;
    module_month : number;
    module_year : string;
}


export class ChartModel {
    data: any[];
    label : string
}

export class ChartNewModel {
    series: any[] = [];
    label : any[]
}

export class UserModel {
    user_id : number;
    username : string;
    ro_name : string;
    user_detail_name : string;
    name : string;
    id :number;
}

export class ReportDataModel {
  brand_end_date: string;
  brand_start_date: string;
  cooler_end_date: string;
  cooler_start_date: string;
  eng_end_date: string;
  eng_start_date: string;
  region_name: string;
  report_data: string;
  state_code: string;
  state_name: string;
  report_name : string;
}
