import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  @Input() show: boolean;

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.show == true) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
}
