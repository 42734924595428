import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TabDirective, TabsetComponent } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonHttpServiceService } from "src/app/admin/Services/common-http-service.service";
import {
  ChannelModel,
  FrequencyModel,
  RegionModel,
} from "src/app/model/contract/channel";
import { StateModel } from "src/app/model/contract/menu-program";
import { RegionDataService } from "src/app/model/region-data.service";
import { SettingDataService } from "src/app/model/setting-data.service";
import { FilterModel } from "src/app/nesa-admin/Models/filter-data";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import {
  DROPDOWN_DETAILS,
  DROPDOWN_DETAILS11,
} from "../Models/general-headings.model";
import { GeneralService } from "../Services/general.service";
import { NgxSpinnerService } from "ngx-spinner";
var $;
@Component({
  selector: "app-reporting-dashboard",
  templateUrl: "./reporting-dashboard.component.html",
  styleUrls: ["./reporting-dashboard.component.scss"],
})
export class ReportingDashboardComponent implements OnInit {
  @ViewChild(TabsetComponent, { static: false }) tabset: TabsetComponent;
  tabs: any[] = [
    { title: "Dynamic Title 1", content: "Dynamic content 1" },
    { title: "Dynamic Title 2", content: "Dynamic content 2" },
    { title: "Dynamic Title 3", content: "Dynamic content 3" },
  ];

  @Output() selectedTab: any;
  selectedNewRegion: number = 0;
  dateRange: any = [];
  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  dropdown: any[] = DROPDOWN_DETAILS11;
  regionsData: RegionModel[];
  statesData: StateModel[];
  selectedStates: StateModel[];
  min: number;
  max: number;
  smonth: any = "0";
  sdate: any = "0";
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  show: boolean = true;
  region_id: any;
  program_id: string;
  options: Array<any> = [];
  data;
  event = [];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() select: EventEmitter<TabDirective> = new EventEmitter();
  @Output() selectTab: EventEmitter<TabDirective> = new EventEmitter();
  calendar_month: number;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private settingDataService: SettingDataService,
    private usersService: RegionDataService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();
    this.program_id = localStorage.getItem("program_id");
    console.log(this.dropdown);
    this.getRegions();
    (this.min = 2019), (this.max = new Date().getFullYear());
    if (typeof parseInt(localStorage.getItem("calendar_year")) != "undefined") {
      this.min = parseInt(localStorage.getItem("calendar_year"));
      this.max = parseInt(localStorage.getItem("calendar_year"));
      this.sdate = parseInt(localStorage.getItem("calendar_year"));
    }
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    this.options.reverse();
    this.calendar_month = parseInt(localStorage.getItem("calendar_year"));
  }

  getRegions(): void {
    this.settingDataService.getAllRegion().subscribe(
      (result: any) => {
        this.regionsData = result.data.regions;
        this.getStateList();
        if (typeof this.regionsData != "undefined") {
          if (this.regionsData.length > 0) {
            this.dropdown.find((d) => d.id == 1).data = this.regionsData;
          }
        }
        this.regionsData.forEach((ele) => {
          ele.itemName = ele.region_name;
          ele.checked = false;
          ele.name = ele.region_name;
        });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe((data) => {
      this.states = data;
      this.dropdown.find((d) => d.id == 2).data = this.states.filter(
        (i) => i.region_id == this.selectedNewRegion
      );
      this.states.forEach((s) => {
        s.name = s.state_name;
        s.id = s.state_code;
      });
    });
  }

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    this.dropdown.find((d) => d.id == 3).data = this.channels;
  }

  StatesfilterByRegion: any[];
  resetDropdown: boolean;

  getList(event, mainData) {
    this[mainData] = event;
    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
    } else {
      g = "";
    }
    return g;
  }

  value: string;
  onSelect(data: TabDirective): void {
    this.value = data.heading;
    console.log(this.value);
  }

  m1FilterByRegion = [];
  m2FilterByRegion = [];
  m3FilterByRegion = [];

  selectedValueCheck(event, filter): void {
    this.event = event;
    // console.log("general:", event, filter);
    console.log(event);
    debugger
    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        if (event != 0) {
          event.forEach((element) => {
            let year = parseInt(localStorage.getItem("calendar_year"));
            let data = this.states.filter(
              (s) => s.region_id == element.id && s.state_year == year
            );
            let m1Data = this.m1Users.filter((s) => s.region_id == element.id);
            let m2Data = this.m2Users.filter((s) => s.region_id == element.id);
            let m3Data = this.m3Users.filter((s) => s.region_id == element.id);
            this.m1FilterByRegion.push(...m1Data);
            this.m2FilterByRegion.push(...m2Data);
            this.m3FilterByRegion.push(...m3Data);
            this.StatesfilterByRegion.push(...data);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        break;
      case 2:
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        if (event != 0) {
          event.forEach((element) => {
            let m1Data = this.m1Users.filter((s) => s.state_id == element.id);
            let m2Data = this.m2Users.filter((s) => s.state_id == element.id);
            let m3Data = this.m3Users.filter((s) => s.state_id == element.id);
            this.m1FilterByRegion.push(...m1Data);
            this.m2FilterByRegion.push(...m2Data);
            this.m3FilterByRegion.push(...m3Data);
          });
        }
        this.state = this.getList(event, "selectedState");
        this.dropdown.find((d) => d.id == 4).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m2FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m3FilterByRegion;
        console.log(event);
        break;
      case 3:
        this.channel = this.getList(event, "selectedChannel");
        localStorage.setItem("selectedchannels", JSON.stringify(event));
        break;
      case 4:
        this.m1 = this.getList(event, "selectedM1");
        localStorage.setItem("selectedm1s", JSON.stringify(event));
        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event, "selectedM2");
        localStorage.setItem("selectedm2s", JSON.stringify(event));
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 = this.getList(event, "selectedM3");
        localStorage.setItem("selectedm3s", JSON.stringify(event));
        break;
    }
    // this.searchByFilter();
  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    window.location.reload();

  }

  errorMsg: any = "";
  errorMsg1: any = "";
  whitelist_date: any = 0;
  whitelist_date1: any;
  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedM1: number[] = [];
  selectedM2: number[] = [];
  selectedM3: number[] = [];
  startDate: any;
  endDate: any;

  searchByFilter() {
    this.errorMsg = "";
    this.errorMsg1 = "";

    this.resetDropdown = false;
    if (this.whitelist_date == 0) {
      this.whitelist_date1 = 0;
    } else {
      this.whitelist_date1 = this.datepipe.transform(
        this.whitelist_date,
        "yyyy-MM-dd"
      );
    }
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (
      this.selectedChannel === undefined ||
      this.selectedChannel.length == 0
    ) {
      this.channel = "";
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = "";
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = "";
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = "";
    }
    if (this.smonth == "0" && this.sdate == "0") {
      this.errorMsg = "Please select year";
      this.errorMsg1 = "Please select month";
    } else {
      if (typeof this.smonth != "undefined") {
        if (parseInt(this.smonth) != 0) {
          if (parseInt(this.sdate) != 0) {
            this.data = new FilterModel();
            this.data.startDate = this.startDate;
            this.data.endDate = this.endDate;
            this.data.region =
              typeof this.region != "undefined" ? this.region : null;
            this.data.state = this.state;
            this.data.channel = this.channel;
            this.data.m1 = this.m1;
            this.data.m2 = this.m2;
            this.data.m3 = this.m3;
            this.data.whitelist_date1 = this.whitelist_date1;
            this.data.smonth = this.smonth;
            this.data.sdate = this.sdate;
          } else {
            this.errorMsg = "Please select year";
          }
        }
      }

      if (typeof this.sdate != "undefined") {
        if (parseInt(this.sdate) != 0) {
          if (parseInt(this.smonth) != 0) {
            let data = new FilterModel();
            data.startDate = this.startDate;
            data.endDate = this.endDate;
            data.region = this.region;
            data.state = this.state;
            data.channel = this.channel;
            data.m1 = this.m1;
            data.m2 = this.m2;
            data.m3 = this.m3;
            data.whitelist_date1 = this.whitelist_date1;
            data.smonth = this.smonth;
            data.sdate = this.sdate;
          } else {
            this.errorMsg1 = "Please select month";
          }
        }
      }
    }
  }

  ngOnChanges() {
    if (this.selectedTab) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  getActiveTab() {
    const activeTab = this.tabset.tabs.filter((tab) => tab.active);
    console.log(activeTab[0].heading);
    let heading = activeTab[0].heading;
    if (heading == "Mystery Shopper CSM Level Detail" || heading == "Menu Programs") {
      this.show = false;
    } else {
      this.show = true;
    }
  }
}
