import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() barChartLabels: Label[];
  @Input() barChartData: ChartDataSets[];

  barChartColors: Color[] = [
    {
      backgroundColor: '#B80F0A',
      pointBorderColor: '#fff'
    },
    {
    backgroundColor: '#e60000',
    pointBorderColor: '#fff'
  },
  {
    backgroundColor: '#b30000',
    pointBorderColor: '#fff'
  },
  {
    backgroundColor: '#800000',
    pointBorderColor: '#fff'
  },
  {
    backgroundColor: '#4d0000',
    pointBorderColor: '#fff'
  }
];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  constructor() { }

  ngOnInit() {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }


}
