import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lb-report',
  templateUrl: './lb-report.component.html',
  styleUrls: ['./lb-report.component.scss']
})
export class LbReportComponent implements OnInit {
  table_data;
  constructor() { }


  ngOnInit() {
    this.table_data = [
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Jan21.xlsx",
        "MYEAR": "January 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Feb21.xlsx",
        "MYEAR": "Ferbruary 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Mar21.xlsx",
        "MYEAR": "March 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Apr21.xlsx",
        "MYEAR": "April 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Jul21.xlsx",
        "MYEAR": "July 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Aug21.xlsx",
        "MYEAR": "August 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Sep21.xlsx",
        "MYEAR": "September 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Oct21.xlsx",
        "MYEAR": "October 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Nov21.xlsx",
        "MYEAR": "November 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Dec21.xlsx",
        "MYEAR": "December 2021"
      },
      {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Jan22.xlsx",
        "MYEAR": "January 2022"
    },
{
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Feb22.xlsx",
        "MYEAR": "February 2022"
    },
{
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Mar22.xlsx",
        "MYEAR": "March 2022"
    },
    {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_Apr22.xlsx",
        "MYEAR": "April 2022"
    },
    {
        "Link": "https://api1.bud4trade.bigcityexperiences.com/uploads/otherreports/Leaderboard_Final_May22.xlsx",
        "MYEAR": "May 2022"
    }
     ]
  }

}
