import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-average-tab1',
  templateUrl: './average-tab1.component.html',
  styleUrls: ['./average-tab1.component.scss']
})
export class AverageTab1Component implements OnInit {

  @Input() data: any[]; 

  constructor() { }

  ngOnInit() {
  }

}
