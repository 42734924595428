export class TileModel{
    name : string;
    color : string;
    value : any;
    value1 : any;
}

export class WhitleTile{
    name : string;
    data : any;
    type : string;
    breakupdata : any;
}

