import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonHttpServiceService } from '../../Services/common-http-service.service';
import { DatePipe } from '@angular/common';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { DAILY_DROPDOWN_DETAILS, DAILY_REPORT } from '../../Models/general-headings.model';
import { RegionModel, ReportModel } from 'src/app/model/contract/channel';
import * as moment from 'moment';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
declare var $: any;
import { SettingDataService } from 'src/app/model/setting-data.service';
import { UserDataService } from 'src/app/model/user-data.service';
import { RegionDataService } from 'src/app/model/region-data.service';
import { type } from 'os';
import { StateModel } from 'src/app/model/contract/menu-program';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {
  public form: FormGroup;
  submittedCampaign: boolean;
  minDate: Date;
  projectSettings: any[] = DAILY_REPORT;
  dropdown: any[] = DAILY_DROPDOWN_DETAILS;
  maxDate: Date;
  startdate: string;
  enddate: string;
  reports: any[];
  weekOrMonth: any[];
  showWeek: boolean = false;
  showDate: boolean;
  flag: any;
  dateRange : any;
  regions: RegionModel[];
  selectedRegion: any;
  data: any;
  isLink: any;
  region_id: number;
  constructor(public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    private httpClient: CommonHttpServiceService,
    public ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private  settingDataService: SettingDataService,
    private usersService : RegionDataService,
    public alertService: AlertService,
    public hubAdminDataProvider: HubAdminDataProvider,) {
      this.maxDate = new Date();
    }

  ngOnInit() {
    this.region_id = parseInt(localStorage.getItem('region_id'))
    this.getRegions();
    this.getStaticReports();
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);
  }

  message: any;

  errorMessage : any;

  searchByFilter() {
    this.errorMessage = "";
    if(typeof this.dateRange != "undefined") {
      let date = moment(this.dateRange).format("YYYY-MM-DD");
      if(typeof this.selectedRegion =="undefined") this.selectedRegion = '';
      if(typeof this.selectedState =="undefined") this.selectedState = '';
      if(typeof this.selectedReport == "undefined") {
        this.dropdown.find(i => i.id == 1).errorStatus = true;
        return;
      }
      this.dropdown.find(i => i.id == 1).errorStatus = false;
      debugger
      this.httpClient.getDailyReports(
      this.region_id, date, this.selectedState, this.selectedReport)
      .subscribe((res: any) => {
      if(res.success) {
        if(res.data.daily_reports.length > 0) {
          this.data = res.data.daily_reports;
        }
        else{
          this.data = [];
          this.message = 'Your Report request has been received. We will get back to with Report Download Link.';
          this.toastr.warning(`${this.message}`,
            'Dashboard', {
            timeOut: 10000,
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
      }
    },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
    }
    else{
      this.errorMessage = "Please select the date"
    }
  }

  resetDropdown: boolean;

  resetFilter() {
    this.resetDropdown = true;
  }

  StatesfilterByRegion : any[];
  states : any[];
  selectedReport: any;
  selectedState : any;
  selectedValueCheck(event, filter): void {
    switch (filter) {
        case 1:
              this.selectedReport = event.find(i => i.id).id;
              break;
        case 2:
          this.selectedRegion = event.find(i => i.id).id;
          this.StatesfilterByRegion= this.states.filter(s => s.region_id == this.selectedRegion);
          this.dropdown.find(d => d.id == 3).data = this.StatesfilterByRegion;
          break;
        case 3:
          this.selectedState = event.find(i => i.id).id;
          break;
    }
    console.log("general:", event, filter);
  }

  statesData : StateModel[];
  selectedStates : StateModel[];
  regionsData: RegionModel[];
  selectedNewRegion: number = 0;
  reportLists : ReportModel[];


  getRegions(): void {
    
    this.settingDataService.getAllRegion()
        .subscribe(
            (result: any) => {
                this.regionsData = result.data.regions;
                this.getStateList()
                if(typeof this.regionsData !="undefined"){
                  if(this.regionsData.length > 0) {
                    this.regionsData = this.regionsData.filter(i => i.id == this.region_id);
                    this.selectedNewRegion = this.regionsData.find(i => i.id == this.region_id).id;
                  }
               }
                this.regionsData.forEach(ele => {
                    ele.itemName = ele.region_name;
                    ele.checked = false;
                    ele.name = ele.region_name
                });
            },
            error => {
                this.alertService.error(error);
            }
        )
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe(
        (data) => {
            this.states = data;
            this.dropdown.find(d => d.id == 3).data = this.states.filter(i => i.region_id == this.selectedNewRegion);
            this.states.forEach(
                s => {
                    s.name = s.state_name;
                    s.id = s.state_code

                }
            )
        }
    )
}


getStaticReports(): void {
  debugger

  this.settingDataService.getAllReports()
      .subscribe(
          (result: any) => {
              this.reportLists = result.data.static_reports;
              this.reportLists.forEach(ele => {
                ele.itemName = ele.name;
                ele.checked = false;
                ele.name = ele.name
            });
              this.reports = this.reportLists.filter(r => r.is_frequency == 1);

              this.dropdown.find(d => d.id == 1).data = this.reports;
          },
          error => {
              this.alertService.error(error);
          }
    )
}

}
