import { ColumnSetting } from './table.model';


export const DROPDOWN_DETAILS: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: [],
    selectedValues : []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: [],
    selectedValues : []
  },
  {
    id: 3,
    name: 'Channel',
    searchText: 'Search Channel',
    data: [],
    selectedValues : []
  },
  {
    id: 4,
    name: 'M1',
    searchText: 'Select M1',
    data: [],
    selectedValues : []
  },
  {
    id: 5,
    name: 'M2',
    searchText: 'Select M2',
    data: [],
    selectedValues : []
  },
  {
    id: 6,
    name: 'M3',
    searchText: 'Select M3',
    data: [],
    selectedValues : []
  },
]

export const DROPDOWN_DETAILS_MYSTERY: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: []
  },
  {
    id: 3,
    name: 'Channel',
    searchText: 'Search Channel',
    data: []
  },
  {
    id: 4,
    name: 'M1',
    searchText: 'Select M1',
    data: []
  },
  {
    id: 5,
    name: 'M2',
    searchText: 'Select M2',
    data: []
  },
  {
    id: 6,
    name: 'M3',
    searchText: 'Select M3',
    data: []
  }
]

export const DROPDOWN_DETAILS11: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: [],
    selectedValues : []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: [],
    selectedValues : []
  },
  {
    id: 3,
    name: 'Channel',
    searchText: 'Search Channel',
    data: [],
    selectedValues : []
  },
  {
    id: 4,
    name: 'M1',
    searchText: 'Select M1',
    data: [],
    selectedValues : []
  },
  {
    id: 5,
    name: 'M2',
    searchText: 'Select M2',
    data: [],
    selectedValues : []
  },
  {
    id: 6,
    name: 'M3',
    searchText: 'Select M3',
    data: [],
    selectedValues : []
  },
]


export const DROPDOWN_DETAILS2: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: []
  }
  // {
  //   id: 3,
  //   name: 'Channel',
  //   searchText: 'Search Channel',
  //   data: []
  // },
  // {
  //   id: 4,
  //   name: 'M1',
  //   searchText: 'Select M1',
  //   data: []
  // },
  // {
  //   id: 5,
  //   name: 'M2',
  //   searchText: 'Select M2',
  //   data: []
  // },
  // {
  //   id: 6,
  //   name: 'M3',
  //   searchText: 'Select M3',
  //   data: []
  // },
]

export const MONTH_DROPDOWN_DETAILS: any[] = [
  // {
  //   id: 1,
  //   name: 'Region',
  //   searchText: 'Select Region',
  //   data: [],
  //   show: false,
  //   error: 'Please select region*',
  //   errorStatus : false
  // },
  {
    id: 2,
    name: 'Month',
    searchText: 'Select Month',
    data: [],
    show: false,
    error: 'Please select month*',
    errorStatus : false
  },
  {
    id: 3,
    name: 'Year',
    searchText: 'Select Year',
    data: [],
    show: false,
    error: 'Please select year*',
    errorStatus : false
  },
  {
    id: 4,
    name: 'Report',
    searchText: 'Select Report',
    data: [],
    show: false,
    error: 'Please select report*',
    errorStatus : false
  }
]

export const FIREBASE_DROPDOWN_DETAILS: any[] = [
  {
    id: 4,
    name: 'Report',
    searchText: 'Select Report',
    data: [],
    show: false,
    error: 'Please select report*',
    errorStatus : false
  },
  {
    id: 1,
    name: 'Region',
    searchText: 'Select Region',
    data: [],
    show: false,
    error: 'Please select region*',
    errorStatus : false
  },
  {
    id: 2,
    name: 'Month',
    searchText: 'Select Month',
    data: [],
    show: false,
    error: 'Please select month*',
    errorStatus : false
  },
  {
    id: 3,
    name: 'Year',
    searchText: 'Select Year',
    data: [],
    show: false,
    error: 'Please select year*',
    errorStatus : false
  }
]

export const WEEKLY_DROPDOWN_DETAILS :any[]= [
  {
    id: 1,
    name: 'Region',
    searchText: 'Select Region',
    data: [],
    show: false,
    error: 'Please select region*',
    errorStatus : false
  },
  {
    id: 2,
    name: 'Report',
    searchText: 'Select Report',
    data: [],
    show: false,
    error: 'Please select report*',
    errorStatus : false
  }
]

export const DAILY_DROPDOWN_DETAILS: any[] = [
  {
    id: 1,
    name: 'Report',
    searchText: 'Select Report',
    data: [],
    show : true,
    error: 'Please select report*',
    errorStatus : false
  },
  {
    id: 2,
    name: 'Region',
    searchText: 'Select Region',
    data: [],
    show : true,
    error: 'Please select report*',
    errorStatus : false
  },
  {
    id: 3,
    name: 'States',
    searchText: 'Select States',
    data: [],
    show : true,
    error: 'Please select report*',
    errorStatus : false
  }
]


export const MONTH : any[] =[
  {id :'1', name: 'Janaury'},
  {id :'2', name: 'February'},
  {id :'3', name: 'March'},
  {id :'4', name: 'April'},
  {id :'5', name: 'May'},
  {id :'6', name: 'June'},
  {id :'7', name: 'July'},
  {id :'8', name: 'August'},
  {id :'9', name: 'September'},
  {id :'10', name: 'October'},
  {id :'11', name: 'November'},
  {id :'12', name: 'December'}
]

export const DROPDOWN_DETAILS1: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: []
  },
  {
    id: 3,
    name: 'Channel',
    searchText: 'Search Channel',
    data: []
  },
  {
    id: 4,
    name: 'M1',
    searchText: 'Select M1',
    data: []
  },
  {
    id: 5,
    name: 'M2',
    searchText: 'Select M2',
    data: []
  },
  {
    id: 6,
    name: 'M3',
    searchText: 'Select M3',
    data: []
  },
  {
    id: 7,
    name: 'M3',
    searchText: 'Select Brand',
    data: []
  }

]

export const MENU_DROPDOWN: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: []
  },
  {
    id: 7,
    name: 'Brand',
    searchText: 'Search Brand',
    data: []
  },
  {
    id: 8,
    name: 'Tempalte',
    searchText: 'Search Template',
    data: []
  },
  {
    id: 9,
    name: 'Status',
    searchText: 'Search Status',
    data: []
  },

]

export const GENERAL_AVERAGE: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Modules'
  },
  {
    primaryKey: 'open_rate',
    header: 'Minutes'
  }
]

export const GENERAL_OPEN_RATE: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Modules'
  },
  {
    primaryKey: 'open_rate',
    header: 'User Count'
  }
]

export const GENERAL_PROMO: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Name'
  },
  {
    primaryKey: 'open_rate',
    header: 'Open Rate'
  },

]

export const GENERAL_PROMO1: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Name'
  },
  {
    primaryKey: 'avg_user_count',
    header: 'Avg CSM Count'
  },
  {
    primaryKey: 'avg_time_spent_hrs',
    header: 'Avg Time Spent(hrs)'
  },

]
export const GENERAL_REWARD: ColumnSetting[] = [
  {
    primaryKey: 'module_name',
    header: 'Module'
  },
  {
    primaryKey: 'total_value_budgeted',
    header: 'Total Points Budgeted'
  },
  {
    primaryKey: 'total_value_earned',
    header: 'Total Points Earned'
  }
]

export const REWART_TAT_TOP: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Brand Name'
  },
  {
    primaryKey: 'avg_time',
    header: 'Average time taken'
  }
]

export const CHANNEL_TARGET: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Category'
  },
  {
    primaryKey: 'target',
    header: 'Target'
  },
  {
    primaryKey: 'achievement',
    header: 'Achievement'
  },
  {
    primaryKey: 'percentage',
    header: '%'
  }
]

export const DAILY_REPORT: ColumnSetting[] = [
  {
    primaryKey: 'link',
    header: 'Link'
  },
];

export const CHANNEL_TARGETS: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'Channel'
  },
  {
    primaryKey: 'target',
    header: 'Target'
  },
  {
    primaryKey: 'achievement',
    header: 'Achievement'
  },
  {
    primaryKey: 'percentage',
    header: '%'
  }
]


export const CHANNEL_BUDGET: ColumnSetting[] = [
  {
    primaryKey: 'channel',
    header: 'Channel'
  },
  {
    primaryKey: 'budgeted',
    header: 'Target'
  },
  {
    primaryKey: 'utilized',
    header: 'Achievement'
  },
  {
    primaryKey: 'percent',
    header: '%'
  }
]

export const CATEGORY_BUDGET: ColumnSetting[] = [
  {
    primaryKey: 'brand_category_name',
    header: 'Category'
  },
  {
    primaryKey: 'budgeted',
    header: 'Target'
  },
  {
    primaryKey: 'utilized',
    header: 'Achievement'
  },
  {
    primaryKey: 'percent',
    header: '%'
  }
]

export const CHANNEL_TARGET_UPLIFT: ColumnSetting[] = [
  {
    primaryKey: 'brand_category_name',
    header: 'Category'
  },
  {
    primaryKey: 'sales_qty',
    header: '% of CSM Achieving Target'
  },
  // {
  //   primaryKey: 'uplift_sales_qty',
  //   header: 'Uplift'
  // },
  // {
  //   primaryKey: 'percent',
  //   header: '%'
  // }
]


export const CHANNEL_TARGET_UPLIFT_ACHIEVEMENT: ColumnSetting[] = [
  {
    primaryKey: 'channel_desc',
    header: 'Channel'
  },
  {
    primaryKey: 'sales_qty',
    header: '% of CSM Achieving Target'
  },
  // {
  //   primaryKey: 'uplift_sales_qty',
  //   header: 'Uplift'
  // },
  // {
  //   primaryKey: 'percent',
  //   header: '%'
  // }
]


export const DISTRIBUTION_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'range_percentage',
    header: 'Bracket'
  },
  {
    primaryKey: 'total_points',
    header: 'No. of CSMs'
  }
]

export const DISTRIBUTION_HEADERS1: ColumnSetting[] = [
  {
    primaryKey: 'bracket',
    header: 'Bracket'
  },
  {
    primaryKey: 'no_of_users',
    header: 'No. of CSMs'
  }
]

export const SCHEMES_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'column1',
    header: 'Name'
  },
  {
    primaryKey: 'column2',
    header: 'Open Rate'
  }
]

export const ROI_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'budget',
    header: 'Budget Spent'
  },
  {
    primaryKey: 'Incr',
    header: 'Incr MACO'
  },
  {
    primaryKey: 'roi',
    header: 'ROI %'
  }
]

export const M1_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'column1',
    header: 'M1 Names'
  },
  {
    primaryKey: 'column2',
    header: 'Vol Achievement'
  },
  {
    primaryKey: 'column2',
    header: 'Uplift'
  },
  {
    primaryKey: 'column2',
    header: '% CSM Achieving Target'
  }
]

export const M2_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'column1',
    header: 'M2 Names'
  },
  {
    primaryKey: 'column2',
    header: 'Vol Achievement'
  },
  {
    primaryKey: 'column2',
    header: 'Uplift'
  },
  {
    primaryKey: 'column2',
    header: '% CSM Achieving Target'
  }
]

export const M3_HEADERS: ColumnSetting[] = [
  {
    primaryKey: 'column1',
    header: 'M3 Names'
  },
  {
    primaryKey: 'column2',
    header: 'Vol Achievement'
  },
  {
    primaryKey: 'column2',
    header: 'Uplift'
  },
  {
    primaryKey: 'column2',
    header: '% CSM Achieving Target'
  }
]

export const M1_CHALLENGE: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'M1 Names'
  },
  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'success_per',
    header: 'Success %'
  }
]

export const M2_CHALLENGE: ColumnSetting[] = [
  {
    primaryKey: 'user_detail_name',
    header: 'M2 Names'
  },
  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'success_per',
    header: 'Success %'
  }
]

export const M2_VISIBILITY: ColumnSetting[] = [
  {
    primaryKey: 'user_detail_name',
    header: 'M2 Names'
  },
  // {
  //   primaryKey: 'successful',
  //   header: 'Total Attempted'
  // },
  {
    primaryKey: 'attempted_count',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'success_count',
    header: 'Total Successful'
  },
  {
    primaryKey: 'successrate',
    header: 'Success %'
  }
]

export const M3_VISIBILITY: ColumnSetting[] = [
  {
    primaryKey: 'user_detail_name',
    header: 'M3 Names'
  },
  {
    primaryKey: 'attempted_count',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'success_count',
    header: 'Total Successful'
  },
  {
    primaryKey: 'successrate',
    header: 'Success %'
  }
]

export const M3_CHALLENGE: ColumnSetting[] = [
  {
    primaryKey: 'user_detail_name',
    header: 'M3 Names'
  },
  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'success_per',
    header: 'Success %'
  }
]




export const CSM_CHALLENGE: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'CSM Names'
  },
  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'success_per',
    header: 'Success %'
  }
]

export const CSM_CHALLENGE_LD: ColumnSetting[] = [
  {
    primaryKey: 'ro_name',
    header: 'CSM Names'
  },
  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'success_per',
    header: 'Success %'
  }
]
export const CHART_TABS: any[] = [
  {
    id: 1,
    name: 'Region',
    searchText: 'Search Region',
    data: []
  },
  {
    id: 2,
    name: 'State',
    searchText: 'Search State',
    data: []
  },
  {
    id: 3,
    name: 'Channel',
    searchText: 'Search Channel',
    data: []
  },
  {
    id: 4,
    name: 'M1',
    searchText: 'Select M1',
    data: []
  },
  {
    id: 5,
    name: 'M2',
    searchText: 'Select M2',
    data: []
  },
  {
    id: 6,
    name: 'M3',
    searchText: 'Select M3',
    data: []
  }

]


export const M1_PERFOMANCE: ColumnSetting[] = [
  {
    primaryKey: 'tse_name',
    header: 'M1 Name'
  },
  // {
  //   primaryKey: 'number',
  //   header: 'Number'
  // },

  {
    primaryKey: 'attempted_count',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'success_count',
    header: 'Total Success'
  },
  {
    primaryKey: 'successrate',
    header: 'Success %'
  }
]

export const M2_PERFOMANCE: ColumnSetting[] = [
  {
    primaryKey: 'tse_name',
    header: 'M2 Name'
  },
  {
    primaryKey: 'number',
    header: 'Number'
  },
  {
    primaryKey: 'successrate',
    header: 'Success Rate'
  }
]

export const M3_PERFOMANCE: ColumnSetting[] = [
  {
    primaryKey: 'tse_name',
    header: 'M3 Name'
  },
  {
    primaryKey: 'number',
    header: 'Number'
  },
  {
    primaryKey: 'successrate',
    header: 'Success Rate'
  }
]

export const TMM_PERFOMANCE: ColumnSetting[] = [
  {
    primaryKey: 'name',
    header: 'TMM Name'
  },
  {
    primaryKey: 'number',
    header: 'Number'
  },
  {
    primaryKey: 'success_rate',
    header: 'Success Rate'
  }
]

export const CSM_PERFOMANCE: ColumnSetting[] = [
  {
    primaryKey: 'csm_name',
    header: 'CSM Name'
  },
  // {
  //   primaryKey: 'number',
  //   header: 'Number'
  // },

  {
    primaryKey: 'attempted',
    header: 'Total Attempted'
  },
  {
    primaryKey: 'successful',
    header: 'Total Successful'
  },
  {
    primaryKey: 'successrate',
    header: 'Success %'
  }

]

export const AUDIT_DETAILS: ColumnSetting[] = [
  {
    primaryKey: 'parameter',
    header: 'Parameter'
  },
  {
    primaryKey: 'questions',
    header: 'Questions'
  },
  {
    primaryKey: 'original_response',
    header: 'Original response'
  },
  {
    primaryKey: 'score_payout',
    header: 'Question Score & Payout'
  },
  {
    primaryKey: 'link',
    header: 'Link to Execution Picture'
  },
  {
    primaryKey: 'action',
    header: 'Action required? (Y/N)'
  },
  {
    primaryKey: 'upload',
    header: 'Upload picture/declaration'
  }
]


export const REDEMPTION_MASTER: any[] = [
    "USER ID",
    "Name",
    "Mobile",
    "Outlet Code",
    "Outlet Name",
    "Address",
    "State",
    "Created Date",
    "Status",
    "Order Ref No.",
    "Product Name",
    "Denomination",
    "Offer Code",
    "Offer Code Pin",
    "Response OrderID",
    "Redeem Points"
];

export const LIVEPOINT: any[] = [
  "SN",
  "Osmosys Code",
  "Outlet Name",
  "State",
  "Mobile Number",
  "Earned Points",
  "Redeemed Points",
  "Unredeemed (LIVE) Points",
  // "CSM Status"
];

export const TARGET_AND_ACHIVEMENTS: any[] = [
    "S.No.",
    "Osmosys Code",
    "Outlet Name",
    "TSE",
    "ASM",
    "State",
    "Channel",
    "Name of CSM",
    "MobileNo.",
    "Haywards/Knockout",
    "Beck's Ice",
    "Budweiser",
    "Magnum",
    "Corona/Hoegaarden",
    "Seven Rivers",
    "Total Target",
    "Haywards/Knockout",
    "Beck's Ice",
    "Budweiser",
    "Magnum",
    "Corona/Hoegaarden'",
    "Seven Rivers",
    "Total Achievement",
    "Haywards/Knockout",
    "Beck's Ice",
    "Budweiser",
    "Magnum",
    "Corona/Hoegaarden'",
    "Seven Rivers",
    "Total Points"
];

// Firebase Report

export const FIRE_TIME_SPENT: any[] = [
  "Sno.",
  "Date",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Time Spent (In Seconds)"
];

export const FIRE_ENAGEMENT_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Engagement Name",
  "Time Spent (In Seconds"
];

export const FIRE_MAU_TIME_SPENT: any[] = [
  "Sno.",
  "Month/Year",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Avg. Time Spent (In Seconds)"
];

export const FIRE_MODULE_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Module Name",
  "Time Spent (In Seconds)"
];

export const FIRE_SCHEME_TIME_SPENT: any[] = [
  "Sno.",
  "Osmosys Code",
  "Outlet Name",
  "CSM Number",
  "State",
  "Region",
  "Scheme Name",
  "Date Clicked"
];


export const COLOR_TITLE: any[] = ['#725CA4', '#04AEE6', '#EBB763', '#725CA4', '#04AEE6', '#EBB763', '#725CA4', '#04AEE6', '#EBB763', '#725CA4', '#04AEE6', '#EBB763', '#725CA4', '#04AEE6', '#EBB763']


export const QC_PANEL: any[] = [
  "Sno.",
  "Outlet Code",
  "Outlet Name",
  "Mystery Shopper Name",
  "Status",
];

export const QC_PANEL1: any[] = [
 "Name",
 "Count"
];
