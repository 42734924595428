import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnSetting } from '../../Models/table.model';

@Component({
  selector: 'app-general-table',
  templateUrl: './general-table.component.html',
  styleUrls: ['./general-table.component.scss']
})
export class GeneralTableComponent implements OnChanges {
  @Input() data: any[];
  keys: string[];
  @Input() settings: ColumnSetting[];
  columnMaps: ColumnSetting[];
  ngOnChanges() {
    if (this.settings) { // when settings provided
      this.columnMaps = this.settings;
    } else { // no settings, create column maps with defaults
      this.columnMaps = Object.keys(this.data[0])
        .map(key => {
          return {
            primaryKey: key,
            header: key.slice(0, 1).toUpperCase() +
              key.replace(/_/g, ' ').slice(1)
          }
        });
    }
  }

}
