import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackendLayoutComponent } from './layout/backend-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { AuthGuard } from '../model/auth.guard';
import { ProgramOverviewComponent } from './dashboard/program-overview/program-overview.component';
import { MysteryQcComponent } from './mystery/Components/mystery-qc/mystery-qc.component';
import {QcReportComponent} from './mystery/Components/qc-report/qc-report.component'

const routes: Routes = [
  {
    path: 'mystery-admin',
    component: BackendLayoutComponent,
    data: {
      breadcrumb: 'Mystery Admin'
    },
    canActivate: [AuthGuard],
     children: [
      { path: 'add-mystery-shopper', loadChildren: () => import('./mystery/Components/add-mystery-shopper/add-mystery-shopper.module').then(m => m.AddMysteryShopperModule), data: { breadcrumb: 'Mystery Shopper Configuration' } },
      { path: 'assign-mystery-shopper', loadChildren: () => import('./mystery/Components/assign-mystery-shopper/assign-mystery-shopper.module').then(m => m.AssignMysteryShopperModule), data: { breadcrumb: 'Mystery Shopper Configuration' } },
      { path: 'mystery-audit-qc',component: MysteryQcComponent, data: { breadcrumb: 'Mystery-Qc' } },
      { path: 'qc-report', component: QcReportComponent, data: { breadcrumb: 'Qc-Report' } },
      { path: 'mystery-audit-template', loadChildren: () => import('./mystery/Components/mystery-audit-template/mystery-audit-template.module').then(m => m.MysteryAuditTemplateModule), data: { breadcrumb: 'Mystery Shopper Configuration ' } },
      { path: 'mystery-outlet-whitelist', loadChildren: () => import('./mystery/Components/mystery-whitelist/mystery-whitelist.module').then(m => m.MysteryWhitelistModule), data: { breadcrumb: 'Mystery Shopper Outlet Whitelist' } },
      { path: 'program-overview-dashboard', component: ProgramOverviewComponent, data: { breadcrumb: 'General Reports' } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MysteryDashboardRoutingModule {
}
