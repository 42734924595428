import { Component, OnInit } from '@angular/core';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { UserDataService } from 'src/app/model/user-data.service';
import { ToastrService } from 'ngx-toastr';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { RegionModel, ChannelModel, ChartModel } from 'src/app/model/contract/channel';
import { DatePipe } from '@angular/common';
import { start } from 'repl';
import { RegionDataService } from 'src/app/model/region-data.service';
import { StateModel } from 'src/app/model/contract/menu-program';

@Component({
  selector: 'app-program-overview',
  templateUrl: './program-overview.component.html',
  styleUrls: ['./program-overview.component.scss'],
  providers: [DatePipe]
})
export class ProgramOverviewComponent implements OnInit {

  private _form: FormGroup;
  region: number;
  averagepointsearned: any;
  totalivrcall: any;
  customerstatusreport: any;
  avgnoofengagmentperuser: any;
  avgredemptiontimebtuserandqc: any;
  selectedChannel: number;
  tseusers: any;
  m2users: any;
  m3users: any;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  timespentapppermonth: any;
  timespentappperweek: any;
  avgtimespentappperweek: any;
  avgtimespentapppermonth: any;
  avgtimespentengagement: any;
  totwhitelistvsopenratemodule: any;
  outletactivepercentagemontly: any;
  constructor(
    private hubAdminDataProvider: HubAdminDataProvider,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private users: UserDataService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe,
    public usersService: RegionDataService,
  ) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });
  }

  ngOnInit() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop();
    }, 1000);
    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.getReport(0, 0, 0, 0, 0, 0, 0, 0)
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;

    this.selectedChannel = 0;
  }

  public TSEbarChartColors: any[] = [
    {
      backgroundColor: '#b80707',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#137d2f',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#237d2f',
      pointBorderColor: '#fff'
    },
  ]

  public SEbarChartColors: any[] = [
    {
      backgroundColor: '#007bff',
      pointBorderColor: '#fff'
    },
    {
      backgroundColor: '#17a2b8',
      pointBorderColor: '#fff'
    },
  ]

  public LDbarChartColors: any[] = [
    {
      backgroundColor: '#003f5c',
      pointBorderColor: '#fff'
    },
  ]




  public TSEDailybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public TSEDialybarChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Logged IN' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Logged Out' }
  ];

  public TSEWeeklybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public TSEWeeklybarChartData: any[] = [
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Logged IN' },
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Logged Out' }
  ];

  public TSEMonthlybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public TSEMonthlybarChartData: any[] = [
    { data: [65, 59, 28, 48, 56, 55, 40], label: 'Logged IN' },
    { data: [28, 48, 40, 19, 86, 56, 55], label: 'Logged Out' }
  ];


  public SEDailybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public SEDialybarChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Logged IN' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Logged Out' }
  ];

  public SEWeeklybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public SEWeeklybarChartData: any[] = [
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Logged IN' },
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Logged Out' }
  ];

  public SEMonthlybarChartLabels: any[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public SEMonthlybarChartData: any[] = [
    { data: [65, 59, 28, 48, 56, 55, 40], label: 'Logged IN' },
    { data: [28, 48, 40, 19, 86, 56, 55], label: 'Logged Out' }
  ];


  public lineChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  public LandDData: Array<any> = [{ data: [], label: 'Total' }];
  public LandDDataChartLabels: Array<any> = [];

  public TopEngagementData: Array<any> = [{ data: [], label: 'Total' }];
  public TopEngagementLabels: Array<any> = [];

  public TopLDData: Array<any> = [{ data: [], label: 'Total' }];
  public TopLDLabels: Array<any> = [];

  public TSEDailybarChartLabels6: any[] = [];
  public TSEDialybarChartData6: any[] = [];

  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];

  public TSEDailybarChartLabels8: any[] = [];
  public TSEDialybarChartData8 : any[] = [];

  totalsmscount: any;
  totalemailcount: any;
  totalwhitelistedusers: any;
  totalposmcounts: any;
  totalcoolercounts: any;
  totalengagementcount: any;
  outletsignuptrendsdaily: any;
  tsesignuptrendsdaily: any;
  waitersignuptrendsdaily: any;


  startDate: any = 0;
  endDate: any = 0;
  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id) {
    if (date != "") {
      this.startDate = this.datepipe.transform(date[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(date[1], 'yyyy-MM-dd');
    }
    this.getReport(this.startDate, this.endDate, region, state, channel, tse_user_id, m2_user_id, m3_user_id);
  }

  setAll() {
    this.TSEDailybarChartLabels6 = [];
    this.TSEDialybarChartData6 = [];
    this.TSEDailybarChartLabels7 = [];
    this.TSEDialybarChartData7 = [];
    this.TSEDailybarChartLabels8 = [];
    this.TSEDialybarChartData8 = [];
  }

  getReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id) {
    this.ngxLoader.start();
    this.setAll();
    this.users.getGeneralReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
      .subscribe(
        (result: any) => {
          this.totalsmscount = result.data.totalsmscount;
          this.totalemailcount = result.data.totalemailcount;
          this.totalposmcounts = result.data.totalposmcounts;
          this.totalcoolercounts = result.data.totalcoolercounts;
          this.totalengagementcount = result.data.totalengagementcount;
          this.outletsignuptrendsdaily = result.data.outletsignuptrendsdaily;
          this.tsesignuptrendsdaily = result.data.tsesignuptrendsdaily;
          this.customerstatusreport = result.data.customerstatusreport;
          this.totalwhitelistedusers = result.data.totalwhitelistedusers;
          this.avgnoofengagmentperuser = result.data.avgnoofengagmentperuser;
          this.totalivrcall = result.data.totalivrcall;
          this.avgredemptiontimebtuserandqc = result.data.avgredemptiontimebtuserandqc;
          this.waitersignuptrendsdaily = result.data.waitersignuptrendsdaily;
          this.timespentapppermonth = result.data.timespentapppermonth;
          this.timespentappperweek = result.data.timespentappperweek;
          this.avgtimespentappperweek = result.data.avgtimespentappperweek;
          this.avgtimespentapppermonth = result.data.avgtimespentapppermonth;
          this.avgtimespentengagement = result.data.avgtimespentengagement;
          if (Object.getOwnPropertyNames(result.data.avgnooflanddperuser).length !== 0) {
            this.LandDData[0].data = result.data.avgnooflanddperuser.data;
            this.LandDDataChartLabels = result.data.avgnooflanddperuser.label;
          }
          if (Object.getOwnPropertyNames(result.data.topperformingengagements).length !== 0) {
            this.TopEngagementData[0].data = result.data.topperformingengagements.data;
            this.TopEngagementLabels = result.data.topperformingengagements.label;
          }

          if (Object.getOwnPropertyNames(result.data.topperformingengagements).length !== 0) {
            this.TopLDData[0].data = result.data.topperforminglandd.data;
            this.TopLDLabels = result.data.topperforminglandd.label;
          }

          if (Object.getOwnPropertyNames(result.data.avgnoofengagmentperuser).length !== 0) {
            let data = result.data.avgnoofengagmentperuser;;
            this.TSEDailybarChartLabels6 = Object.values(result.data.avgnoofengagmentperuser).map(e => e['task_type']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.avgnoofengagmentperuser).map(e => e['avg_count']);
              chart.label = 'Avg Count';
            });
            this.TSEDialybarChartData6.push(chart);
          }

          if (Object.getOwnPropertyNames(result.data.totwhitelistvsopenratemodule).length !== 0) {
            let data = result.data.totwhitelistvsopenratemodule;
            this.totwhitelistvsopenratemodule = data;
            this.TSEDailybarChartLabels7 = Object.values(result.data.totwhitelistvsopenratemodule).map(e => e['module_name']);
            let chart: ChartModel = new ChartModel;
            let chart1: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.totwhitelistvsopenratemodule).map(e => e['whitelist_count']);
              chart.label = 'Whitelist Count';
            });

            data.forEach(element => {
              chart1.data = Object.values(result.data.totwhitelistvsopenratemodule).map(e => e['open_rate_count']);
              chart1.label = 'Openrate Count';
            });

            this.TSEDialybarChartData7.push(chart);
            this.TSEDialybarChartData7.push(chart1);
          }


          if (Object.getOwnPropertyNames(result.data.outletactivepercentagemontly).length !== 0) {
            let data = result.data.outletactivepercentagemontly;
            this.outletactivepercentagemontly = data;
            this.TSEDailybarChartLabels8 = Object.values(result.data.outletactivepercentagemontly).map(e => e['month']);
            let chart: ChartModel = new ChartModel;
            data.forEach(element => {
              chart.data = Object.values(result.data.outletactivepercentagemontly).map(e => e['active_percentage']);
              chart.label = 'Active Percentage';
            });

            this.TSEDialybarChartData8.push(chart);
          }

          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
          }
        }
      )
  }

  regionList: RegionModel[];

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

}
