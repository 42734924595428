import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { Router, ActivatedRoute } from "@angular/router";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { HierarchyProvider } from "src/app/shared/Providers/Shared/HierarchyProvider";
import {
  MenuTemplate,
  MenuBrandModel,
  StateModel,
  MenuTemplateRequestModel,
  MenuTransactionModel,
  MenuTemplateRequestModels
} from "src/app/model/contract/menu-program";
import {
  ChannelModel,
  OutletModel,
  RegionModel,
} from "src/app/model/contract/channel";
import { DatePipe } from "@angular/common";
import { Angular2Csv } from "angular2-csv";
import { LoyaltyTargetProvider } from "src/app/admin/Providers/LoyaltyTargetProvider";
import { MenuProgramProvider } from "src/app/admin/Providers/MenuProgramProvider";
import {
  DROPDOWN_DETAILS,
  M1_CHALLENGE,
  M2_CHALLENGE,
  M3_CHALLENGE,
  CSM_CHALLENGE,
  COLOR_TITLE,
  MENU_DROPDOWN,
} from "../Models/general-headings.model";
import { FilterModel } from "src/app/nesa-admin/Models/filter-data";
import { RegionDataService } from "src/app/model/region-data.service";
import { GlobalService } from "src/app/model/global.service";

@Component({
  selector: "app-menu-programs",
  templateUrl: "./menu-programs.component.html",
  styleUrls: ["./menu-programs.component.scss"],
})
export class MenuProgramsComponent implements OnInit {
  isSearch: boolean;
  dropdownList = [];
  statusDropdown = [];
  dropdownSettings = {};
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  templates: MenuTemplate[];
  requestTransactions: MenuTransactionModel[];
  siteUrl: any;
  brands: MenuBrandModel[];
  states: StateModel[];

  requests: MenuTemplateRequestModel[];
  requestsf: MenuTemplateRequestModels[];
  allrequests: MenuTemplateRequestModel[];
  channels: ChannelModel[];
  searchText: any;
  requestSendToAgency: MenuTemplateRequestModel[];
  requestUpdatedByAgency: MenuTemplateRequestModel[];
  requestApproved: MenuTemplateRequestModel[];
  requestTreview: MenuTemplateRequestModel[];
  requestSendToQrAgency: MenuTemplateRequestModel[];
  requestUpdatedByQrAgency: MenuTemplateRequestModel[];
  requestTmmreview: MenuTemplateRequestModel[];
  colors: any[] = COLOR_TITLE;
  dateRange: any = [];
  searchByBrandData: number;
  searchByTemplateData: number;
  searchByStatus: any;
  date1: any;
  date2: any;
  @Input() model: MenuTemplateRequestModel = new MenuTemplateRequestModel();
  assignUser: number;
  reassignUser: number;
  error: { isError: boolean; errorMessage: string };
  disableAssign: boolean = false;
  disableReAssign: boolean = false;
  regionList: RegionModel[];
  searchByRegion: any;
  searchByState: any;
  modalRef2: BsModalRef;
  totalCost: number = 0;
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  selectOption = [
    { id: 1, name: "State" },
    { id: 2, name: "Region" },
    { id: 3, name: "M1" },
    { id: 4, name: "M2" },
    { id: 5, name: "M3" },
  ];

  // Date Variable;

  start_date: any;
  end_date: any;
  dropdown: any[] = MENU_DROPDOWN;
  startDate: any;
  endDate: any;
  searchRange;
  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedBrand: number[] = [];
  selectedTemplate: number[] = [];
  selectedStatus: number[] = [];
  selectedm1: any;
  selectedtmm: any;
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  event = [];
  resetDropdown: boolean;
  min: number;
  max: number;
  options: Array<any> = [];
  errorMsg1: string;
  errorMsg: string;
  spinner: boolean = true;
  tmmUser: any;
  calendar_year;
  count=0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public ngxLoader: NgxUiLoaderService,
    public commonProvider: CommonProvider,
    public geoHerirarchProvider: HierarchyProvider,
    public targetProvider: LoyaltyTargetProvider,
    private hubAdminDataProvider: HubAdminDataProvider,
    private teamLeaderProvider: MenuProgramProvider,
    public datepipe: DatePipe,
    public usersService: RegionDataService,
    public globalService: GlobalService
  ) {
    this.siteUrl = this.globalService.apiHost;
  }

  @Input() filterData: FilterModel;

  ngOnChanges() {}



  getmenuwhitelistcount(region,state_code,channel_type,tse_user_id,calendar_year): void {
    this.usersService.getmenuwhitelistcount(region,state_code,channel_type,tse_user_id,calendar_year)
        .subscribe(
            (data: any) => {
              this.count = data;
              this.menuProgram[0].points = this.count
                
            },
            error => {
                console.log(error)
            }
        )
}

getmenustatusreport(start_date,end_date,region,state_code,brand_id,template_id,tse_user_id,tmm_id): void {
  this.usersService.getmenustatusreport(start_date,end_date,region,state_code,brand_id,template_id,tse_user_id,tmm_id)
      .subscribe(
          (data: any) => {
            debugger
            let all_data = data;
            let filename: string = "Menu_Report";
            let options = {
              showLabels: true,
              headers: [
               "Brand Name",
                "Template Name",
                "Region",
                "State",
                "Outlet Code",
                "Outlet Name",
                "Copies",
                "Request Raised By",
                "Timestamp",
                "TMM/TSE Review Status",
                "By TMM/M1 (Name)",
                "Comments",
                "Timestamp",
                
                "Agency Status",
                "Comments",
                "Timestamp",
                "TMM/TSE Review Status",
                "By TMM/M1 (Name)",
                "Comments",
                "Timestamp",
                "QR Agency Status",
                "Comments",
                "Timestamp",
                "TMM/TSE Review Status",
                "By TMM/M1 (Name)",
                "Comments",
                "Timestamp",
                // "No. of Page",
                // "Cost Per Page",
                // "Total Cost",
                // "Status",
                // "Updated Date",
                // "TSE Name",
                // "Assign Agent Name",        

        


              ],
            };
        
            for (let i = 0; i < all_data.length; i++) {
              let item = all_data[i];
              let dt = [null, null];
              console.log(item);
              console.log(dt);
              this._csvdata.push({
                menu_brand_name: item.menu_brand_name,
                menu_template_name: item.menu_template_name,
                region_name: item.region_name,
                state_name: item.state_name,
                ro_code: item.outlet_code,
                ro_name: item.outlet_name,
                no_of_copies: item.no_of_copies,
                request_raised_by : item.request_raised_by,
                timestamp_1 : item.timestamp_1,
                tse_tmm_status_2: item.tse_tmm_status_2,
                tse_tmm_name_2 :   item.tse_tmm_name_2,
                user_comments_2 : item.user_comments_2,
                timestamp_2 : item.timestamp_2,
                agency_status : item.agency_status,
                user_comments_3 : item.user_comments_3,
                timestamp_3 : item.timestamp_3,
                tse_tmm_status_4 : item.tse_tmm_status_4,
                tse_tmm_name_4 : item.tse_tmm_name_4,
                user_comments_4 : item.user_comments_4,
                timestamp_4 : item.timestamp_4,
                qr_agency_status : item.qr_agency_status,
                user_comments_5 : item.user_comments_5,
                timestamp_5 : item.timestamp_5,
                tse_tmm_status_6 : item.tse_tmm_status_6,
                
                tse_tmm_name_6 : item.tse_tmm_name_6,
                user_comments_6 : item.user_comments_6,
                timestamp_6 : item.timestamp_6



   
              });
            }
            new Angular2Csv(this._csvdata, filename, options);
            this.ngxLoader.stopBackground();
            this._downloadSubmitted = false;
              
          },
          error => {
              console.log(error)
          }
      )
}



  ngOnInit() {
    (this.min = 2019), (this.max = new Date().getFullYear());
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    console.log(this.options);
    this.options.reverse();
    this.getMenuTemplateRequestList();
    this.searchByStatus = "";
    this.statusDropdown = [
      // { item_id: 1, item_text: 'Pending Review By TSE' },
      { id: 2, name: "Sent to Agency" },
      { id: 3, name: "Updated by Agency" },
      { id: 4, name: "Approved" },
      { id: 9, name: "Sent for TSE/TMM Review" },
      { id: 7, name: "Sent to QR Agency" },
      { id: 8, name: "Updated by QR Agency" },
      
      
      // { item_id: 5, item_text: 'Rejected' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  
    this.calendar_year = localStorage.getItem('calendar_year')
    this.getmenuwhitelistcount(0,0,0,0,this.calendar_year)
  }
  menuProgram = [
    {
      id: 5,
      name: "Total Whitelisted",
      color: this.colors[0],
      points: 0,
    },

    {
      id: 6,
      name: "Pending Review By TSE",
      color: this.colors[1],
      points: 0,
    },
    {
      id: 1,
      name: "Sent to Agency",
      color: this.colors[0],
      points: 0,
    },
    
    {
      id: 2,
      name: "Updated by Agency",
      color: this.colors[0],
      points: 0,
    },
    {
      id: 9,
      name: "Sent for TSE/TMM Review",
      color: this.colors[3],
      points: 0,
    },
    {
      id: 7,
      name: " Sent to QR Agency",
      color: this.colors[4],
      points: 0,
    },
    {
      id: 8,
      name: "Updated by QR Agency",
      color: this.colors[5],
      points: 0,
    },
    {
      id: 3,
      name: "Approved",
      color: this.colors[6],
      points: 0,
    },
    {
      id: 4,
      name: "Total Cost",
      color: this.colors[2],
      points: 0,
    },
  ];

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    this.templates = this.teamLeaderProvider.templates;
    this.tmmUser = this.commonProvider.tmmUsers;
    this.brands = this.teamLeaderProvider.brands;

    this.dropdown.find((d) => d.id == 1).data = this.regions;
    this.dropdown.find((d) => d.id == 7).data = this.brands;
    this.dropdown.find((d) => d.id == 8).data = this.templates;
    this.dropdown.find((d) => d.id == 9).data = this.statusDropdown;
    // this.dropdown.find((d) => d.id == 1).data = this.regions;
  }

  onDateSelection(item) {
    this.teamLeaderProvider.getMenuTemplateRequestList();
    if (item != null) {
      this.ngxLoader.startBackground();
      setTimeout(() => {
        this.ngxLoader.stopBackground(); // stop foreground spinner of the master loader with 'default' taskId
      }, 1000);
      this.isSearch = true;
      this.date1 = this.datepipe.transform(item[0], "yyyy-MM-dd");
      this.date2 = this.datepipe.transform(item[1], "yyyy-MM-dd");
      this.teamLeaderProvider.getDateBasedRecords(this.date1, this.date2);
    }
  }

  searchData() {
    if (!this.isSearch) {
      this.teamLeaderProvider.searchMenuRecords(
        this.searchByBrandData,
        this.searchByTemplateData,
        this.searchByStatus,
        this.searchByRegion,
        this.searchByState
      );
    } else {
      this.teamLeaderProvider.searchMenuRecordsWithDate(
        this.searchByBrandData,
        this.searchByTemplateData,
        this.searchByStatus,
        this.date1,
        this.date2,
        this.searchByRegion,
        this.searchByState
      );
    }
  }

  moveToIndivialEntry(entries: any) {
    this.router.navigate(["/report-admin/menu-program-report/", entries.id]);
  }

  // Download Template
  private _csvdata = [];
  _downloadSubmitted: boolean;

  public downloadTemplate() {

    this.ngxLoader.startBackground();
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (this.selectedBrand === undefined || this.selectedBrand.length == 0) {
      this.m1 = "";
    }

    if (this.selectedm1 === undefined || this.selectedm1.length == 0) {
      this.selectedm1 = [];
    }
    
    if (this.selectedtmm === undefined || this.selectedtmm.length == 0) {
      this.selectedtmm = [];
    }
    if (
      this.selectedTemplate === undefined ||
      this.selectedTemplate.length == 0
    ) {
      this.m2 = "";
    }

    if (this.selectedStatus === undefined || this.selectedStatus.length == 0) {
      this.m3 = "";
    }
    this._downloadSubmitted = true;
    this._csvdata = [];
    let filename: string = "Menu_Report";
    // if (this.startdate) {
    //   filename = filename + '_' + this.startdate + '_' + this.enddate;
    // } else {
    //   filename = filename;
    // }


    this.getmenustatusreport(this.startDate,this.endDate,this.selectedRegion,this.selectedState, this.selectedBrand,this.selectedTemplate,this.selectedm1,this.selectedtmm)
    

   
  }

  //Dummy

  // Service Call
  m1FilterByRegion = [];
  m2FilterByRegion = [];
  m3FilterByRegion = [];

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    this.event = event;
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        if (event != 0) {
          event.forEach((element) => {
            let data = this.states.filter((s) => s.region_id == element.id);
            let data1 = this.m1Users.filter((s) => s.region_id == element.id);
            let data2 = this.tmmUser.filter((s) => s.region_id == element.id);
            this.StatesfilterByRegion.push(...data);
            this.m1FilterByRegion.push(...data1);

            this.m2FilterByRegion.push(...data2);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        this.dropdown.find((d) => d.id == 5).data = this.m1FilterByRegion;
        this.dropdown.find((d) => d.id == 6).data = this.m2FilterByRegion;
        break;
      case 2:
        this.state = this.getList(event, "selectedState");
        break;
        case 5:
          this.m1Users = this.getList(event, "selectedm1");
          break;
          case 6:
            this.tmmUser = this.getList(event, "selectedtmm");
            break;
      case 7:
        this.m1 = this.getList(event, "selectedBrand");
        break;
      case 8:
        this.m2 = this.getList(event, "selectedTemplate");
        break;
      case 9:
        this.m3 = this.getList(event, "selectedStatus");
        break;
    }
    // this.searchByFilter();
    // this.searchByFilter();
  }

  getList(event, mainData) {
    this[mainData] = event;
    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
      console.log(this[mainData]);
    } else {
      g = "";
    }

    return g;
  }

  getMenuTemplateRequestList(): void {
    let calendar_month = parseInt(localStorage.getItem("calendar_year"));
    this.usersService.getAllTemplateMenuRequest(calendar_month).subscribe((data) => {
      this.requests = data;
      this.allrequests = data;
      if (this.requests.length > 0) {

        this.totalCost = this.requests
        .filter((r) => r.total_cost)
        .reduce((a, b) => a + b.total_cost || 0, 0);

        this.requests.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
        this.requests.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
      }

      if (typeof this.allrequests != "undefined") {
        if (this.allrequests.length > 0) {
          this.requestSendToAgency = this.allrequests.filter(
            (r) => r.internal_status == 2
          );
          this.requestUpdatedByAgency = this.allrequests.filter(
            (r) => r.internal_status == 3
          );
          this.requestApproved = this.allrequests.filter(
            (r) => r.internal_status == 4
          );

          this.requestTreview = this.allrequests.filter(
            (r) => r.internal_status == 9
          );
            
          this.requestSendToQrAgency = this.allrequests.filter(
            (r) => r.internal_status == 7
          );

          this.requestUpdatedByQrAgency = this.allrequests.filter(
            (r) => r.internal_status == 8
          );

          debugger
          this.requestTmmreview = this.allrequests.filter(
            (r) => r.internal_status == 1
          );


            


          this.menuProgram.find(
            (i) => i.id == 6
          ).points = this.requestTmmreview.length;
          
          console.log()


          this.menuProgram.find(
            (i) => i.id == 1
          ).points = this.requestSendToAgency.length;
          

          this.menuProgram.find(
            (i) => i.id == 8
          ).points = this.requestUpdatedByQrAgency.length;

            
            
          this.menuProgram.find(
            (i) => i.id == 7
          ).points = this.requestSendToQrAgency.length;

            
          this.menuProgram.find(
            (i) => i.id == 9
          ).points = this.requestTreview.length;


          this.menuProgram.find(
            (i) => i.id == 2
          ).points = this.requestUpdatedByAgency.length;
          this.menuProgram.find(
            (i) => i.id == 3
          ).points = this.requestApproved.length;
          this.menuProgram.find((i) => i.id == 4).points = this.totalCost;
          localStorage.setItem("menuProgram", JSON.stringify(this.menuProgram));
        } else {
          this.requestSendToAgency = [];
          this.requestUpdatedByAgency = [];
          this.requestApproved = [];
          this.allrequests = [];
        }
      }
    });
  }

  searchByFilter() {
    debugger
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.resetDropdown = false;
    if (this.dateRange == []) {
      this.startDate = 0;
      this.endDate = 0;
      this.isSearch = false;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
      this.isSearch = true;
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (this.selectedBrand === undefined || this.selectedBrand.length == 0) {
      this.m1 = "";
    }

    if (this.selectedm1 === undefined || this.selectedm1.length == 0) {
      this.selectedm1 = [];
    }
    
    if (this.selectedtmm === undefined || this.selectedtmm.length == 0) {
      this.selectedtmm = [];
    }
    if (
      this.selectedTemplate === undefined ||
      this.selectedTemplate.length == 0
    ) {
      this.m2 = "";
    }

    if (this.selectedStatus === undefined || this.selectedStatus.length == 0) {
      this.m3 = "";
    }

    if (this.dateRange.length != 0) {
      if (this.dateRange.length > 0) {
        this.date1 = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
        this.date2 = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
        this.isSearch = true;
      }
    }
    else{
      this.isSearch = false;
    }
    debugger
    this.getmenuwhitelistcount(this.selectedRegion,this.selectedState,0,this.selectedm1,this.calendar_year)
    if (!this.isSearch) {
      this.searchMenuRecords(
        this.selectedBrand,
        this.selectedTemplate,
        this.selectedStatus,
        this.selectedRegion,
        this.selectedState,
        this.selectedm1,
        this.selectedtmm
      );
    } else {
      this.searchMenuRecordsWithDate(
        this.selectedBrand,
        this.selectedTemplate,
        this.selectedStatus,
        this.date1,
        this.date2,
        this.selectedRegion,
        this.selectedState,
        this.selectedm1,
        this.selectedtmm
      );
    }
  }

  getmenuwhitelistcount1(region,state_code,channel_type,tse_user_id,calendar_year): void {
    this.usersService.getmenuwhitelistcount(region,state_code,channel_type,tse_user_id,calendar_year)
        .subscribe(
            (result: any) => {
                let data = result.data.outlets;
         
                
            },
            error => {
                alert(error)
            }
        )
}

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }

  openModalWithClass(template: TemplateRef<any>, data: any) {
    this.getMenuRequestTransactions(data.id);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }

  getMenuRequestTransactions(id) {
    this.ngxLoader.start();
    this.usersService
      .getAllTemplateMenuRequestTransaction(id)
      .subscribe((data) => {
        this.requestTransactions = data;
        if (this.requestTransactions.length > 0) {
          this.requestTransactions.forEach((f) => {
            this.ngxLoader.stop();
            if (f.template_logo != null) {
              let path = f.template_logo.split("/");
              console.log(path);
              f.logo_name = path[path.length - 1];

              if (f.template_images.length > 0) {
                f.template_images.forEach((i) => {
                  let path1 = f.template_logo.split("/");
                  console.log(path1);
                  let path2 = i.menu_template_image.split("/");
                  i.folder_name = path2[path2.length - 2];
                  console.log(i.folder_name);
                  f.menu_folder_name = i.folder_name;
                  i.image_name = path1[path1.length - 1];
                });
              }
            }
          });
        }
      });
  }

  searchMenuRecords(brandId, templateName, internalStatus, region, state,m1,tmmUser) {
    this.requests = this.allrequests;

    if (brandId.length != 0) {

        this.requests = this.requests.filter((f) => {
          return brandId.includes(f.menu_brand_id);
        });
        console.log(this.requests);
      
    }

    if (templateName.length != 0) {

        this.requests = this.requests.filter((f) => {
          return templateName.includes(f.template_id);
        });
      
    }

    if (internalStatus.length != 0) {
      this.requests = this.requests.filter((f) => {
        return internalStatus.includes(f.internal_status);
      });
    }

    if (typeof region != "undefined" ) {
      if (region != null && region != "") {
        this.requests = this.requests.filter((f) => {
          return region.includes(f.region_id);
        });
      }
    }
    if ( m1.length != 0) {
        this.requests = this.requests.filter((f) => {
          return m1.includes(f.tse_user_id);
        });
    }

    if ( tmmUser.length != 0) {
      this.requests = this.requests.filter((f) => {
        return tmmUser.includes(f.tmm_id);
      });
  }
    if (state.length != 0) {
        this.requests = this.requests.filter((f) => {
          return state.includes(f.state_id);
        });
    }

    if (typeof this.requests != "undefined") {
      if (this.requests.length > 0) {
        this.requestSendToAgency = this.requests.filter(
          (r) => r.internal_status == 2
        );
        this.requestUpdatedByAgency = this.requests.filter(
          (r) => r.internal_status == 3
        );
        this.requestApproved = this.requests.filter(
          (r) => r.internal_status == 4
        );
        this.menuProgram.find(
          (i) => i.id == 1
        ).points = this.requestSendToAgency.length;
        this.menuProgram.find(
          (i) => i.id == 2
        ).points = this.requestUpdatedByAgency.length;
        this.menuProgram.find(
          (i) => i.id == 3
        ).points = this.requestApproved.length;
        this.menuProgram.find((i) => i.id == 4).points =
        this.totalCost = this.requests
        .filter((r) => r.total_cost)
        .reduce((a, b) => a + b.total_cost || 0, 0);
        localStorage.setItem("menuProgram", JSON.stringify(this.menuProgram));
      } else {
        this.menuProgram.find(
          (i) => i.id == 1
        ).points = 0;
        this.menuProgram.find(
          (i) => i.id == 2
        ).points = 0;
        this.menuProgram.find(
          (i) => i.id == 3
        ).points = 0;
        this.menuProgram.find((i) => i.id == 4).points = 0;
        localStorage.setItem("menuProgram", JSON.stringify(this.menuProgram));
      }
    }
  }

  searchMenuRecordsWithDate(
    brandId,
    templateName,
    internalStatus,
    date1,
    date2,
    region,
    state,
    m1,
    tmmUser
  ) {
    
    this.requests = this.allrequests;
    let tempDate = new Date(date2);
    console.log("here");
    date2 = tempDate.setDate(tempDate.getDate() + 1);

    date2 = new Date(date2)

    if (date2 != undefined ||date2 != "" ) {
      this.requests = this.requests.filter((m) => {
        return (
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
        );
      });
    }
  
      if (brandId.length != 0) {
        this.requests = this.requests.filter((m) => {
          return (
            brandId.includes(m.menu_brand_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      }
    

    if (templateName.length != 0) {
        this.requests = this.requests.filter((m) => {
          return (
            templateName.includes(m.template_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
    }

    if (internalStatus.length != 0) {
      this.requests = this.requests.filter((m) => {
        return (
          internalStatus.includes(m.internal_status) &&
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
        );
      });
    }
    if (m1.length != 0) {
      this.requests = this.requests.filter((m) => {
        return (
          m1.includes(m.tse_user_id) &&
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
        );
      });

    }

    if (tmmUser.length != 0) {
      this.requests = this.requests.filter((m) => {
        return (
          tmmUser.includes(m.tmm_id) &&
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
        );
      });
    }


    if (region.length != 0) {

        this.requests = this.requests.filter((m) => {
          return (
            region.includes(m.region_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      
    }


    
    if (state.length != 0) {
 
        this.requests = this.requests.filter((m) => {
          return (
            state.includes(m.state_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      
    }

    if (typeof this.requests != "undefined") {
      if (this.requests.length > 0) {
        this.requestSendToAgency = this.requests.filter(
          (r) => r.internal_status == 2
        );
        this.requestUpdatedByAgency = this.requests.filter(
          (r) => r.internal_status == 3
        );
        this.requestApproved = this.requests.filter(
          (r) => r.internal_status == 4
        );
        this.menuProgram.find(
          (i) => i.id == 1
        ).points = this.requestSendToAgency.length;
        this.menuProgram.find(
          (i) => i.id == 2
        ).points = this.requestUpdatedByAgency.length;
        this.menuProgram.find(
          (i) => i.id == 3
        ).points = this.requestApproved.length;
        this.menuProgram.find((i) => i.id == 4).points =
        this.totalCost = this.requests
        .filter((r) => r.total_cost)
        .reduce((a, b) => a + b.total_cost || 0, 0);
        localStorage.setItem("menuProgram", JSON.stringify(this.menuProgram));
      } else {
        this.menuProgram.find(
          (i) => i.id == 1
        ).points = 0;
        this.menuProgram.find(
          (i) => i.id == 2
        ).points = 0;
        this.menuProgram.find(
          (i) => i.id == 3
        ).points = 0;
        this.menuProgram.find((i) => i.id == 4).points = 0;
        localStorage.setItem("menuProgram", JSON.stringify(this.menuProgram));
      }
    }
  }

  getDateBasedRecords(date1, date2) {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.requests = this.requests.filter(
      (m) =>
        new Date(m.request_timestamp) >= new Date(date1) &&
        new Date(m.request_timestamp) <= date2
    );
  }
}
