import { RewardSettingsModel } from './RewardSettingsModel';
import { CampaignActivities } from './campaign-activites';
import { QuestionAnswerModel } from 'src/app/admin/dashboard/mysteryshopper/entry.component';


export class CampaignNewModel {

    id: number;
    campaign_id : number;
    campaign_name : string;
    start_date : string;
    end_date : string;
    status : number;
    activity_start_date : string; 
    activity_end_date : string;
    activity_title : string;
    activity_instruction : string;
    enable_wof : boolean;
    wof_title : string;
    trigger_name : string;
    set_trigger : any;
    user_role_id : number;
    region_id : number;
    sequence_id : number;
    frequency : number;
    module_id : string;
    enable_wof_criteria: any;
    modules: any[];
    quiz_activity : any[];
    reward_data :any[];
    selectedModule : TaskModuleModel[];
    task_data : TasksModel[];
    quiz_data : TasksModel[];
    image_data : TasksModel[];
}


export class CampaignModel {
    id: number;
    campaign_name: string;
    program_id: number;
    start_date: string;
    end_date: string;
    user_role_id: number;
    geography_id: number;
    active_hrs_start: string;
    active_hrs_end: any;
    duration_days: number;
    terms: string;
    status: number;
    created_date: Date;
    updated_date: Date;
    announcement: string;
    results: string;
    quizChecked: boolean;
    imageChecked: boolean;
    eventChecked : boolean;
    campaign_date_range: string;
    CampaignEngagement: CampaignEngagementModel;
    CampaignRewardSettings: RewardSettingsModel[];
    campaignActiveState: boolean;
    campaignStatus: string;
    gameType: boolean;
    successMsgBoolean: boolean;
    failureMsgBoolean: boolean;
    successSmsBoolean: boolean;
    failureSmsBoolean: boolean;
    successNotificationBoolean: boolean;
    failureNotificationBoolean: boolean;
    setCampaignHold: boolean;
    moduleId: number;
    CampaignActivity: CampaignActivites;
    CampaignRoleAccess: CampaignRoleAccess;
    Reward: RewardModel[];
    campign_hold: number;
    CampaignReward : CampaignQuizModel[];
    QuestionsOptions : QuestionAnswer[];
    CampaignQuizModel : CampaignQuizModel[];
    quiz : any;
    notDisabled : boolean;
    tasks : TasksModel[];
    userTask : TasksModel[];
    selectedModule : TaskModuleModel[] = [];
}

export class TaskModuleModel {
    
    task_module_id : number;
    task_module_name: string;
    checked : boolean;
    count : number;
    tasks : TasksModel[];
}

export class CampaignEngagementModel {
    id: number;
    engagement_title: string;
    activity_type: number;
    start_date: string;
    end_date: string;
    winner_select_logic: number;
    trigger_id: number;
    frequency: number;
    sucess_msg: string;
    success_img: string;
    fail_msg: string;
    fail_img: string;
    created_date: string;
    updated_date: string;
    campaign_id: number;
}

export class CampaignRoleAccess {
    id: number;
    campaign_id: number;
    user_role_id: number;
    geographical_id: number;
    tier_id: number;
    created_date: string;
    program_id: number;
    region_id : number;
}

export class CampaignActivites {
    id: number;
    campaign_id: number;
    activity_type: number;
    start_date: string;
    end_date: string;
    activity_title: string;
    activity_icon: string;
    activity_desc: string;
    reward: string;
    points: number;
    sequence_id: string;
    frequency: number;
    activity_per_day: number;
    days: number;
    trigger_name: string;
    set_trigger: number;
    success_msg: string;
    success_notify: string;
    success_sms: string;
    fail_msg: string;
    fail_notify: string;
    fail_sms: string;
    created_date: string;
    updated_date: string;
    enable_wof: boolean;
    enable_wof_criteria: string;
    program_id: number;
    status: number;
    success_pop_image: any;
    failure_pop_image: any;
    module_id: number;
    wof_title: string;
    instruction : string;
    realted_module : boolean;
    module_trans_id : number;
}


export class RewardModel {
    id: number;
    reward_type_id: number;
    program_id: number;
    campaign_id: number;
    reward_name: string;
    status: number;
    created_at: string;
    updated_at: string;
    reward_color: string;
}


export class CampaignQuizModel {
    id: number;
    campaign_id: number;
    campaign_activities_config_id: number;
    question: string;
    question_type: number;
    quiz_type: number;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    option5: string;
    no_of_points: number;
    time_in_seconds: number;
    display_date: string;
    display_time: string;
    created_date: string;
    updated_date: string;
    answer: string;
    is_subtask: boolean;
    program_id: number;
    is_answer_check: boolean;
    instruction : string;
    options : options[];
    activity_title : string;
}

export class options {
    option : boolean;
    answer : boolean;
    optionText : string;
    answerPoints : number;
    question : number;
    addAnswer : boolean;
}

export class QuestionAnswer {
    options : options [];
    totalPoints : number;
    questionType : number;
    questionName: string;
}

export class Questionoptions {
    option : boolean;
    answer : number;
    optionText : string;
    answerPoints : number;
    question : number;
    addAnswer : boolean;
    optionImage: string;
}

export class CourseQuestionAnswer {
    activity_name : string;
    options : Questionoptions [];
    totalPoints : number;
    questionType : number;
    quizType : number;
    questionName: string;
}


export class RewardForm {
	denomination : string;
	color : string;
} 


export class Question {
    id: number;
    name: string;
    questionTypeId: number;
    options: Option[];

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.name = data.name;
        this.questionTypeId = data.questionTypeId;
        this.options = [];
        data.options.forEach(o => {
            this.options.push(new Option(o));
        });
    }
}

export class Option {
    id: number;
    questionId: number;
    name: string;
    isAnswer: boolean;
    selected: boolean;
    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.questionId = data.questionId;
        this.name = data.name;
        this.isAnswer = data.isAnswer;
    }
}

export class TasksModel {
  id : number;
  campaign_id : number;
  task_type : number;
  starttimeslot : string;
  endtimeslot : string;
  has_subtask : boolean;
  subtask_type : number;
  title : string;
  subtitle : string;
  description : string;
  help : string;
  success_points : number;
  success_msg : string;
  failure_msg : string;
  status : number;
  created_date : string;
  updated_date : string;
  user_role_id : number;
  task_question_id : number;
  subtask_question_id : number;
  default_lang : string;
  program_id : number;
  timeslotdate : string;
  instruction : string;
  end_date : string;
  task_frequency : number;
  is_information : number;
  redirect_url : string;
  task_rules : string;
  campaign_activities_config_id : number;
  realted_module : boolean;
  module_id : number;
  module_trans_id : number;
  language_tasks : LanguageTaskModel[] = []; 
  lang_task : LanguageTaskModel[];
  questions : QuestionsModel[]
}


export class LanguageTaskModel {
    language_id : number;
    language_short_name : string;
    task_title : string;
    task_instruction : string;
    language_name : string;
}


export class Questions {
    language_id : number;
    langauage_name : string;
    question : string;
    answer : string;
}

export class CampaignTaskDetailsModel {
    language_id : string;
    task_name : string;
    instruction : string;
    language_name : string;
}



export class QuestionsModel {
    questions : Questions[];
    points : number;
}

