import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { RegionModel, ReportDataModel } from "src/app/model/contract/channel";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import {
  DAILY_REPORT,
  MONTH,
  MONTH_DROPDOWN_DETAILS,
} from "../../Models/general-headings.model";
import { CommonHttpServiceService } from "../../Services/common-http-service.service";
import { ExportExcelService } from "../../Services/export-excel.service";
import { GeneralService } from "../../Services/general.service";

@Component({
  selector: "app-monthly-report",
  templateUrl: "./monthly-report.component.html",
  styleUrls: ["./monthly-report.component.scss"],
})
export class MonthlyReportComponent implements OnInit {
  public form: FormGroup;
  submittedCampaign: boolean;
  minDate: Date;
  maxDate: Date;
  startdate: string;
  enddate: string;
  reports: any[];
  weekOrMonth: any[];
  showWeek: boolean = false;
  showDate: boolean;
  flag: any;
  dateRange: any;
  dropdown: any[] = MONTH_DROPDOWN_DETAILS;
  resetDropdown: boolean;
  projectSettings: any[] = DAILY_REPORT;
  regions: RegionModel[];
  min: number;
  max: number;
  years: any[] = [];
  selectedRegion: number;
  selectedMonth: number;
  selectedYear: string;
  selectedReport: number;
  months: any[] = MONTH;
  data1: ReportDataModel[] = [];
  targetExcelLink : any = "";

  constructor(
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private excel: ExportExcelService
  ) {
    if (
      parseInt(localStorage.getItem("calendar_year")) ==
      new Date().getFullYear()
    ) {
      this.minDate = new Date(
        parseInt(localStorage.getItem("calendar_year")),
        0
      );
      this.maxDate = new Date();
    } else {
      this.minDate = new Date(
        parseInt(localStorage.getItem("calendar_year")),
        0
      );
      this.maxDate = new Date(
        parseInt(localStorage.getItem("calendar_year")),
        11,
        31
      );
    }
  }

  ngOnInit() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);
    (this.min = 2019), (this.max = new Date().getFullYear());
    if (typeof parseInt(localStorage.getItem("calendar_year")) != "undefined") {
      this.min = parseInt(localStorage.getItem("calendar_year"));
      this.max = parseInt(localStorage.getItem("calendar_year"));
    }
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      this.years.push({ id: i, name: i });
    }
    console.log(this.years);
  }

  data: any[] = [];
  isLink: boolean;
  errorMessage: any;

  searchByFilter() {
    this.errorMessage = "";
    this.data = [];
    this.isLink = false;
    if (typeof this.selectedMonth == "undefined")
      this.dropdown.find((i) => i.id == 2).errorStatus = true;
    if (typeof this.selectedYear == "undefined")
      this.dropdown.find((i) => i.id == 3).errorStatus = true;
    if (typeof this.selectedReport == "undefined")
      this.dropdown.find((i) => i.id == 4).errorStatus = true;
    if (typeof this.selectedRegion == "undefined")
      (this.selectedRegion = null), (this.selectedRegionName = "All Region");
    if (
      typeof this.selectedMonth != "undefined" &&
      typeof this.selectedYear != "undefined" &&
      typeof this.selectedReport != "undefined"
    ) {
      let calendar_year = parseInt(localStorage.getItem("calendar_year"));
      console.log("selected report",this.selectedReport)
      debugger
      // if(this.selectedReport != 2){
        
        this.httpClient
          .getMonthlyReports(
            this.selectedMonth,
            this.selectedYear,
            this.selectedRegion,
            this.selectedReport,
            calendar_year
          )
          .subscribe(
            (res: any) => {
              debugger
              
              if (res.success) {
                this.data1 = [];
                if (res.data.flag == "excel") {
                  this.isLink = false;
                  if (res.data.report_data != undefined || res.data.report_data.length > 0) {
                    if (this.selectedReport == 1) {
                      this.excel.exportRedmiptionExcel(
                        res.data.report_data,
                        this.selectedRegionName,
                        this.selectedMonthName,
                        this.selectedYearName
                      );
                    } else if (this.selectedReport == 2) {
                      this.excel.exportTargetAndAchivementExcel(
                        res.data.report_data,
                        this.selectedRegionName,
                        this.selectedMonthName,
                        this.selectedYearName
                      );
                    }
                  } else {
                    this.toastr.error(`No Data Found`, "Monthly Report", {
                      timeOut: 10000,
                      closeButton: true,
                      positionClass: "toast-top-center",
                    });
                  }
                } else if (res.data.flag == "grid") {
                  if (res.data.report_batch_data.length == 0) {
                    this.data = res.data.message;
                    this.isLink = res.data.is_link;
                  } else {
                    this.data1 = res.data.report_batch_data;
                    this.data1.forEach((element) => {
                      let data = element.report_data.split('/');
                      element.report_name = data[data.length - 1];
                    })
                  }
                } else {
                  this.data = res.data.message;
                  this.isLink = res.data.is_link;
                }
              }
            },
            (error) => {
              // unauthorized access
              if (error.status == 401 || error.status == 403) {
              } else {
                // this.alertService.error(error.data.message);
                this.toastr.error(`Internal Server Error`, "Dashboard", {
                  timeOut: 10000,
                  closeButton: true,
                  positionClass: "toast-bottom-right",
                });
              }
            }
          );
      // }else if(this.selectedReport == 2){
      //   console.log('target and achevement')
      //   if(this.selectedRegion == null){
      //     this.toastr.error(`please select all filter`, "Region", {
      //       timeOut: 10000,
      //       closeButton: true,
      //       positionClass: "toast-top-center",
      //     });
      //     return
      //   }
      //   this.httpClient
      //     .downloadMonthlyTargetAndAchievementReports(
      //       this.selectedMonth,
      //       this.selectedYear,
      //       this.selectedRegion,
      //       this.selectedReport,
      //       calendar_year
      //     )
      //     .subscribe(
      //       (res: any) => {
      //         debugger
              
      //         if (res.success) {
      //           if(res.data.link != ''){
      //             this.targetExcelLink = res.data.link;
      //             setTimeout(() => {
      //                  document.getElementById('targetExcel').click();
      //             }, 500);
      //             console.log(res)
      //           }else{
      //             this.toastr.error(`please generate the excel`, "", {
      //               timeOut: 10000,
      //               closeButton: true,
      //               positionClass: "toast-top-center",
      //             });
      //           }
      //         }
      //       })

      // }
    }
  }

  generateTragetExcel(){
    this.errorMessage = "";
    this.data = [];
    this.isLink = false;
    if (typeof this.selectedMonth == "undefined")
      this.dropdown.find((i) => i.id == 2).errorStatus = true;
    if (typeof this.selectedYear == "undefined")
      this.dropdown.find((i) => i.id == 3).errorStatus = true;
    if (typeof this.selectedReport == "undefined")
      this.dropdown.find((i) => i.id == 4).errorStatus = true;
    if (typeof this.selectedRegion == "undefined")
      (this.selectedRegion = null), (this.selectedRegionName = "All Region");
    if (
      typeof this.selectedMonth != "undefined" &&
      typeof this.selectedYear != "undefined" &&
      typeof this.selectedReport != "undefined"
    ) {
      let calendar_year = parseInt(localStorage.getItem("calendar_year"));
      console.log("selected report",this.selectedReport)
      
        console.log('target and achevement')
        if(this.selectedRegion == null){
          this.toastr.error(`please select all filter`, "Region", {
            timeOut: 10000,
            closeButton: true,
            positionClass: "toast-top-center",
          });
          return
        }
        this.httpClient
          .getMonthlyTargetAndAchievementReports(
            this.selectedMonth,
            this.selectedYear,
            this.selectedRegion,
            this.selectedReport,
            calendar_year
          )
          .subscribe(
            (res: any) => {
              debugger
              
              if (res.success) {
                if(res.data.link !=''){
                  this.toastr.success(`Generate sucessfully`, "Excel", {
                    timeOut: 10000,
                    closeButton: true,
                    positionClass: "toast-top-center",
                  });
                }
                console.log(res)
              }
            })

      
    }
  }

  ngDoCheck() {
    this.regions = this.commonProvider.regionList;
    this.reports = this.commonProvider.reportLists;
    if (typeof this.reports != "undefined") {
      if (this.reports.length > 0) {
        this.reports = this.reports.filter((r) => r.is_frequency == 2);
      }
    }
    this.dropdown.find((d) => d.id == 1).data = this.regions;
    this.dropdown.find((d) => d.id == 2).data = this.months;
    this.dropdown.find((d) => d.id == 3).data = this.years;
    this.dropdown.find((d) => d.id == 4).data = this.reports;
  }

  resetFilter() {
    this.resetDropdown = true;
  }

  selectedRegionName: string;

  selectedMonthName: string;

  selectedYearName: string;

  selectedValueCheck(event, filter): void {
    switch (filter) {
      case 1:
        this.selectedRegion = undefined;
        if (event.length > 0) {
          this.selectedRegion = event.find((i) => i.id).id;
          this.selectedRegionName = event.find((i) => i.name).name;
        }
        break;
      case 2:
        this.selectedMonth = undefined;
        if (event.length > 0) {
          this.selectedMonth = event.find((i) => i.id).id;
          this.selectedMonthName = event.find((i) => i.name).name;
          this.dropdown.find((i) => i.id == 2).errorStatus = false;
          break;
        }
      case 3:
        this.selectedYear = undefined;
        if (event.length > 0) {
          this.selectedYear = event.find((i) => i.id).id;
          this.selectedYearName = event.find((i) => i.name).name;
          this.dropdown.find((i) => i.id == 3).errorStatus = false;
          break;
        }
      case 4:
        this.selectedReport = undefined;
        if (event.length > 0) {
          this.selectedReport = event.find((i) => i.id).id;
          this.dropdown.find((i) => i.id == 4).errorStatus = false;
          break;
        }
    }
    console.log("general:", event, filter);
  }
}


