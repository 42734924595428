import "rxjs/add/operator/catch";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, retry } from "rxjs/operators";
import { GlobalService } from "src/app/model/global.service";
import { StaffService } from "src/app/model/staff.service";
import { User } from "src/app/model/contract/user";
import { GENERAL_REPORT } from "../Models/general.mock-data";
import { of } from "rxjs/internal/observable/of";
import { VisiblityBrand } from "../Models/visiblity-brand.mock-data";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  program_id: string;
  calendar_year: any;

  constructor(
    private globalService: GlobalService,
    private staffService: StaffService,
    private http: HttpClient
  ) {
    this.program_id = localStorage.getItem("program_id");
    this.calendar_year = parseInt(localStorage.getItem("calendar_year"));
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.staffService.getToken(),
    });
  }

  private getURLList() {
    return this.globalService.apiHost + "/hub-admin";
  }

  private getURLList1() {
    return this.globalService.apiHost + "/adminreport";
  }

  getGeneralReport(
    startdate,
    enddate,
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id
  ): Observable<User[]> {
    // let headers = this.getHeaders();
    // let apiUrl = this.getURLList1() + '/generalreports?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    // return this.http.get(apiUrl, { headers: headers })
    //   .pipe(
    //     catchError(this.handleError)
    //   )
    //   .map(
    //     response => {
    //       return <any[]>response;
    //     }
    //   )
    return of(GENERAL_REPORT);
  }

  getVisibilityBrand(
    startdate,
    enddate,
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id,
    month,
    year,
    calendar_year
  ): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl =
      this.getURLList1() +
      "/visibilitybranding?program_id=" +
      this.program_id +
      "&startdate=" +
      startdate +
      "&enddate=" +
      enddate +
      "&region=" +
      region +
      "&state_code=" +
      state +
      "&channel_type=" +
      channel +
      "&tse_user_id=" +
      tse_user_id +
      "&m2_user_id=" +
      m2_user_id +
      "&m3_user_id=" +
      m3_user_id +
      "&month=" +
      month +
      "&year=" +
      year +
      "&calendar_year=" +
      calendar_year;
    return this.http
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  getContentLearning(
    startdate,
    enddate,
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id
  ): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl =
      this.getURLList1() +
      "/contentandlearning?program_id=" +
      this.program_id +
      "&startdate=" +
      startdate +
      "&enddate=" +
      enddate +
      "&region=" +
      region +
      "&state_code=" +
      state +
      "&channel_type=" +
      channel +
      "&tse_user_id=" +
      tse_user_id +
      "&m2_user_id=" +
      m2_user_id +
      "&m3_user_id=" +
      m3_user_id;
    return this.http
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  // Handling Errors

  private handleError(response: any) {
    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: "Sorry, there was a connection error occurred. Please try again.",
      };
    } else {
      errorMessage = response.error;
    }
    return throwError(errorMessage);
  }
}
