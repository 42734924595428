import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-white-tile',
  templateUrl: './white-tile.component.html',
  styleUrls: ['./white-tile.component.scss']
})
export class WhiteTileComponent implements OnInit {

  @Input() data : any;

  constructor() { }
  ngOnInit() {
    console.log(this.data)
  }


}
