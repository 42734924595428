import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { P404Component } from './page/404.component';
export const routes: Routes = [
  // otherwise redirect to home
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'logout', loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule) },
  // { path: 'admin', loadChildren: ()=> import('./admin/admin.module').then(a => a.AdminModule)},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: P404Component },
];

export const routing = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules
});


