import { Component, OnInit, Output, OnChanges, Input, EventEmitter, Renderer2, TemplateRef } from '@angular/core';
import { StaffService } from '../../model/staff.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';

@Component({
  selector: 'app-backend',
  templateUrl: './backend-layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class BackendLayoutComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  modalRef: BsModalRef;
  public userData: any = {};
  public today: Date;
  public menuOpen: boolean = false;
  parameters: any;
  searchArea: any;
  showFilter : boolean = true;
  selectedItem : boolean = true;
  queryParam : any = {}; 
  geoHierarchyActive: boolean;
  settingsActive: boolean = false;
  currentRouter : any;
  username: string;
  constructor(public staffService: StaffService, 
    public activatedRoute: ActivatedRoute, 
    public router: Router,
    public routerDataProvider: RouteDataProvider,
    private renderer: Renderer2,
    private _location: Location,
    private modalService: BsModalService) {
    this.username = localStorage.getItem('user_name');
    this.today = new Date();
    this.routerDataProvider.subArea.subscribe(
      m => {
        this.currentRouter = m;
      }
    )
    
  }

  ngOnInit(): void {
    const jwtValue: any = this.staffService.getJWTValue();
    if (jwtValue !== null) {
      this.userData = jwtValue.data;
    }
    
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }
  
  public toggled(open:boolean):void {
    
  }
  
  clickFilter(value):void{
    this.routerDataProvider.filter(value);
  }

  sidebarClick(event){
    console.log(event);
  }

  backClicked() {
    console.log('here');
    this._location.back();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
