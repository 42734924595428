import { Component, OnInit } from '@angular/core';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { UserDataService } from 'src/app/model/user-data.service';
import { ToastrService } from 'ngx-toastr';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { DatePipe } from '@angular/common';
import { RegionDataService } from 'src/app/model/region-data.service';
import { RegionModel, ChannelModel, FrequencyModel } from 'src/app/model/contract/channel';
import { StateModel } from 'src/app/model/contract/menu-program';
import { Router } from '@angular/router';

import { Angular2Csv } from 'angular2-csv';
import { COLOR_TITLE, DROPDOWN_DETAILS } from '../Models/general-headings.model';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { WhitleTile } from '../Models/tile_data.model';

@Component({
  selector: 'app-mysteryshopper',
  templateUrl: './mysteryshopper.component.html',
  styleUrls: ['./mysteryshopper.component.scss']
})
export class MysteryshopperComponent implements OnInit {
  region: number;
  // selectedChannel: number;
  state: number;
  selectedRole: number;
  tse_user_id: number;
  m2_user_id: number;
  m3_user_id: number;
  tseusers: any;
  m2users: any;
  m3users: any;
  private _form: FormGroup;
  Total_Payout_name: string;
  Total_PayoutGroup:any;
  percentage1: any;
  percentage2: any;
  percentage3: any;
  tot_budget: any;
  tot_volume_payout: any;
  colors: any[] = COLOR_TITLE;
  percentageGroup:any;
  GapsGroup:any;
  //
  TotalStats: any;

  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];

  dropdown: any[] = DROPDOWN_DETAILS;
  // Dropdown Selected Values;
  selectedRegion: number;
  selectedState: number;
  selectedChannel: number;
  selectedM1: number;
  selectedM2: number;
  selectedM3: number;

  constructor(
    private hubAdminDataProvider: HubAdminDataProvider,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private users: UserDataService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    public settingDataService: SettingDataService,
    public datepipe: DatePipe,
    public usersService: RegionDataService,
    private router: Router,
    private commonProvider:CommonProvider
  ) {

    this._form = this._formBuilder.group({
      date: ['',],
      region: ['',],
      state: ['',],
      channel: ['',],
      role: ['',],
      tse_user_id: ['',],
      m2_user_id: ['',],
      m3_user_id: ['',]
    });

  }

  data1: any[];
  dataBase: any[] = [];


  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;

    this.dropdown.find(d => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find(d => d.id == 3).data = this.channels;
    this.dropdown.find(d => d.id == 4).data = this.m1Users;
    this.dropdown.find(d => d.id == 5).data = this.m2Users;
    this.dropdown.find(d => d.id == 6).data = this.m3Users;
  }
  ngOnInit() {

    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop();
    }, 1000);

    this.getRegions();
    this.getStateList();
    this.getChannels();
    this.getUsers();
    this.region = 0;
    this.selectedChannel = 0;
    this.state = 0;
    this.selectedRole = 0;
    this.tse_user_id = 0;
    this.m2_user_id = 0;
    this.m3_user_id = 0;
    this.getReport(0, 0, 0, 0, 0, 0, 1)
  }

  startDate: any = 0;
  endDate: any = 0;
  round  = 0;

  onSearch(date: any, region: any, state: any, channel: any, tse_user_id, m2_user_id, m3_user_id, round) {
    this.getReport(region, state, channel, tse_user_id, m2_user_id, m3_user_id, round);
  }

  enrolled_vs_audited: any[];
  question_percentage: any[];
  tot_points_group: any[];
  mysteryTransaction: MysteryTransactionModel[];
  groupEarnedTotal: any;
  groupTotal: any;
  MYSTERYSHOPPER_data = [];
  async getReport(region, state, channel, tse_user_id, m2_user_id, m3_user_id, round) {
    this.ngxLoader.start();
    let calendar_year = parseInt(localStorage.getItem("calendar_year"));
    // await this.users.getMysteryReport(region, state, channel, tse_user_id, m2_user_id, m3_user_id, round,)
    //   .subscribe(
    //     (result: any) => {
    //       this.ngxLoader.stop();
    //       this.tot_points_group = result.data.tot_points_group;
    //       this.mysteryTransaction = result.data.mystery_transaction;
    //       this.tot_points_group = result.data.tot_points_group;
    //       let i = 0;
    //       this.groupEarnedTotal = 0;
    //       this.groupTotal = 0;
    //       this.tot_budget = result.data.tot_budget;
    //       this.tot_volume_payout = result.data.tot_volume_payout;
    //       this.tot_points_group.forEach(
    //         d => {
    //           if (i != this.tot_points_group.length - 1) {
    //             this.groupEarnedTotal += d.earned_points
    //             this.groupTotal = d.total_points
    //           }
    //           i++;
    //         }
    //       );
    //       this.Total_Payout_name = this.tot_points_group.map(m => m.group_name).join(' + ');
    //       this.formattingData(result.data);
    //       result.data.mysteryshopper.forEach(element => {
    //         let data: WhitleTile = new WhitleTile;
    //         data.data = element.value;
    //         data.name = element.name;
    //         (data.name.includes("%")) ? data.type = '%': '';
    //         (data.name.includes("INR")) ? data.data = '₹ ' + data.data: '';
    //         this.MYSTERYSHOPPER_data.push(data);
    //       });
    //     }
    //   )
  }

  payoutdetails = [
    {
      name: 'Total payout available',
      data: '750000'
    },
    {
      name: 'Total Actual Payout (INR)',
      data: '675000'
    },
    {
      name: 'Total Execution Payout Available available',
      data: '550000'
    },
    {
      name: 'Total Actual Execution Payout (INR)',
      data: '750000'
    },
    {
      name: 'Total Recommended Action Payout Available',
      data: '-'
    },
    {
      name: 'Total Actual Recommendation Payout (INR)',
      data: '3000'
    },
    {
      name: 'Total Volume Payout Available (INR)',
      data: '3200000'
    },
    {
      name: 'Total Actual Volume Payout (INR)',
      data: '100000'
    },
  ]

  outletAchivement = [
    {
      name: "Number of outlets that scored above 80%",
      data: "65%"
    },
    {
      name: "Number of outlets that scored above 50%-80%",
      data: "70%"
    },
    {
      name: "Number of outlets that scored above 50%",
      data: "35%"
    }
  ]

  formattingData(details){
    let audited =[{
      name: "Number of Planned Audits",
      color:this.colors[0],
      points:details.enrolled_vs_audited[0].assigned_total_count
    },
    {
      name: "Number of Audits completed",
      color:this.colors[1],
      points:details.enrolled_vs_audited[0].completed_total_count
    }]
    this.enrolled_vs_audited = audited;
    let Avarage = [];
    this.question_percentage = Avarage;
    this.enrolled_vs_audited = [...this.enrolled_vs_audited, ...this.question_percentage];
    let percent = [{
      name:"Number of outlets that recorded above 80%",
      data:details.data_80_percentage[0].percentage+" %",
      color: this.colors[0]
    },{
      name:"Number of outlets that recorded above 50-80%",
      data:details.data_80_50_percentage[0].percentage+" %",
      color: this.colors[1]
    },{
      name:"Number of outlets that score less than 50%",
      data:details.data_50_percentage[0].percentage+" %",
      color: this.colors[2]
    }]
    this.percentageGroup = percent;
    let Total_Payout = [
      {
        name:'Total '+this.Total_Payout_name+' Payout (INR)',
        data:this.groupEarnedTotal,
        color:this.colors[0]
      },{
        name:'Total Volume Payout (INR)',
        data:this.tot_volume_payout,
        color:this.colors[1]
      },{
        name:'Total Budget (INR)',
        data:this.tot_budget,
        color:this.colors[2]
      },
    ]
    this.Total_PayoutGroup = Total_Payout;
    this.percentageGroup = [...this.percentageGroup, ...this.Total_PayoutGroup]
    let Gaps = [
      {
        name:'Number of Gaps Recorded',
        points:'0%',
        color:this.colors[0]
      },
      {
        name:'Number of Gaps Resolved',
        points:"0%",
        color:this.colors[1]
      }
    ]
    let totalStats = [
      {
        name:'Total Recommendation Entries',
        points:'1800',
        color:this.colors[0]
      },
      {
        name:'Total Recommendation Entries Marked Successful',
        points:"900",
        color:this.colors[1]
      },
      {
        name:'Total Volume Target',
        points:'100000',
        color:this.colors[2]
      },
      {
        name:'Total Volume Achievement',
        points:"5000",
        color:this.colors[0]
      }
    ]
    this.GapsGroup = Gaps;
    this.TotalStats = totalStats;
    console.log(Total_Payout);
  }

  regionList: RegionModel[];

  getRegions(): void {
    this.settingDataService.getAllRegion()
      .subscribe(
        (result: any) => {
          this.regionList = result.data.regions;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  onChangeRegion(item) {
    this.selectedStates = this.states.filter(s => s.region_id == item);
  }

  // states: StateModel[];
  selectedStates: StateModel[];
  getStateList(): void {
    this.usersService.getAllStates().subscribe(
      (data) => {
        this.states = data;
      }
    )
  }

  onReset() {
    this.ngOnInit();
  }

  selectChannel() {

  }

  channelList: ChannelModel[];

  getChannels(): void {
    this.settingDataService.getAllChannel()
      .subscribe(
        (result: any) => {
          this.channelList = result.data.channels;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getUsers(): void {
    this.users.getUsers()
      .subscribe(
        (result: any) => {
          this.tseusers = result.data.tseusers;
          this.m2users = result.data.m2users;
          this.m3users = result.data.m3users;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }


  moveToIndivialEntry(entries: any) {
    this.router.navigate(['/report-admin/mystery-shopper/', entries.assignment_id])
  }



  // Download Template
  private _csvdata = [];
  _downloadSubmitted: boolean;

  public downloadTemplate() {
    this.ngxLoader.startBackground();
    this._downloadSubmitted = true;
    this._csvdata = [];
    let filename: string = 'Mystery_Audit_Report';
    // if (this.startdate) {
    //   filename = filename + '_' + this.startdate + '_' + this.enddate;
    // } else {
    //   filename = filename;
    // }
    let options = {
      showLabels: true,
      headers: ['Outlet Code',
        'Outlet Name',
        'TSE',
        'TSE Number',
        'M2',
        'M2 Number',
        'M3',
        'M3 Number',
        'Audit Date',
        'Total Score',
        'Total Payout',
        'Gaps To Close',
        'Assigment ID',
        'Audit Name',
        'Channel'
      ]
    };

    for (let i = 0; i < this.mysteryTransaction.length; i++) {
      let item = this.mysteryTransaction[i];
      let dt = [null, null];
      console.log(item)
      console.log(dt);
      this._csvdata.push({
        ro_code: item.ro_code,
        ro_name: item.ro_name,
        tse_name: item.tse_name,
        tse_mobile: item.tse_mobile,
        m2_name: item.m2_name,
        m2_mobile: item.m2_mobile,
        m3_name: item.m3_name,
        m3_mobile: item.m3_mobile,
        completed_on: item.completed_on,
        total_cost: item.total_points,
        earned_points: item.earned_points,
        record_gap: item.record_gap,
        assignment_id: item.assignment_id,
        audit_name: item.audit_name,
        channel: item.channel
      });
    }
    new Angular2Csv(this._csvdata, filename, options);
    this.ngxLoader.stopBackground();
    this._downloadSubmitted = false;
  }
}

export class MysteryTransactionModel {
  assignment_id: number;
  outlet_user_id: number;
  audit_name: string;
  completed_on: string;
  total_points: number;
  earned_points: number;
  tse_mobile: string;
  ro_code: number;
  tse_name: string;
  ro_name: string;
  channel: string;
  m2_mobile: string;
  m2_name: string;
  m3_mobile: string;
  m3_name: string;
  record_gap : string;
}
