import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-dropdown',
  templateUrl: './common-dropdown.component.html',
  styleUrls: ['./common-dropdown.component.scss']
})
export class CommonDropdownComponent implements OnInit {
  @Input() selecDetails: any;
  @Input() multiple: any;
  @Input() dropdownName: any;
  @Input() reset: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  selectedValue: any;
  constructor() {

  }

  ngOnInit() {

    debugger
    console.log(this.selecDetails)
    this.selectedValue = 0;
    console.log(this.selecDetails)
  }

  valueChanged(value): void {

  }

  onItemDeSelect() {
    this.notify.emit(this.selectedValue);
  }

  ngOnChanges() {
    if(this.reset == true) {
      this.selectedValue = [];
    }
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    enableCheckAll:false,
    limitSelection:500,
    
  
  };

onItemSelect(item: any) {
  debugger
    console.log(this.selectedValue);
  if (typeof this.selectedValue.find(i => i.id).id != "undefined") {
    this.notify.emit(this.selectedValue);
  }
}
}
