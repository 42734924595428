import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RegionModel } from 'src/app/model/contract/channel';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { DAILY_REPORT, FIREBASE_DROPDOWN_DETAILS, MONTH, MONTH_DROPDOWN_DETAILS } from '../../Models/general-headings.model';
import { CommonHttpServiceService } from '../../Services/common-http-service.service';
import { ExportExcelService } from '../../Services/export-excel.service';
import { GeneralService } from '../../Services/general.service';
import * as moment from 'moment';
@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent implements OnInit {

  public form: FormGroup;
  submittedCampaign: boolean;
  minDate: Date;
  maxDate: Date;
  startdate: string;
  enddate: string;
  reports: any[];
  weekOrMonth: any[];
  showWeek: boolean = false;
  showDate: boolean;
  flag: any;
  dateRange : any;
  dropdown: any[] = FIREBASE_DROPDOWN_DETAILS;
  resetDropdown: boolean;
  projectSettings: any[] = DAILY_REPORT;
  regions: RegionModel[];
  min: number;
  max: number;
  years : any[] = [];
  selectedRegion : number;
  selectedMonth : number;
  selectedYear : string;
  selectedReport : number;
  months : any[] = MONTH;
  show : boolean = false;
  constructor(public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private toastr: ToastrService,
    private excel : ExportExcelService) {
      if(parseInt(localStorage.getItem("calendar_year")) == new Date().getFullYear()) {
        this.minDate = new Date(parseInt(localStorage.getItem("calendar_year")), 0 );
        this.maxDate = new Date();
      }
      else{
          this.minDate = new Date(parseInt(localStorage.getItem("calendar_year")), 0 );
          this.maxDate = new Date(parseInt(localStorage.getItem("calendar_year")), 11, 31);
      }
    }

  ngOnInit() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.ngxLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);
    (this.min = 2019), (this.max = new Date().getFullYear());
    if (typeof parseInt(localStorage.getItem("calendar_year")) != "undefined") {
      this.min = parseInt(localStorage.getItem("calendar_year"));
      this.max = parseInt(localStorage.getItem("calendar_year"));
    }
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      this.years.push({ id: i, name: i });
    }

    console.log(this.years);
  }

  data    : any;
  isLink  : boolean;
  date    : any;
  hide    : boolean = false;
  errorMessage : any;

  searchByFilter() {
    // if(typeof this.selectedMonth == "undefined") this.dropdown.find(i => i.id == 2).errorStatus = true;
    // if(typeof this.selectedYear == "undefined") this.dropdown.find(i => i.id == 3).errorStatus = true;
    if(typeof this.selectedReport == "undefined") this.dropdown.find(i => i.id == 4).errorStatus = true;
    if(typeof this.selectedRegion == "undefined") (this.selectedRegion = null),this.selectedRegionName ="All Region";

    if(typeof this.selectedRegion == "undefined") {
      this.selectedRegion = null;
    }
    if(typeof this.selectedMonth == "undefined") {
      this.selectedMonth = null;
    }
    if(typeof this.selectedYear == "undefined") {
      this.selectedYear = null;
    }
    if(typeof this.selectedReport != "undefined") {
    if(this.selectedReport == 5) {
      if(this.dateRange != undefined) {
        this.date = moment(this.dateRange).format("YYYY-MM-DD");
      }
      else{
        this.date = null;
        this.errorMessage = "Please select the date"
        return;
      }
    }
    else{
      this.date = null;
    }

    this.ngxLoader.startBackground();
      this.httpClient.getFireBaseReports(this.selectedMonth,
        this.selectedYear,
        this.selectedRegion,
        this.selectedReport, this.date, null)
        .subscribe((res: any) => {
        if(res.success) {
          this.ngxLoader.stopBackground();
          if(res.data.length > 0) {
            if(this.selectedReport == 5) {
              this.excel.DAUTimeSpentExcel(res.data, this.selectedRegionName, this.selectedMonth, this.selectedYear);
            }
            else if(this.selectedReport == 6) {
              this.excel.EnagementTimeSpentExcel(res.data, this.selectedRegionName, this.selectedMonth, this.selectedYear);
            }
            else if(this.selectedReport == 7) {
              this.excel.MAUTimeSpentExcel(res.data, this.selectedRegionName, this.selectedMonth, this.selectedYear);
            }
            else if(this.selectedReport == 8) {
              this.excel.ModuleTimeSpentExcel(res.data, this.selectedRegionName, this.selectedMonth, this.selectedYear);
            }
            else if(this.selectedReport == 9) {
              this.excel.SchemeTimeSpentExcel(res.data, this.selectedRegionName, this.selectedMonth, this.selectedYear);
            }
          }
          else{
            this.toastr.error(`No Data Found`,
              'Firebase Report', {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-top-center",
            });
          }
        }
      },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
    }
  }

  ngDoCheck() {
    this.regions = this.commonProvider.regionList;
    this.reports = this.commonProvider.reportLists;
    if(typeof this.reports !="undefined") {
      if(this.reports.length > 0) {
        this.reports = this.reports.filter(r => r.is_frequency == 4);
      }
    }
    this.dropdown.find(d => d.id == 1).data = this.regions;
    this.dropdown.find(d => d.id == 2).data = this.months;
    this.dropdown.find(d => d.id == 3).data = this.years;
    this.dropdown.find(d => d.id == 4).data = this.reports;
    this.dropdown.find(d => d.id == 4).show = true;
  }

  resetFilter() {
    this.resetDropdown = true;
  }

  selectedRegionName:string;

  selectedMonthName:string;

  selectedYearName:string;

  selectedValueCheck(event, filter): void {
    switch (filter) {
        case 1:
          this.selectedRegion = event.find(i => i.id).id;
          this.selectedRegionName = event.find(i => i.name).name;
          break;
        case 2:
          this.selectedMonth = event.find(i => i.id).id;
          this.selectedMonthName = event.find(i => i.name).name;
          this.dropdown.find(i => i.id == 2).errorStatus = false;
          break;
        case 3:
          this.selectedYear = event.find(i => i.id).id;
          this.selectedYearName = event.find(i => i.name).name;
          this.dropdown.find(i => i.id == 3).errorStatus = false;
          break;
        case 4:
          if(event.length > 0) {
            this.selectedReport = event.find(i => i.id).id;
            this.dropdown.find(i => i.id == 4).errorStatus = false;
            if(this.selectedReport != 5) {
              this.show = false;
              this.dropdown.find(d => d.id == 1).show = true;
              this.dropdown.find(d => d.id == 2).show = true;
              this.dropdown.find(d => d.id == 3).show = true;
            }
            else{
              this.show = true;
              this.dropdown.find(d => d.id == 1).show = false;
              this.dropdown.find(d => d.id == 2).show = false;
              this.dropdown.find(d => d.id == 3).show = false;
              // this.dropdown.find(i => i.id == 1).errorStatus = false;
              // this.dropdown.find(i => i.id == 2).errorStatus = false;
              // this.dropdown.find(i => i.id == 3).errorStatus = false;
            }
          }
          else{
            this.show = false;
            this.dropdown.find(d => d.id == 1).show = false;
            this.dropdown.find(d => d.id == 2).show = false;
            this.dropdown.find(d => d.id == 3).show = false;
            // this.dropdown.find(i => i.id == 1).errorStatus = false;
            // this.dropdown.find(i => i.id == 2).errorStatus = false;
            // this.dropdown.find(i => i.id == 3).errorStatus = false;
          }
          break;
    }
    console.log("general:", event, filter);
  }

}
