import { EventData, EventType } from "src/app/model/sharedModel";
import { Injectable } from "@angular/core";
import { MysteryShopperProvider } from "src/app/admin/Providers/MysteryShopperProvider";
import { AreaDataProvider } from "../RouterProvider/AreaDataProviders";
import { RouteDataProvider } from "../RouterProvider/RouteDataProvider.provider";
import { CampaignProvider } from "../Shared/CampaignProvider";
import { UserRoleProvider } from "../Shared/UserRoleProvider";
import { HierarchyProvider } from "../Shared/HierarchyProvider";
import { CommonProvider } from "../Shared/CommonProvider";
import { LoyaltySchemeProvider } from "src/app/admin/Providers/LoyaltySchemeProvider";
import { LoyaltyTargetProvider } from "src/app/admin/Providers/LoyaltyTargetProvider";
import { VisibilityProvider } from "src/app/admin/Providers/VisibilityProvider";
import { ContentLearningProvider } from "src/app/admin/Providers/ContentLearningProvider";
import { LeaderboardProvider } from "src/app/admin/Providers/LeaderboardProvider";
import { MenuProgramProvider } from "src/app/admin/Providers/MenuProgramProvider";

@Injectable()
export class HubAdminDataProvider extends AreaDataProvider {
  constructor(
    public routerDataProvider: RouteDataProvider,
    public mysteryShopperProvider: MysteryShopperProvider,
    public campaignProvider: CampaignProvider,
    public userRoleProvider: UserRoleProvider,
    public geoProvider: HierarchyProvider,
    public hierarchyProvider: HierarchyProvider,
    public commonProvider: CommonProvider,
    public loyaltySchemeProvider: LoyaltySchemeProvider,
    public loyaltyTargetProvider: LoyaltyTargetProvider,
    public visiblityProvider: VisibilityProvider,
    public contentLearningProvider: ContentLearningProvider,
    public leaderboardProvider: LeaderboardProvider,
    public menuProvider: MenuProgramProvider
  ) {
    super("settings", routerDataProvider);
  }

  areaChanged(newArea: string, firstCall: boolean) {
    if (firstCall) {
      return;
    }
  }

  subAreaChanged(newSubArea: string, firstCall: boolean) {
    if (firstCall || newSubArea == this.currentAreaName) {
      return;
    }
    this.eventHandler({ type: EventType.subAreaChanged });
  }

  queryParamChanged(newQueryParams: any, firstCall: boolean) {
    if (firstCall) {
      return;
    }
    if (newQueryParams.id !== undefined) {
      // console.log(newQueryParams);/
      this.eventHandler({ type: EventType.queryParamChanged });
    } else {
      this.eventHandler({ type: EventType.areaChanged });
    }
  }

  urlMaker(data: any): string {
    return "";
  }

  eventHandler(eventData: EventData) {
    switch (eventData.type) {
      case EventType.queryParamChanged:
        // console.log('Query Param Changed', this.currentQueryParam);
        break;
      case EventType.areaChanged:
        // console.log('Query Param Changed', this.currentAreaName);
        switch (this.currentAreaName) {
          case "report-admin":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            this.commonProvider.getStaticReports();
            break;
          case "mystery-admin":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
          case "region-admin":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
          case "m1-admin":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
        }
        break;
      case EventType.subAreaChanged:
        // console.log('Sub Area Changed', this.currentSubAreaName);
        this.commonProvider.getUserRoles();
        switch (this.currentSubAreaName) {
          case "add-mystery-shopper":
            this.mysteryShopperProvider.getMysteryShopperList();
            this.mysteryShopperProvider.getUserRoles();
            // this.campaignProvider.getCampaigns();
            this.geoProvider.getGeoHierarchyList();
            break;
          case "campaign":
            this.campaignProvider.getCampaigns();
            this.userRoleProvider.getUserRoles();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.geoProvider.getGeoHierarchyList();
            this.contentLearningProvider.getCourseList();
            this.campaignProvider.getCampaignTasks();
            break;
          case "geo-hierarchy":
            this.hierarchyProvider.getGeoHierarchyList();
            // this.commonProvider.getUsersList();
            break;
          case "profile-hierarchy":
            this.commonProvider.getUserRoles();
            // this.commonProvider.getUsersList();
            break;
          case "add-user":
            //this.commonProvider.getUsersList();
            break;
          case "outlet-hierarchy":
            this.hierarchyProvider.getChannelTypeList();
            // this.commonProvider.getUsersList();
            break;
          case "profile-hierarchy":
            this.hierarchyProvider.getUserProfileList();
            // this.commonProvider.getUsersList();
            break;
          case "schemes-offers":
            this.commonProvider.getChannels();
            // this.commonProvider.getFrequencies();
            this.loyaltySchemeProvider.getSchemeList();
            this.geoProvider.getGeoHierarchyList();
            // this.commonProvider.getUnits();
            // this.commonProvider.getBrandCategories();
            break;
          case "whitelist-outlets":
            // console.log('here')
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.geoProvider.getGeoHierarchyList();
            this.commonProvider.getUnits();
            this.commonProvider.getBrandCategories();
            this.loyaltyTargetProvider.getTarget();
            this.commonProvider.getRegions();
            break;
          case "crown":
            // console.log('in crown')
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.geoProvider.getGeoHierarchyList();
            this.loyaltyTargetProvider.getCrownTarget();
            break;
          case "menu-program":
            // console.log('in Menu')
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.geoProvider.getGeoHierarchyList();
            this.loyaltyTargetProvider.getMenuProgram();
            break;
          case "carton-insert":
            // console.log('in carton-insert')
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.geoProvider.getGeoHierarchyList();
            this.commonProvider.getRegions();
            this.loyaltyTargetProvider.getCartonInsert();
            break;
          case "assign-mystery-shopper":
            this.commonProvider.getChannels();
            this.geoProvider.getGeoHierarchyList();
            this.commonProvider.getRegions();
            this.mysteryShopperProvider.getMysteryShopperAssignment();
            this.mysteryShopperProvider.getMysteryShopperList();
            break;
          case "mystery-outlet-whitelist":
            this.mysteryShopperProvider.getMysterCampaignList();
            break;
          case "mystery-audit-template":
            this.mysteryShopperProvider.getMysteryTemplates();
            this.commonProvider.getChannels();
            this.commonProvider.getRegions();
            this.mysteryShopperProvider.getMysteryTemplateQuestions();
            break;
          case "posm":
            this.visiblityProvider.getAuditList();
            this.visiblityProvider.getPosmConfigDataList();
            this.commonProvider.getChannels();
            this.geoProvider.getGeoHierarchyList();
            this.commonProvider.getFrequencies();
            break;
          case "content-learning":
            this.contentLearningProvider.getCourseList();
            this.commonProvider.getChannels();
            this.commonProvider.getRegions();
            break;
          case "communication":
            this.commonProvider.getChannels();
            this.geoProvider.getGeoHierarchyList();
            this.commonProvider.getFrequencies();
            this.commonProvider.getAllModule();
            this.commonProvider.getMessageTrigger();
            this.visiblityProvider.getAllCommunication();
            break;
          case "trigger":
            this.commonProvider.getAllModule();
            this.commonProvider.getMessageTrigger();
            this.visiblityProvider.getAllCommunication();
            this.commonProvider.getUserRoles();
            this.geoProvider.getGeoHierarchyList();
          case "leaderboard":
            this.commonProvider.getFrequencies();
            this.geoProvider.getGeoHierarchyList();
            this.leaderboardProvider.getLeaderboards();
            this.userRoleProvider.getUserRoles();
            break;
          case "menu-program-overview":
            this.menuProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getRegions();
            break;
          case "menu-brand-template":
            this.menuProvider.getMenuTemplateList();
            this.menuProvider.getBrandList();
            this.commonProvider.getChannels();
            this.menuProvider.getAllMenuBrandChannel();
            break;
          case "menu-program-config":
            this.menuProvider.getMenuTemplateConfig();
            this.menuProvider.getMenuTemplateList();
            this.menuProvider.getBrandList();
            break;
          case "qc-report":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
          case "engagement":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
          case "Crown-Collection":
            this.commonProvider.getChannels();
            this.commonProvider.getFrequencies();
            this.commonProvider.getRegions();
            this.commonProvider.getUsers();
            this.commonProvider.getStateList();
            this.menuProvider.getTemplateList();
            this.menuProvider.getBrandList();
            this.menuProvider.getMenuTemplateRequestList();
            this.commonProvider.getBrandCategories();
            break;
        }
        break;
    }
  }
}
