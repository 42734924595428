import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseBody } from './contract/response-body';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { UserRoleModel } from './contract/userroles';

@Injectable()

export class UserRoleService {

  constructor(private globalService:GlobalService, 
    private staffService:StaffService,
    private http:HttpClient) { }

    private getHeaders(){
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.staffService.getToken()
      })
    }

    private getURLList(){
      return this.globalService.apiHost  + '/admin';
    }

    getUserRoleList():Observable<UserRoleModel>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/roles';
      return this.http.get<UserRoleModel>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <UserRoleModel>response;
        }
      )
    }

       //POST /v1/admin
  getSearchResult(searchValue:any):Observable<UserRoleModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchuserroles';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <UserRoleModel[]>response;
      }
    )
  }

  getUserRoleById(id:number):Observable<UserRoleModel>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/userrolesbyid?id='+id;
      return this.http.get<UserRoleModel>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <UserRoleModel>response;
        }
      )
  }

  addUserRoleData(userRole:UserRoleModel):Observable<ResponseBody>{
    let headers = this.getHeaders();
    let body    = JSON.stringify(userRole);
    let apiUrl     = this.getURLList() + '/adduserrole';
    return this.http.post<ResponseBody>(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <ResponseBody>response;
      }
    )
  }

  updateUserRoleData(id:number,userRole:UserRoleModel):Observable<ResponseBody>{
    let headers = this.getHeaders();
    let body    = JSON.stringify(userRole);
    let apiUrl     = this.getURLList() + '/updateuserrole?id='+id;
    return this.http.post<ResponseBody>(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <ResponseBody>response;
      }
    )
  }

    private handleError(response: any) {

      let errorMessage: any = {};
      // Connection error
      if (response.error.status === 0) {
        errorMessage = {
          success: false,
          status: 0,
          data: 'Sorry, there was a connection error occurred. Please try again.'
        };
      } else {
        errorMessage = response.error;
      }
  
      return throwError(errorMessage);
    }
}
