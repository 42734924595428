import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeoHierarchyModel } from 'src/app/model/contract/program';
import { GeoHierachyDataService } from 'src/app/model/geo-hierachy-data.service';
import { RouteDataProvider } from '../RouterProvider/RouteDataProvider.provider';
import { StaffDataService } from 'src/app/model/staff-data.service';
import { AlertService } from '../RouterProvider/AlertProvider';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { ChannelTypeModel, UserProfileModel } from 'src/app/model/contract/user';

@Injectable({
  providedIn: 'root',
})
export class HierarchyProvider {

  geoHierarchyList: GeoHierarchyModel[];
  geoParentChild: any[];
  submitted: boolean;
  options = {
    idKey: 'value',
    parentKey: 'parent_id'
  }

  channelDataList: ChannelTypeModel[];
  userProfileList: UserProfileModel[];

  constructor(private routeDataProvider: RouteDataProvider,
    private staffDataService: StaffDataService,
    private router: Router,
    public geohierarchyService: GeoHierachyDataService,
    public alertService: AlertService,
    public settingService: SettingDataService) {
  }


  getGeoHierarchyList(): void {
    this.geohierarchyService.getGeoHierarchyList()
      .subscribe(
        (result: any) => {
          this.geoHierarchyList = result.data;
          this.geoHierarchyList.forEach(e => {
            e.checked = false;
            e.itemName = e.text
          });
          if (this.geoHierarchyList.length > 0) {
            this.geoParentChild = this.createTree(this.options, this.geoHierarchyList)
            this.geoParentChild.forEach(ele => {
              ele.itemName = ele.text;
              ele.checked = false;
          });
          }
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  addGeoHierarchy(geo): void {
    this.geohierarchyService.addGeoHierarchyData(geo)
      .subscribe(
        result => {
          if (result.success) {
            this.router.navigate(['/admin/geo-hierarchy']);
            this.getGeoHierarchyList();
          }
        },
        error => {
          this.submitted = false;
          this.alertService.error(error);
        }
      )
  }

  getChannelTypeList(): void {
    this.settingService.getAllChannelType()
    .subscribe(
      (response: any) => {
        this.channelDataList = response.data.channels;
      },
      error => {
        this.alertService.error(error);
      }
    )
  }

  getUserProfileList(): void {
    let program_id = 1;
    this.settingService.getUserProfiles(program_id)
    .subscribe(
      (response: any) => {
        this.userProfileList = response.data.userProfileData;
      },
      error => {
        this.alertService.error(error);
      }
    )
  }

  createTree(options: any, data: any) {
    options = options || {};
    var ID_KEY = options.idKey || 'value';
    var PARENT_KEY = options.parentKey || 'parent_id';
    var CHILDREN_KEY = options.childrenKey || 'children';

    var tree = [],
      childrenOf = {};
    var item, id, parentId;

    for (var i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    };
    // console.log(tree);
    //let tempNodes: NgOrganizationChartNodeModel[] = tree as NgOrganizationChartNodeModel[];
    // console.log(tempNodes);
    return tree;
  }


}