import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-br',
  templateUrl: './progress-br.component.html',
  styleUrls: ['./progress-br.component.scss']
})
export class ProgressBrComponent implements OnInit {

  @Input() width : any;

  constructor() { }

  ngOnInit() {
  }

}
