import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { GlobalService } from './global.service';
import { User, MysteryCampaignModel, WhitelistUsersDateRangeModel } from './contract/user';
import { StaffService } from './staff.service';
import { ResponseBody } from './contract/response-body';
import { SharedService } from '../shared/shared.service';
import { UserList } from './contract/user-list';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry } from 'rxjs/operators';
import { MysterShopperData } from '../services/mystery-shopper-data';

@Injectable()
export class UserDataService extends MysterShopperData {
  program_id: string;
  calendar_year:any;
  constructor(

    private globalService: GlobalService,
    private staffService: StaffService,
    private http: HttpClient
  ) {
    super();
    this.program_id = localStorage.getItem('program_id');
    this.calendar_year= parseInt(localStorage.getItem("calendar_year"));
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.staffService.getToken()
    });
  }

  public static getStatusTypes(): Array<any> {
    return [
      {
        label: 'Active',
        value: 10
      },
      {
        label: 'Disabled',
        value: 0
      }
    ];
  }

  // DELETE /v1/user/1
  deleteUserById(id: number): Observable<any> {
    let headers = this.getHeaders();

    return this.http
      .delete<ResponseBody>(
        this.getURLList() + '/' + id,
        {
          headers: headers
        }
      )
      .map((response) => {
        return response;
      })
      .catch(this.handleError);
  }

  // PUT /v1/user/1
  updateUserById(id: number, user: User): Observable<ResponseBody> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updateuser?id=' + id;
    let body = JSON.stringify(user);
    return this.http.put<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  // GET /v1/user
  getAllUsers(): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getusers';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  // GET /v1/user/1
  getUserById(id: number): Observable<User> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/userbyid?id=' + id;
    return this.http.get<User>(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }


  private handleError(response: any) {
    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }
    return throwError(errorMessage);
  }

  private getURLList() {
    return this.globalService.apiHost + '/hub-admin';
  }

  private getURLList1() {
    return this.globalService.apiHost + '/admin';
  }

  //POST /v1/admin
  getSearchResult(searchValue: any): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/searchuser';
    let body = JSON.stringify({ 'search_text': searchValue });
    return this.http.post(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  /* Excel Report */
  getRewardReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/rewardredemptionreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getSmsReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/totalsmssent?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getTotPointsReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/totalpointsissued?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getTotIVRReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/totalivrcalls?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }


  getTotPosmReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/posmauditdatareport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getTotMenuReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/menurequestandstatusreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getTotEngagementReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/totalengagementreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }


  getTotLoginReport(startdate, enddate, flag): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/logindatareport?startdate=' + startdate + '&enddate=' + enddate + '&flag=' + flag;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getTotMysteryReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/mysteryauditreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }


  getTotCrowncollectionauditReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/crowncollectionaudit?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getLandReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/learninganddevelopmentreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getCrownTotReport(startdate, enddate): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/pointsacrossoutletcrownreport?startdate=' + startdate + '&enddate=' + enddate;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  /* End Excel Report*/

  getGeneralReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/generalreports?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getKingServiceReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/kingservicereports?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getVisiblityReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/visibility?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  qcpaneldata(): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/qcpaneldata';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }


  getEngagmentReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/engagementsreports?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }


  getUsers(): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/getallusers';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getCrownReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/crowncollectionreports?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  private getURLList2() {
    return this.globalService.apiHost + '/adminreport';
  }

  getMysteryReport(region, state, channel, tse_user_id, m2_user_id, m3_user_id, round,month,year, calendar_year): Observable<User[]> {
    this.calendar_year= parseInt(localStorage.getItem("calendar_year"));
    let headers = this.getHeaders();
    let apiUrl = this.getURLList2() + '/mysteryshopper?program_id='+this.program_id+'&region=' + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id + "&round="+ round+ "&month="+ month+ "&year="+ year +
    "&calendar_year=" +
    calendar_year;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getMysteryReportByOutlet(outlet_user_id: any): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/mysterydetailbyid?outlet_user_id=' + outlet_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getLearningReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/learningandevelopment?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getRewardDashboardReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList1() + '/rewards?startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  // POST /v1/staff/addprogram
  addUser(user: User): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/adduser';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addExcelData(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmysteryshopper';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  MysterShopperCampaign(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmysterycampaign';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateMysterCampaign(target: WhitelistUsersDateRangeModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/mysterywhitelist';
    let body = JSON.stringify(target);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  getwhitelistdata(id: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/mysterywhitelistdata?id=' + id;
    return this.http.get<ResponseBody>(apiUrl, { headers: headers })
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addMysteryAssignedExcelData(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/assignmysteryshopper';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addMysteryTemplateData(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmysteryquestions';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  getMysteryShopperAssinedList(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/mysteryassignmentlist';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getChannelListData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getallchannel';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  getMysteryTemplateData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getmysteryquestions';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  addMysteryShopperUser(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmysteryuser';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateMysteryShopperUser(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatemysteryshopper';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  updateMysteryAssignment(user: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatemysteryassignment';
    let body = JSON.stringify(user);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addMysteryTemplate(template: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addtemplate';
    let body = JSON.stringify(template);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  getMysteryTemplates(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getmysterytemplates';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  updateMysteryTemplate(template: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/updatetemplate';
    let body = JSON.stringify(template);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  addMysteryQuestionariesForm(template: any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/addmysteryquestionsform';
    let body = JSON.stringify(template);
    return this.http.post<ResponseBody>(apiUrl, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return response;
        }
      )
  }

  getAllMysteryShopper(id: number): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getmysteryshoppers?program_id=' + id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  getAllMysteryShopperCamapign(): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getmysterycampaign';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  getAllMysteryShopperOutlets(campaign_id): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/getmysteryoutlets?campaign_id=' + campaign_id;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  getAllMysteryShopperCampaignOutlets(): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/mysterycampaignoutlets';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }

  AssignMysteryShopperCampaignOutlets(records, user, end_date, start_date, campaign_id): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/assignmysterycampaignoutlets';
    let body = {
      'assigned_user_id': user,
      'records': records,
      'end_date': end_date,
      'start_date': start_date,
      'campaign_id': campaign_id
    };
    return this.http.post(apiUrl, JSON.stringify(body), { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  editMysteryShopperCampaignOutlets(id, record): Observable<any[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/editmysterycampaignoutlets';
    let body = {
      'assigned_id': id,
      'record': record,
    };
    return this.http.post(apiUrl, JSON.stringify(body), { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any[]>response;
        }
      )
  }

  // GET /v1/user
  getAllHubAdminUsers(currentPage): Observable<User[]> {
    let headers = this.getHeaders();
    let apiUrl = this.getURLList() + '/gethubadminusers?currentPage=' + currentPage;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <User[]>response;
        }
      )
  }
}
