import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { StaffService } from './staff.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseBody } from './contract/response-body';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CampaignModel } from "./contract/campaign";
import { CampaignActivation } from './contract/campaign-activation';
import { CampaignEnrollments } from './contract/campaign-enrollment';
import { CampaignEngagement } from './contract/campaign-engagement';
import { CampaignActivities } from './contract/campaign-activites';
import { CampaignModulesModel } from './contract/campaign-modules';
import { CampaignRoleAccessModel } from './contract/campaign-role-access';
import { CampaignQuizConfig } from './contract/campaign-quiz-config';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CampaignDataService {

  constructor(private globalService:GlobalService,
    private staffService:StaffService,
    private http:HttpClient) { }

    private getHeaders(){
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.staffService.getToken()
      })
    }

    private getURLList(){
      return this.globalService.apiHost  + '/admin';
    }

    private getHubURLList(){
      return this.globalService.apiHost  + '/hub-admin';
    }

    getCampaignList():Observable<CampaignModel>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaigns';
      return this.http.get<CampaignModel>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignModel>response;
        }
      )
    }

    getCampaignFullDetails():Observable<CampaignModel>{
      let headers = this.getHeaders();
      let apiUrl     = this.getHubURLList() + '/campaigndata';
      return this.http.get<CampaignModel>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignModel>response;
        }
      )
    }

    getCampaignRewardList(id: number):Observable<any>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignsreward?campaign_id=' + id;
      return this.http.get<any>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any>response.data;
        }
      )
    }

    getCampaignTask():Observable<any>{
      let headers = this.getHeaders();
      let apiUrl     = this.getHubURLList() + '/gettaskcampaign';
      return this.http.get<any>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any>response.data;
        }
      )
    }



    getCampaignQuizDataList(id: number):Observable<any>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignquiz?campaign_id='+id;
      return this.http.get<any>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <any>response.data;
        }
      )
    }

    addCampaign(campaign:CampaignModel):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(campaign);
      let apiUrl     = this.getHubURLList() + '/addcampaign';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError),
        map(
          response => {
            return <ResponseBody>response;
          }
        )
      )

    }

    UpdateCampaign(campaign:CampaignModel):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(campaign);
      let apiUrl     = this.getHubURLList() + '/updatecampaign';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    UpdateCampaignStatus(campaign:CampaignModel):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(campaign);
      let apiUrl     = this.getHubURLList() + '/updatecampaignstatus';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }



    getCampaignById(id:number):Observable<CampaignModel>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignbyid?id='+id;
      return this.http.get<CampaignModel>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignModel>response;
        }
      )
    }

    getCampaignActivationList():Observable<CampaignActivation>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignactivation';
      return this.http.get<CampaignActivation>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivation>response;
        }
      )
    }

    getCampaignEnrollmentList():Observable<CampaignEnrollments>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignenrollment';
      return this.http.get<CampaignEnrollments>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignEnrollments>response;
        }
      )
    }

    getCampaignEngagementList():Observable<CampaignEnrollments>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignengagements';
      return this.http.get<CampaignEnrollments>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignEnrollments>response;
        }
      )
    }

    getCampaignActivityList():Observable<CampaignEnrollments>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignactivities';
      return this.http.get<CampaignEnrollments>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignEnrollments>response;
        }
      )
    }

    getCampaignQuizList():Observable<CampaignQuizConfig>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignquizactivation';
      return this.http.get<CampaignQuizConfig>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignQuizConfig>response;
        }
      )
    }

    addActivation(activation:CampaignActivation):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(activation);
      let apiUrl     = this.getURLList() + '/addcampaignactivation';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    addEngagement(engagement:CampaignEngagement):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(engagement);
      let apiUrl     = this.getURLList() + '/addcampaignengagement';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    addCampaignModules(modules:CampaignModulesModel):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(modules);
      let apiUrl     = this.getURLList() + '/addcampaignmodules';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    addEnrolment(enrolment:CampaignEnrollments):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(enrolment);
      let apiUrl     = this.getURLList() + '/addcampaignenrolment';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    addActivites(activites:CampaignActivities):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(activites);
      let apiUrl     = this.getURLList() + '/addcampaignactivites';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    addCampaignRoleAccess(roleAccess:CampaignRoleAccessModel):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(roleAccess);
      let apiUrl     = this.getURLList() + '/addcampaignroleaccess';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }


    addCampaignQuiz(quiz:CampaignQuizConfig):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let body    = JSON.stringify(quiz);
      let apiUrl     = this.getURLList() + '/addcampaignquizactivity';
      return this.http.post<ResponseBody>(apiUrl,body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    getActivationById(id:number):Observable<CampaignActivation>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignactivationbyid?id='+id;
      return this.http.get<CampaignActivation>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivation>response;
        }
      )
    }
    getEnrolmentId(id:number):Observable<CampaignActivation>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignenrolmentbyid?id='+id;
      return this.http.get<CampaignActivation>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivation>response;
        }
      )
    }
    getModuleById(id:number):Observable<CampaignActivation>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignmodulebyid?id='+id;
      return this.http.get<CampaignActivation>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivation>response;
        }
      )
    }

    getEnagementById(id:number):Observable<CampaignEngagement>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignengagementbyid?id='+id;
      return this.http.get<CampaignEngagement>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignEngagement>response;
        }
      )
    }

    getActivityById(id:number):Observable<CampaignActivities>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignactivitybyid?id='+id;
      return this.http.get<CampaignActivities>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivities>response;
        }
      )
    }

    getRoleAccessById(id:number):Observable<CampaignActivities>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignroleaccessbyid?id='+id;
      return this.http.get<CampaignActivities>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignActivities>response;
        }
      )
    }

    getCampaignQuizActivityById(id:number):Observable<CampaignQuizConfig>{
      let headers = this.getHeaders();
      let apiUrl     = this.getURLList() + '/campaignquizactivitybyid?id='+id;
      return this.http.get<CampaignQuizConfig>(apiUrl, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <CampaignQuizConfig>response;
        }
      )
    }

    updateCampaignActivation(activation:CampaignActivation, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignactivation?id='+ id;
      let body    = JSON.stringify(activation);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    updateCampaignEnrolment(enrollment:CampaignEnrollments, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignenrolment?id='+ id;
      let body    = JSON.stringify(enrollment);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    updateCampaignEngagement(engagement:CampaignEngagement, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignengagement?id='+ id;
      let body    = JSON.stringify(engagement);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    updateCampaignModules(module:CampaignModulesModel, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignmodule?id='+ id;
      let body    = JSON.stringify(module);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    updateCampaignActivity(activity:CampaignActivities, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignactivity?id='+ id;
      let body    = JSON.stringify(activity);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }
    updateCampaignRoleAccess(roleAccess:CampaignRoleAccessModel, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignroleaccess?id='+ id;
      let body    = JSON.stringify(roleAccess);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    updateCampaignQuizActivity(quiz:CampaignQuizConfig, id: Number):Observable<ResponseBody>{
      let headers = this.getHeaders();
      let apiUrl  = this.getURLList() + '/updatecampaignquizactivity?id='+ id;
      let body    = JSON.stringify(quiz);
      return this.http.put<ResponseBody>(apiUrl, body, {headers:headers})
      .pipe(
        catchError(this.handleError)
      )
      .map(
        response => {
          return <ResponseBody>response;
        }
      )
    }

    //POST /v1/admin
  getSearchResult(searchValue:any):Observable<CampaignModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchcampaign';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignModel[]>response;
      }
    )
  }



  getSearchEnrolmentResult(searchValue:any):Observable<CampaignEnrollments[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchenrolment';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignEnrollments[]>response;
      }
    )
  }

  getSearchEngagementResult(searchValue:any):Observable<CampaignEngagement[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchengagement';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignEngagement[]>response;
      }
    )
  }

  //POST /v1/admin
  getSearchActivationResult(searchValue:any):Observable<CampaignModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchactivation';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignModel[]>response;
      }
    )
  }

  getSearchActivityResult(searchValue:any):Observable<CampaignModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/searchactivites';
    let body    = JSON.stringify({'search_text': searchValue});
    return this.http.post(apiUrl, body, {headers:headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignModel[]>response;
      }
    )
  }

  getCampaignModules():Observable<CampaignModulesModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/campaignmodules';
    return this.http.get(apiUrl, {headers: headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignModulesModel[]>response;
      }
    )
  }

  getCampaignRoleAcess():Observable<CampaignRoleAccessModel[]>{
    let headers = this.getHeaders();
    let apiUrl  = this.getURLList() + '/campaignroleaccess';
    return this.http.get(apiUrl, {headers: headers})
    .pipe(
      catchError(this.handleError)
    )
    .map(
      response => {
        return <CampaignRoleAccessModel[]>response;
      }
    )
  }




  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
