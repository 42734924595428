import { Component, OnInit, ɵConsole ,TemplateRef,Input } from '@angular/core';
import { MysteryShopperProvider } from 'src/app/admin/Providers/MysteryShopperProvider';

import { UserDataService } from 'src/app/model/user-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { StateModel } from 'src/app/model/contract/menu-program';
import { ChannelModel, FrequencyModel, RegionModel, ChartNewModel} from 'src/app/model/contract/channel';
import { DROPDOWN_DETAILS, GENERAL_AVERAGE, GENERAL_REWARD, GENERAL_PROMO1, COLOR_TITLE, REWART_TAT_TOP, GENERAL_OPEN_RATE } from '../../../Models/general-headings.model';
import { TileModel, WhitleTile } from '../../../Models/tile_data.model';
import * as moment from 'moment';

import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/shared/Providers/RouterProvider/AlertProvider';
import { CommonHttpServiceService } from '../../../Services/common-http-service.service';
import { ToastrService } from 'ngx-toastr';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExportExcelService } from 'src/app/report-dashboard/Services/export-excel.service';

@Component({
  selector: 'app-qc-report',
  templateUrl: './qc-report.component.html',
  styleUrls: ['./qc-report.component.scss']
})
export class QcReportComponent implements OnInit {

  data;
  question_header;
  dateRange: any = [];
  maxDate= new Date();
  today: Date;
  startDate: any;
  endDate: any;
  states: StateModel[];
  channels: ChannelModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  whitelist_date:any=0;
  dropdown: any[] = DROPDOWN_DETAILS;
  options:Array<any>=[];
  qc_data:any;
  modalRef: BsModalRef;
  // Date Variable;
  start_date: any;
  end_date: any;

  // Bar Chart
  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];
  public TSEDailybarChartLabels5: any[] = [];
  public TSEDialybarChartData5: any[];

  // Dropdown Selected Values;
  selectedRegion: number[]=[];
  selectedState: number[]=[];
  selectedChannel: number[]=[];
  selectedM1: number[]=[];
  selectedM2: number[]=[];
  selectedM3:number[]=[];
  resetDropdown: boolean;
  whitelist_date1: any;
  min: number;
  max: number;
  smonth:any='0';
  sdate:any='0';
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
modal_image:any;
  i: any;
  modal_text: any;
  status: any;
  program_id: string;



  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;

    this.dropdown.find(d => d.id == 1).data = this.regions;
    // this.dropdown.find(d => d.id == 2).data = this.states;
    this.dropdown.find(d => d.id == 3).data = this.channels;
    this.dropdown.find(d => d.id == 4).data = this.m1Users;
    this.dropdown.find(d => d.id == 5).data = this.m2Users;
    this.dropdown.find(d => d.id == 6).data = this.m3Users;
    // console.log(this.dropdown)
  }

  // Service Call



  openModal(template: TemplateRef<any>,image) {
    this.modalRef = this.modalService.show(template);
    this.modal_image = image;
  }


  confirm(template: TemplateRef<any>,image,status) {
    this.modalRef = this.modalService.show(template);
    this.modal_text = image;
    this.status = status;
  }

  close_modal(){
    this.modalRef.hide()
  }

  acheviementHeading: any[] = [
    "Volume Achievement",
    "% of POCs achieving target"
  ]

  WeeklyChallengeHeading: any[] = [
    "Available",
    "Attempted",
    "Successful"
  ]

  contentLearningHeading: any[] = [
    "Available",
    "Attempted",
    "Successful"
  ]

  constructor(public excelService: ExportExcelService, public mysteryShopperProvider: MysteryShopperProvider,private users: UserDataService, public datepipe: DatePipe,private router: Router, public commonProvider: CommonProvider,public ngxLoader: NgxUiLoaderService,public alertService: AlertService,private httpClient: CommonHttpServiceService,private toastr: ToastrService,public hubAdminDataProvider: HubAdminDataProvider,private modalService: BsModalService) {

  }

  ngOnInit() {
    debugger
    console.log(this.maxDate);
    this.program_id = localStorage.getItem('program_id');
    // this.getGeneralReport(0, 0, 0, 0, 0, 0, 0, 0, 0,0,0);
    this.min = 2019,
    this.max = new Date().getFullYear();
    let options = '<option>Select Year</option>'
    for (var i = this.min; i<=this.max; i++){
      options += '<option value="'+i+'">'+i+'</option>'
      this.options.push(i);

  }
  this.options.reverse();


  //   this.today = new Date();
  //   this.maxDate = new Date();
  // this.users.qcpaneldata().subscribe(
  //   (response:any)=>{
  //     this.data = response.data;
  //     console.log(this.data);
  //     this.question_header = this.data.question_header;
  //   }
  // )
  }
  // submit(){
  //   this.startDate = this.datepipe.transform(this.dateRange[0], 'yyyy-MM-dd');
  //   this.endDate = this.datepipe.transform(this.dateRange[1], 'yyyy-MM-dd');
  //   console.log(this.startDate)
  //   console.log(this.endDate);
  // }


  getGeneralReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id,whitelist_date,month,year) {
    debugger
    // StartingLoader;
    this.ngxLoader.start();

    let url = '/generalreports?program_id='+this.program_id+'&startdate=' + startdate + "&enddate=" + enddate + "&region=" + region + "&state_code=" + state + "&channel_type=" + channel + "&tse_user_id=" + tse_user_id + "&m2_user_id=" + m2_user_id + "&m3_user_id=" + m3_user_id+ "&whitelist_date=" + whitelist_date+ "&month=" + month+ "&year=" + year;
    this.httpClient.getDetails(url)
      .subscribe(
        (result: any) => {
          this.data = result.data;
          console.log(this.data)
          let [i, j, k, l, m, n, o, p, q, r, s] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.ngxLoader.stop();
        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            this.alertService.error(error.data.message);
            this.ngxLoader.stop();
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
            });
          }
        }
      )
  }

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion=[];
        if(event !=0)
                {

          event.forEach(element => {
            let data = this.states.filter(s => s.region_id == element.id);
            this.StatesfilterByRegion.push(...data);
          });
        }
      this.region = this.getList(event,'selectedRegion');
      console.log(this.region);
        this.dropdown.find(d => d.id == 2).data = this.StatesfilterByRegion;
        break;
      case 2:
        debugger
       this.state =  this.getList(event,'selectedState');

        break;
      case 3:
        this.channel = this.getList(event,'selectedChannel');

        break;
      case 4:
      this.m1 =  this.getList(event,'selectedM1');

        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event,'selectedM2');
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 =this.getList(event,'selectedM3');
        break;
    }
    // this.searchByFilter();
  }

  image(i,s){
    debugger

    document.getElementById('input'+i+s).click();

  }

  _downloadSubmitted: boolean;

  downloadTemplate(item) {
    this._downloadSubmitted = false;
    if(item.outlet_details.length > 0) {
      this.excelService.QCExcel(item.outlet_details, 0, 0, 0);
    }
  }

  changeListener($event,data,id) : void {
    debugger
    console.log(data);
    console.log(id);
    this.readThis($event.target,data);
  }

  readThis(inputValue: any,data): void {
    debugger
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.readAsDataURL(file);

    myReader.onloadend = (e) => {
      data.answer = myReader.result;
      console.log(this.modal_image)
    }

  }


  getList(event,mainData){
    this[mainData] = event;

    var z = [];
    var g;
    if(event.length != 0){
      event.forEach(element => {
       z.push(element.id)

    });
    g = z.join();
    this[mainData] = z;
    console.log(this[mainData]);

    }
    else{
      g = ''
    }

    return g;

  }

  openModals(answers)
{
  console.log(answers)
}
  getData(month,year,region,state,channel) {
    debugger
    // StartingLoader;
    this.ngxLoader.start();

    let url = '/qcreportdata?month=' + month + "&year=" + year + "&region_id=" + region + "&state_code=" + state + "&channel_type=" + channel;
    this.httpClient.getQcData(url)
      .subscribe(
        (result: any) => {
          debugger
          this.ngxLoader.stop();
          // this.data = result.data;
          // console.log(this.data);
          this.qc_data = result.data.qc_data;
        console.log(this.qc_data)

        },
        error => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.ngxLoader.stop();
            this.toastr.error(`Internal Server Error`,
              'Dashboard', {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
            });
          }
        }
      )
  }

  searchByFilter() {
    debugger
    // let selectedRegion = this.selectedRegion.slice()
    this.resetDropdown = false;
    if(this.whitelist_date == 0){
      this.whitelist_date1 = 0;
    }
    else{
      this.whitelist_date1 = this.datepipe.transform(this.whitelist_date, 'yyyy-MM-dd')
    }
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    }
    else {
      this.startDate = this.datepipe.transform(this.dateRange[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.dateRange[1], 'yyyy-MM-dd');
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length==0 ) {
      this.region = '';
    }

    if (this.selectedState === undefined || this.selectedState.length==0 ) {
      this.state = '';
    }

    if (this.selectedChannel === undefined || this.selectedChannel.length == 0) {
      this.channel = '';
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = '';
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = '';
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = '';
    }

    this.getData(this.smonth, this.sdate,this.region, this.state, this.channel);
  }








  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    // console.log("Reset");
  }


  submit(assignment_id,question_data,remarks,user_id){
    debugger
    this.modalRef.hide()
    this.ngxLoader.start();
    this.httpClient.qcfinalauditsubmit(assignment_id,question_data,this.status,remarks,user_id)
    .subscribe(
      (result: any) => {
        this.searchByFilter()
        // this.alertService.success(result.data);
        this.ngxLoader.stop();
        this.toastr.success(`Success`,
        result.data , {
            timeOut: 10000,
            closeButton: true,
            positionClass: "toast-top-right",
        });
      },
      error => {
        // unauthorized access
        if (error.status == 401 || error.status == 403) {
        } else {
          // this.alertService.error(error.data.message);
            this.ngxLoader.stop();
            this.toastr.error(`Error`,
            error.data.message, {
                timeOut: 10000,
                closeButton: true,
                positionClass: "toast-bottom-right",
            });
        }
      }
    )

  }

}
