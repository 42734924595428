import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { GlobalService } from './global.service';
import { ResponseBody } from './contract/response-body';
const httpOptions = {
    headers : new HttpHeaders({'Content-Type': 'application/json'})
}

@Injectable()
export class StaffService {
  redirectURL = '';
  loggedIn = false;

  constructor(
      private globalService: GlobalService,
      private router: Router,
      private jwtHelper: JwtHelperService,
      private http: HttpClient
  ) {
    this.loggedIn = this.isLoggedIn();
  }


  adminLogin(email:string, password:string, year : string):Observable<any>{
    let headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Access-Control-Allow-Origin': '*'});
    let apiUrl  = this.globalService.apiHost + '/admin/login';
    let body    = JSON.stringify({"LoginForm":{"username":email, "password":password}, "calendar_year": year});
    return this.http.post<ResponseBody>(apiUrl, body, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .map(response => {
        if(response.success) {
          console.log('login successfully');
          this.loggedIn = true;
          localStorage.setItem('backend-token', response.data.access_token);
        }
        else{
          localStorage.removeItem('backend-token');
          this.loggedIn = false;
        }

        return response;
      });
  }

  public logout(): void {
    localStorage.removeItem(environment.tokenName);
    this.loggedIn = false;
  }

  public getToken(): any {
    return localStorage.getItem(environment.tokenName);
  }

  public unauthorizedAccess(error: any): void {
    this.logout();
    this.router.navigate([ '/login' ]);
  }

  public isLoggedIn(): boolean {
    //return this.jwtHelper.isTokenExpired() !== true;
    // return tokenNotExpired('backend-token');
    return true;
  }

  public getJWTValue(): any {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      return this.jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  private checkToken(): any {
    return !!localStorage.getItem(environment.tokenName);
  }

  private handleError(response: Response | any) {
    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return Observable.throw(errorMessage);
  }



}
