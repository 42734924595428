import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { HubAdminDataProvider } from 'src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonProvider } from 'src/app/shared/Providers/Shared/CommonProvider';
import { HierarchyProvider } from 'src/app/shared/Providers/Shared/HierarchyProvider';
import { MenuTemplate, MenuBrandModel, StateModel, MenuTemplateRequestModel } from 'src/app/model/contract/menu-program';
import { ChannelModel, OutletModel, RegionModel } from 'src/app/model/contract/channel';
import { DatePipe } from '@angular/common';
import { Angular2Csv } from 'angular2-csv';
import { LoyaltyTargetProvider } from 'src/app/admin/Providers/LoyaltyTargetProvider';
import { MenuProgramProvider } from 'src/app/admin/Providers/MenuProgramProvider';
import { COLOR_TITLE, MENU_DROPDOWN } from '../Models/general-headings.model';

@Component({
  selector: 'app-menu-program-overview',
  templateUrl: './menu-program-overview.component.html',
  styleUrls: ['./menu-program-overview.component.scss']
})
export class MenuProgramOverviewComponent implements OnInit {
  isSearch: boolean;
  dropdownList = [];
  statusDropdown = [];
  dropdownSettings = {};
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  templates: MenuTemplate[];

  brands: MenuBrandModel[];
  states: StateModel[];
  requests: MenuTemplateRequestModel[];
  allrequests: MenuTemplateRequestModel[];
  regions: RegionModel[];
  searchText: any;
  requestSendToAgency: MenuTemplateRequestModel[];
  requestUpdatedByAgency: MenuTemplateRequestModel[];
  requestApproved: MenuTemplateRequestModel[];
  colors: any[] = COLOR_TITLE;
  dateRange: any = [];
  searchByBrandData: number;
  searchByTemplateData: number;
  searchByStatus: any;
  date1: any;
  date2: any;
  @Input() model: MenuTemplateRequestModel = new MenuTemplateRequestModel;
  assignUser: number;
  reassignUser: number;
  error: { isError: boolean; errorMessage: string; };
  disableAssign: boolean = false;
  disableReAssign: boolean = false;
  regionList: RegionModel[];
  searchByRegion: any;
  searchByState: any;
  modalRef2: BsModalRef;
  totalCost: number = 0;

  dropdown: any[] = MENU_DROPDOWN;
  StatesfilterByRegion: StateModel[];
  selectedRegion: any;
  selectedState: any;
  selectedBrand: any;
  selectedTemplate: any;
  selectedStatus: any;
  startDate: any;
  endDate: any;
  selectedChannel: any;
  selectedM1: any;
  selectedM2: any;
  selectedM3: any;
  resetDropdown: boolean;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public ngxLoader: NgxUiLoaderService,
    public commonProvider: CommonProvider,
    public geoHerirarchProvider: HierarchyProvider,
    public targetProvider: LoyaltyTargetProvider,
    private hubAdminDataProvider: HubAdminDataProvider,
    private teamLeaderProvider: MenuProgramProvider,
    public datepipe: DatePipe,
  ) { }

  ngOnInit() {
    this.teamLeaderProvider.getMenuTemplateRequestList();
    this.searchByStatus = "";


    this.statusDropdown = [
      // { item_id: 1, item_text: 'Pending Review By TSE' },
      { id: 2, name: 'Sent to Agency' },
      { id: 3, name: 'Updated by Agency' },
      { id: 4, name: 'Approved' },
      // { item_id: 5, item_text: 'Rejected' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.searchByBrandData = 0;

    this.searchByTemplateData = 0;

    this.searchByRegion = 0;

    this.searchByState = 0;
  }

  menuProgram = [
    {
      id: 1,
      name: 'Sent to Agency',
      color: this.colors[0],
      points: 0
    },
    {
      id: 2,
      name: 'Updated by Agency',
      color: this.colors[1],
      points: 0
    },
    {
      id: 3,
      name: 'Approved',
      color: this.colors[2],
      points: 0
    },
    {
      id: 4,
      name: 'Total Cost',
      color: this.colors[1],
      points: 0
    }
  ]

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.templates = this.teamLeaderProvider.templates;
    this.brands = this.teamLeaderProvider.brands;
    this.requests = this.teamLeaderProvider.searchRequestData;
    this.allrequests = this.teamLeaderProvider.searchRequestData;
    this.regionList = this.commonProvider.regionList;
    this.totalCost = this.teamLeaderProvider.totalCost;
    this.dropdown.find(d => d.id == 1).data = this.regionList;
    this.dropdown.find(d => d.id == 7).data = this.brands;
    this.dropdown.find(d => d.id == 8).data = this.templates;
    this.dropdown.find(d => d.id == 9).data = this.statusDropdown;
    console.log(this.totalCost);
    // if (typeof this.allrequests != "undefined") {
    //   if (this.allrequests.length <= 0) {
    //     this.ngxLoader.stop();
    //   }
    // }
    // else {
    //   this.ngxLoader.start();
    //   setTimeout(() => {
    //     this.ngxLoader.stop();
    //   }, 1000);
    // }

    if (typeof this.allrequests != "undefined") {
      if (this.allrequests.length > 0) {
        this.requestSendToAgency = this.allrequests.filter(r => r.internal_status == 2);
        this.requestUpdatedByAgency = this.allrequests.filter(r => r.internal_status == 3);
        this.requestApproved = this.allrequests.filter(r => r.internal_status == 4);
        this.menuProgram.find(i => i.id == 1).points = this.requestSendToAgency.length;
        this.menuProgram.find(i => i.id == 2).points = this.requestUpdatedByAgency.length;
        this.menuProgram.find(i => i.id == 3).points = this.requestApproved.length;
        this.menuProgram.find(i => i.id == 4).points = this.totalCost;
      }
      else {
        this.requestSendToAgency = [];
        this.requestUpdatedByAgency = [];
        this.requestApproved = [];
        this.allrequests = [];
      }
    }
  }


  onDateSelection(item) {
    this.teamLeaderProvider.getMenuTemplateRequestList();
    if (item != null) {
      this.ngxLoader.startBackground();
      setTimeout(() => {
        this.ngxLoader.stopBackground(); // stop foreground spinner of the master loader with 'default' taskId
      }, 1000);
      this.isSearch = true;
      this.date1 = this.datepipe.transform(item[0], 'yyyy-MM-dd');
      this.date2 = this.datepipe.transform(item[1], 'yyyy-MM-dd');
      this.teamLeaderProvider.getDateBasedRecords(this.date1, this.date2);
    }
  }

  searchData() {

    console.log(`Brand : ${this.searchByBrandData}`);
    console.log(`Template : ${this.searchByTemplateData}`);
    console.log(`Status : ${this.searchByStatus}`);
    console.log(`Region : ${this.searchByRegion}`);
    console.log(`State : ${this.searchByState}`);

    if (!this.isSearch) {
      this.teamLeaderProvider.searchMenuRecords(this.searchByBrandData, this.searchByTemplateData, this.searchByStatus, this.searchByRegion, this.searchByState);
    }
    else {
      this.teamLeaderProvider.searchMenuRecordsWithDate(this.searchByBrandData, this.searchByTemplateData, this.searchByStatus, this.date1, this.date2, this.searchByRegion, this.searchByState);
    }

  }


  moveToIndivialEntry(entries: any) {
    this.router.navigate(['/report-admin/menu-program-report/', entries.id])
  }

  // Download Template
  private _csvdata = [];
  _downloadSubmitted: boolean;

  public downloadTemplate() {
    this.ngxLoader.startBackground();
    this._downloadSubmitted = true;
    this._csvdata = [];
    let filename: string = 'Menu_Report';
    // if (this.startdate) {
    //   filename = filename + '_' + this.startdate + '_' + this.enddate;
    // } else {
    //   filename = filename;
    // }
    let options = {
      showLabels: true,
      headers: ['Brand Name',
        'Template Name',
        'Region',
        'State',
        'Outlet Code',
        'Outlet Name',
        'Copies',
        'No. of Page',
        'Cost Per Page',
        'Total Cost',
        'Status',
        'Updated Date',
        'TSE Name',
        'Assign Agent Name']
    };

    for (let i = 0; i < this.requests.length; i++) {
      let item = this.requests[i];
      let dt = [null, null];
      console.log(item)
      console.log(dt);
      this._csvdata.push({
        menu_brand_name: item.menu_brand_name,
        menu_template_name: item.menu_template_name,
        region_name: item.region_name,
        state_name: item.state_name,
        ro_code: item.ro_code,
        ro_name: item.ro_name,
        no_of_copies: item.no_of_copies,
        no_of_pages: item.no_of_pages,
        cost_per_page: item.cost_per_page,
        total_cost: item.total_cost,
        request_status: item.request_status,
        request_timestamp: item.request_timestamp,
        tse_name: item.tse_name,
        assigned_user_name: item.assigned_user_name
      });
    }
    new Angular2Csv(this._csvdata, filename, options);
    this.ngxLoader.stopBackground();
    this._downloadSubmitted = false;
  }

  //Dummy



  selectedValueCheck(event, filter): void {
    console.log(event);
    switch (filter) {
      case 1:
        this.StatesfilterByRegion = this.states.filter(s => s.region_id == event);
        this.dropdown.find(d => d.id == 2).data = this.StatesfilterByRegion;
        this.selectedRegion = event;
        break;
      case 2:
        this.selectedState = event;
        break;
      case 7:
        this.selectedBrand = event;
        break;
      case 8:
        this.selectedTemplate = event;
        break;
      case 9:
        this.selectedStatus = event;
        break;
    }
    // this.searchByFilter();
  }

  searchByFilter() {

    this.teamLeaderProvider.getMenuTemplateRequestList();

    if (this.dateRange == []) {
      this.startDate = 0;
      this.endDate = 0;
      this.isSearch = false;
    }
    else {
      this.startDate = this.datepipe.transform(this.dateRange[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.dateRange[1], 'yyyy-MM-dd');
      this.isSearch = true;
    }

    if (this.selectedRegion === undefined) {
      this.selectedRegion = 0;
    }

    if (this.selectedState === undefined) {
      this.selectedState = 0;
    }

    if (this.selectedBrand === undefined) {
      this.selectedBrand = 0;
    }

    if (this.selectedTemplate === undefined) {
      this.selectedTemplate = 0;
    }

    if (this.selectedStatus === undefined) {
      this.selectedStatus = 0;
    }

    console.log(this.isSearch);



    if (this.isSearch) {
      this.teamLeaderProvider.searchMenuRecords(this.selectedBrand, this.selectedTemplate, this.selectedStatus, this.selectedRegion, this.selectedState);
    }
    else {
      this.teamLeaderProvider.searchMenuRecordsWithDate(this.selectedBrand, this.selectedTemplate, this.selectedStatus, this.date1, this.date2, this.selectedRegion, this.selectedState);
    }

  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }

}
