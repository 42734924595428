import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, TemplateRef, HostListener } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { element } from 'protractor';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MenuTransactionModel } from 'src/app/model/contract/menu-program';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/model/global.service';
import { RegionDataService } from 'src/app/model/region-data.service';
import { MenuProgramProvider } from 'src/app/admin/Providers/MenuProgramProvider';

@Component({
    selector: 'app-menu-program-overview',
    templateUrl: './entry.component.html',
    styleUrls: ['./menu-program-overview.component.scss']
})

export class EntryMenuComponent implements OnInit {

    requestTransactions: MenuTransactionModel[];
    siteUrl: any;

    constructor(
        public routerDataProviders: RouteDataProvider,
        private router: Router,
        public datepipe: DatePipe,
        private modalService: BsModalService,
        private teamLeaderProvider: MenuProgramProvider,
        private _location: Location,
        private activatedRoute: ActivatedRoute,
        public usersService: RegionDataService,
        public globalService: GlobalService,
        public ngxLoader: NgxUiLoaderService) {
        this.siteUrl = this.globalService.apiHost;
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(
            params => {
                if (typeof params['id'] != 'undefined') {
                    this.getMenuRequestTransactions(params['id'])
                }
            }
        );
    }


    getMenuRequestTransactions(id) {
        this.ngxLoader.start();
        this.usersService.getAllTemplateMenuRequestTransaction(id).subscribe(
            (data) => {
                this.requestTransactions = data;
                if (this.requestTransactions.length > 0) {
                    this.requestTransactions.forEach(
                        f => {
                            this.ngxLoader.stop();
                            if (f.template_logo != null) {
                                let path = f.template_logo.split('/');
                                console.log(path);
                                f.logo_name = path[path.length - 1];
                                if (f.template_images.length > 0) {
                                    f.template_images.forEach(
                                        i => {
                                            let path1 = f.template_logo.split('/');
                                            console.log(path1);
                                            i.image_name = path1[path1.length - 1]
                                        }
                                    )
                                }
                            }
                        }
                    )
                }
            }
        )
    }

    backClicked() {
        console.log('here');
        this._location.back();
    }


}