import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import {
  IBarChartOptions,
  IChartistAnimationOptions,
  IChartistData
} from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';

@Component({
  selector: 'app-bar-chart',
  template: ` 
  <x-chartist
                [type]="type"
                [data]="data"
                [options]="options"
                [events]="events"
              ></x-chartist>`,
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent {

  @Input() data : any;
  type: ChartType = 'Bar';
  data1: IChartistData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    series: [
      [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
      [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4]
    ]
  };
  options: IBarChartOptions = {
    axisX: {
      showGrid: false
    },
    height: 300
  };

  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'bar') {
        data.element.animate({
          y2: <IChartistAnimationOptions>{
            dur: '0.5s',
            from: data.y1,
            to: data.y2,
            easing: 'easeOutQuad'
          }
        });
      }
    },
    created(data: any): void {
      const defs = data.svg.elem('defs');
      defs.elem('linearGradient', {
        id: 'gradient2',
        x1: 1,
        y1: 1,
        x2: 1,
        y2: 1
      }).elem('stop', {
        offset: 0,
        'stop-color': 'rgba(22, 141, 238, 1)'
      }).parent().elem('stop', {
        offset: 1,
        'stop-color': 'rgba(98, 188, 270, 11)'
      });
    },
  };
  ngOnInit() {
    console.log(this.data);
    

    debugger
    this.data.labels.forEach((element, index) => {
      if(element == 'Coolers & Branding')
      {
        this.data.labels[index] = "Cooler Purity & Consumer Promo & Price Comms Execution"
      }
      else if (element == 'Content & Learning')
      {

        this.data.labels[index] = "Content"
      }
      else if (element == 'Weekly Challenges'){
        this.data.labels[index] = "Tactical Challenges"
      }

      else if(element == 'Coolers / Branding'){

        this.data.labels[index] = "Consumer Promotions / Cooler Purity"

      }
      else 
      {
        return
      }
      console.log(this.data)
    });
  }

}
