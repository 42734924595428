import { Component, OnInit, Output, OnChanges, Input, EventEmitter, Renderer2, TemplateRef, HostListener } from '@angular/core';
import { StaffService } from '../../model/staff.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RouteDataProvider } from 'src/app/shared/Providers/RouterProvider/RouteDataProvider.provider';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
declare var $;
@Component({
  selector: 'app-backend',
  templateUrl: './backend-layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class BackendLayoutComponent implements OnInit {
  // @HostListener('window:resize', ['$event'])
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  modalRef: BsModalRef;
  public userData: any = {};
  public today: Date;
  public menuOpen: boolean = false;
  parameters: any;
  searchArea: any;
  showFilter : boolean = true;
  selectedItem : boolean = true;
  queryParam : any = {};
  geoHierarchyActive: boolean;
  settingsActive: boolean = false;
  currentRouter : any;
  username: string;
  usernames: string;

  constructor(public staffService: StaffService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public routerDataProvider: RouteDataProvider,
    private renderer: Renderer2,
    private _location: Location,
    private modalService: BsModalService) {
    this.username = localStorage.getItem('user_name');
    this.today = new Date();
    this.routerDataProvider.subArea.subscribe(
      m => {
        this.currentRouter = m;
      }
    )
  }

  ngOnInit(): void {
    this.usernames = localStorage.getItem('user_names');
    const jwtValue: any = this.staffService.getJWTValue();
    if (jwtValue !== null) {
      this.userData = jwtValue.data;
    }

  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  public toggled(open:boolean):void {

  }

  clickFilter(value):void{
    this.routerDataProvider.filter(value);
  }

  sidebarClick(event){
    console.log(event);
  }

  backClicked() {
    console.log('here');
    this._location.back();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async convetToPDF() {
    var data = document.getElementById('Program-Overview');
    var body = document.body,
    html = document.documentElement;
    var height = Math.max( body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight )
    html2canvas(data).then(async canvas => {

    // Few necessary setting options
    var imgWidth = 208;

    var pageHeight = 295;

    var imgHeight = canvas.height * imgWidth / canvas.width;

    debugger;

    const contentDataURL = canvas.toDataURL('image/png')

    let pdf = new jsPDF.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

    var position = 0;

    pageHeight = pdf.internal.pageSize.height;

    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      await pdf.save('new-file.pdf'); // Generated PDF
    });
}


}
